import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ClickAwayListener,
  IconButton,
  Drawer as MuiDrawer,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

interface DrawerProps {
  title: string;
  isOpen: boolean;
  onClose?: () => void;
  onClickOutside?: () => void;
  children: ReactNode;
  hideBackdrop?: boolean;
  zIndex?: number;
  width?: number;
}

const Drawer = ({
  title,
  isOpen,
  children,
  hideBackdrop = true,
  zIndex = 10,
  onClose,
  width = 464,
  onClickOutside,
}: DrawerProps) => {
  const theme = useTheme();

  const drawerPaperStyles = {
    boxSizing: 'border-box',
    overflow: 'hidden',
    width,
  };

  const contentStyles = {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  };

  const onClickAway = () => {
    if (onClickOutside) return onClickOutside();
  };

  return (
    <MuiDrawer
      sx={{
        width: 0,
        zIndex,
        '& .MuiDrawer-paper': drawerPaperStyles,
      }}
      hideBackdrop={hideBackdrop}
      anchor="right"
      open={isOpen}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Stack
          sx={{
            height: '100%',
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            p={1.5}
            pl={3}
          >
            <Typography variant="h3">{title}</Typography>
            {onClose && (
              <IconButton
                onClick={onClose}
                sx={{
                  width: 40,
                  height: 40,
                  color: theme.palette.common.brand.darkBlue,
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            )}
          </Stack>
          <Stack sx={contentStyles}>{children}</Stack>
        </Stack>
      </ClickAwayListener>
    </MuiDrawer>
  );
};

export default Drawer;
