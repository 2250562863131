import { Stack, Typography } from '@mui/material';
import { RespondedConsents } from 'api/patient/patient-service';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { useTranslation } from 'react-i18next';

export const ListOfRespondedConsents = ({
  respondedConsents,
}: {
  respondedConsents: RespondedConsents[];
}) => {
  const { t } = useTranslation('patientPortal');
  const { translateRelationship } = useRelationshipOptions();

  return (
    <Stack gap={1.5}>
      <Typography variant="h4">{t('home-consent-section.received')}</Typography>
      {respondedConsents?.map((consent, index) => (
        <Typography key={index} variant="body1">
          {consent.name}{' '}
          {consent.relationship &&
            `(${translateRelationship(consent.relationship).toLowerCase()})`}
        </Typography>
      ))}
    </Stack>
  );
};
