import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, Link, Stack, Typography } from '@mui/material';
import { usePatientService } from 'api/patient/patient-service';
import { PedigreeNodeSex } from 'common/features/pedigree/common/types';
import Dialog from 'components/common/Dialog';
import NodeConsumer from 'patientPortal/features/pedigree/pedigreeRenderer/pedigreeNode/NodeConsumer';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

type PedigreeSubmissionDialog =
  | 'confirmSubmit'
  | 'pedigreeMissing'
  | 'invalidNode';

export const SubmitAndSavePedigreeAction = ({
  pedigreeCaseId,
  hasPedigree,
  hasUnfinishedTasks,
}: {
  pedigreeCaseId: string;
  hasPedigree: boolean;
  hasUnfinishedTasks: boolean;
}) => {
  const service = usePatientService();
  const queryClient = useQueryClient();
  const { t } = useTranslation('patientPortal');
  const navigate = useNavigate();

  const [pedigreeSubmissionDialog, setPedigreeSubmissionDialog] =
    useState<PedigreeSubmissionDialog | null>(null);

  const submitPedigreeMutation = useMutation(
    () => {
      return service?.submitPedigree(pedigreeCaseId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pedigreeCaseInfo');
        queryClient.invalidateQueries('pedigreeCase');
        return navigate(PATIENT_PATHS.PEDIGREE_SUBMITTED, {
          state: { hasUnfinishedTasks },
        });
      },
      onError: ({ response }) => {
        const { data } = response || {};
        if (data.message === 'Invalid pedigree node data') {
          setPedigreeSubmissionDialog('invalidNode');
        }
      },
    },
  );

  const submitPedigree = () => {
    submitPedigreeMutation.mutate();
  };

  const handleSubmit = () => {
    if (!hasPedigree) {
      return setPedigreeSubmissionDialog('pedigreeMissing');
    }
    return setPedigreeSubmissionDialog('confirmSubmit');
  };

  const homeUrl = `${window.location.href}`;

  return (
    <>
      <Typography variant="body1">
        {t('home-next-steps-section.submit.description')}
      </Typography>
      <Stack direction="column" gap={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
        >
          {t('home-next-steps-section.submit.button-text')}
        </Button>
        <Stack textAlign="center">
          <Typography variant="caption">
            {t('home-next-steps-section.continue-later')}:
          </Typography>
          <Link variant="caption" href={homeUrl}>
            {homeUrl}
          </Link>
        </Stack>
      </Stack>
      <SubmissionDialog
        submitPedigree={submitPedigree}
        isLoading={submitPedigreeMutation.isLoading}
        pedigreeSubmissionDialog={pedigreeSubmissionDialog}
        setPedigreeSubmissionDialog={setPedigreeSubmissionDialog}
      />
    </>
  );
};

const SubmissionDialog = ({
  submitPedigree,
  isLoading,
  pedigreeSubmissionDialog,
  setPedigreeSubmissionDialog,
}: {
  submitPedigree: () => void;
  isLoading: boolean;
  pedigreeSubmissionDialog: PedigreeSubmissionDialog | null;
  setPedigreeSubmissionDialog: (
    dialog: PedigreeSubmissionDialog | null,
  ) => void;
}) => {
  const { t } = useTranslation('patientPortal');

  const onCloseDialog = useCallback(() => {
    setPedigreeSubmissionDialog(null);
  }, [setPedigreeSubmissionDialog]);

  const dialoguesContent = useMemo(() => {
    return {
      confirmSubmit: {
        open: true,
        title: t('pedigree-submission-dialog-confirm-submit.title'),
        content: t('pedigree-submission-dialog-confirm-submit.content'),
        actions: [
          <Button
            key="pedigree-missing-button-dialog-cancel"
            variant="outlined"
            onClick={onCloseDialog}
            disabled={isLoading}
          >
            {t('pedigree-submission-dialog-confirm-submit.button-cancel')}
          </Button>,
          <LoadingButton
            key="pedigree-missing-button-dialog-submit"
            variant="contained"
            onClick={submitPedigree}
            loading={isLoading}
          >
            {t('pedigree-submission-dialog-confirm-submit.button-submit')}
          </LoadingButton>,
        ],
      },
      pedigreeMissing: {
        open: true,
        title: t('pedigree-submission-dialog-pedigree-missing.title'),
        content: t('pedigree-submission-dialog-pedigree-missing.content'),
        actions: [
          <Button
            key="pedigree-missing-button-dialog"
            variant="contained"
            onClick={onCloseDialog}
          >
            {t('pedigree-submission-dialog-pedigree-missing.button-ok')}
          </Button>,
        ],
      },
      invalidNode: {
        open: true,
        title: t('pedigree-submission-dialog-error.title'),
        content: t('pedigree-submission-dialog-error.invalid-node-content'),
        element: <InvalidNodeSubmissionSubmitDialog />,
        actions: [
          <Button
            key="invalid-node-dialog-button"
            variant="contained"
            onClick={onCloseDialog}
          >
            {t('pedigree-submission-dialog-error.button-ok')}
          </Button>,
        ],
      },
    };
  }, [isLoading, onCloseDialog, submitPedigree, t]);

  if (!pedigreeSubmissionDialog) return null;

  const dialogProps = dialoguesContent[pedigreeSubmissionDialog];
  return <Dialog {...dialogProps} />;
};

export const InvalidNodeSubmissionSubmitDialog = () => {
  const { t } = useTranslation('patientPortal');

  return (
    <Stack alignItems="center" mt={3} mb={3} sx={{ position: 'relative' }}>
      <NodeConsumer
        sex={PedigreeNodeSex.FEMALE}
        isIndex={false}
        isSelected={true}
        hasCancerHistory={true}
        hasError={true}
        onClick={() => {}}
        nodeSize={{ width: 165, height: 152 }}
        addDiagnosisLabel={t('node-member.add-diagnosis.labe')}
        cancerHistoryLabel={t('node-member.cancer-history.label')}
        relativeRelationLabel={t('relative-relation-label')}
        styleProps={{ borderWidth: 2 }}
      ></NodeConsumer>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          position: 'absolute',
          bottom: -18,
          p: 1,
          width: 20,
          height: 20,
          border: 0,
          borderRadius: '50%',
          background: 'white',
          boxShadow: 2,
          color: 'common.brand.blue',
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="2x" />
      </Stack>
    </Stack>
  );
};
