import { Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

function PedigreeCaseInactive() {
  const { t } = useTranslation(['patientPortal']);
  const location = useLocation();
  const navigate = useNavigate();

  const inactiveCase = location.state?.inactiveCase;

  useEffect(() => {
    if (!inactiveCase) {
      return navigate('..');
    }
  }, [inactiveCase, navigate]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <Stack spacing={4} textAlign="center">
        <Typography variant="h1">
          {t('pedigree-case-inactive.title')}
        </Typography>
        <Typography variant="body1">
          {t('pedigree-case-inactive.description')}
        </Typography>
        <Typography variant="body1">
          {t('pedigree-case-inactive.content')}
        </Typography>
      </Stack>
    </Container>
  );
}

export default PedigreeCaseInactive;
