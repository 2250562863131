import { Button, Divider, Stack, Typography } from '@mui/material';
import { PendingConsents } from 'api/patient/patient-service';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoBox } from './InfoBox';

export const ListOfPendingConsents = ({
  pendingConsents,
  hasRespondedConsents,
}: {
  pendingConsents: PendingConsents[];
  hasRespondedConsents: boolean;
}) => {
  const showDivider = useCallback(
    (index: number) => {
      return (
        (pendingConsents && index < pendingConsents.length - 1) ||
        hasRespondedConsents
      );
    },
    [pendingConsents, hasRespondedConsents],
  );

  return (
    <Stack gap={3}>
      <InfoBox />
      {pendingConsents.map((consent, index) => (
        <Stack key={consent.id} gap={3}>
          <PendingConsentItem consent={consent} />
          {showDivider(index) && <Divider />}
        </Stack>
      ))}
    </Stack>
  );
};

const PendingConsentItem = ({ consent }: { consent: PendingConsents }) => {
  const {
    name,
    relationship,
    isDeceased,
    access,
    signatory,
    proxySignerToConsenteeRelationship,
  } = consent;

  const { translateRelationship } = useRelationshipOptions();
  const { t } = useTranslation('patientPortal');

  const formatName = useMemo(() => {
    if (isDeceased && !name) {
      return t('home-consent-section.deceased');
    } else {
      return name || t('home-consent-section-unknown.name');
    }
  }, [isDeceased, name, t]);

  const description = useMemo(() => {
    if (signatory?.type === 'SPECIFIC_PERSON') {
      const nameDescription = t(
        'home-consent-section.description-signatory.specific-person',
        {
          name: signatory.name || t('home-consent-section-unknown.name'),
        },
      );
      const relationshipDescription = proxySignerToConsenteeRelationship
        ? `, (${translateRelationship(
            proxySignerToConsenteeRelationship,
          ).toLowerCase()})`
        : '';
      return `${nameDescription}${relationshipDescription}.`;
    }
    if (signatory?.type === 'FIRST_DEGREE_RELATIVE') {
      return t(
        'home-consent-section.description-signatory.first-degree-relative',
      );
    }
    return null;
  }, [
    proxySignerToConsenteeRelationship,
    signatory?.name,
    signatory?.type,
    t,
    translateRelationship,
  ]);

  const handleCopyToClipboard = () => {
    const textToCopy = `${access.consentLink}\n${t(
      'home-consent-section.consent-pin',
    )}: ${access.pin.toLocaleUpperCase()}`;
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <Stack gap={1.5}>
      <Typography variant="h5">
        {formatName} ({translateRelationship(relationship).toLowerCase()})
      </Typography>
      {description && <Typography variant="body1">{description}</Typography>}
      <Stack>
        <Typography variant="body1">
          {t('home-consent-section.consent-link')}:
        </Typography>
        <Typography variant="body1">{access.consentLink}</Typography>
      </Stack>
      <Typography variant="body1">
        {t('home-consent-section.consent-pin')}:{' '}
        {access.pin.toLocaleUpperCase()}
      </Typography>
      <Button
        onClick={handleCopyToClipboard}
        variant="outlined"
        sx={{ width: 'fit-content' }}
      >
        {t('home-consent-section.consent-button-text')}
      </Button>
    </Stack>
  );
};
