import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { UiSchema } from '@rjsf/utils';
import { useContext, useMemo } from 'react';
import { DynamicFormContext } from '..';

interface ReadonlyViewProps {
  title: string;
  value: string;
  uiSchema?: UiSchema;
  padding?: number | string;
  direction?: 'row' | 'column' | 'row-reverse';
  gap?: number;
  variant?: 'body1' | 'body2';
}

export const ReadonlyView = ({
  title,
  uiSchema,
  value,
  padding,
  direction,
  gap,
  variant = 'body1',
}: ReadonlyViewProps) => {
  const theme = useTheme();
  const formContext = useContext(DynamicFormContext);

  const formatValue = useMemo(() => {
    const concatenateFieldKey = uiSchema?.['ui:readOnlyConcatenateWithTitle'];

    if (!concatenateFieldKey) {
      return value;
    }

    const { formData, uiSchema: formUiSchema } = formContext?.formState || {};

    const fieldValueToConcat = findObjectByKey(formData, concatenateFieldKey);
    const fieldUiSchema = findObjectByKey(formUiSchema, concatenateFieldKey);
    const fieldTitleToConcat = fieldUiSchema?.['ui:title']?.toLowerCase();

    if (fieldValueToConcat && fieldTitleToConcat) {
      return `${value}, ${fieldTitleToConcat}`;
    }

    return value;
  }, [formContext?.formState, uiSchema, value]);

  return (
    <Grid container p={padding}>
      <Grid item>
        <Stack direction={direction} gap={gap}>
          <Typography
            variant={variant}
            color={theme.palette.common.opacity.darkBlue[70]}
          >
            {title}
          </Typography>
          <Typography mt={0.5} variant={variant}>
            {formatValue}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

const findObjectByKey = (obj: any, name: string) => {
  if (typeof obj !== 'object' || obj === null) {
    return null;
  }

  for (const key in obj) {
    if (key === name) {
      return obj[key];
    }
    if (typeof obj[key] === 'object') {
      const result: any = findObjectByKey(obj[key], name);
      if (result !== null) {
        return result;
      }
    }
  }

  return null;
};
