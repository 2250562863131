import { usePatientService } from 'api/patient/patient-service';
import { LoadingBackdrop } from 'components/common';
import { PedigreeCaseConfirmation } from 'patientPortal/features/pedigree/pedigreeCaseConfirmation/PedigreeCaseConfirmation';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

function PedigreeCaseSubmitted() {
  const service = usePatientService();
  const navigate = useNavigate();

  const { patientId } = useParams();

  const { isLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service?.getPedigreeCase(patientId || ''),
    {
      onSuccess: (data) => {
        const isCaseConfirmation =
          data?.status === 'pedigreeReceived' || data?.status === 'caseViewed';
        if (!isCaseConfirmation) {
          return navigate('..');
        }
      },
    },
  );

  if (isLoading) return <LoadingBackdrop />;
  return <PedigreeCaseConfirmation />;
}

export default PedigreeCaseSubmitted;
