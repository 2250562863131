import { useCallback, useEffect, useMemo, useState } from 'react';
import { ExportDialogContentView } from '../views/ExportDialogContentView';

export type ExportFormat = 'pdf' | 'canRisk' | null;

interface ExportDialogContentControllerProps {
  closeDialog: () => void;
  onExportClick: (format: ExportFormat, anonymized: boolean) => void;
  isLoading: boolean;
  exportFailedMessage: string | null;
}

export function ExportDialogContentController(
  props: ExportDialogContentControllerProps,
) {
  const [exportFormat, setExportFormat] = useState<ExportFormat>(null);
  const [anonymized, setAnonymized] = useState(false);

  const { closeDialog, onExportClick, isLoading, exportFailedMessage } = props;

  const handleExportFormatChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setExportFormat(event.target.value as ExportFormat);
  };

  const handleAnonymizedChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setAnonymized(checked);
  };

  const handleExportClick = useCallback(() => {
    if (!exportFormat) {
      return;
    }
    onExportClick(exportFormat, anonymized);
  }, [exportFormat, anonymized, onExportClick]);

  useEffect(() => {
    if (exportFormat === 'canRisk') setAnonymized(true);
  }, [exportFormat]);

  const isAnonymizedDisabled = useMemo(
    () => exportFormat === 'canRisk',
    [exportFormat],
  );

  return (
    <ExportDialogContentView
      exportFormat={exportFormat}
      onExportFormatChange={handleExportFormatChange}
      anonymized={anonymized}
      onAnonymizedChange={handleAnonymizedChange}
      isAnonymizedDisabled={isAnonymizedDisabled}
      onCancelClick={closeDialog}
      onExportClick={handleExportClick}
      isLoading={isLoading}
      exportFailedMessage={exportFailedMessage}
    />
  );
}
