import {
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Message, usePatientService } from 'api/patient/patient-service';
import { isoToShortDate } from 'common/utils';
import { SectionBox } from 'components/common';
import type { i18n } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

type MessageGroupByDate = {
  datetime: string;
  messages: Message[];
};

export const Messages = () => {
  const service = usePatientService();
  const { patientId } = useParams();
  const { t, i18n } = useTranslation('patientPortal');

  const { data, isLoading } = useQuery(
    ['messages', patientId],
    () => service?.getMessages(patientId || ''),
    {
      refetchOnWindowFocus: false,
    },
  );

  const messageGroup = useMemo(() => {
    if (!data?.messages) return null;
    return groupMessagesByDate(data?.messages, i18n);
  }, [data, i18n]);

  if (isLoading || !messageGroup) return null;

  return (
    <Container maxWidth="md">
      <Stack gap={3} mb={4}>
        <Typography variant="h2">{t('messages.title')}</Typography>
        {messageGroup.map((group, index) => (
          <MessageGroup key={index} group={group} />
        ))}
      </Stack>
    </Container>
  );
};

const MessageGroup = ({ group }: { group: MessageGroupByDate }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack gap={isMobile ? 1.5 : 3} direction={isMobile ? 'column' : 'row'}>
      <Typography variant="body1">{group.datetime}</Typography>
      <Stack gap={3} width="100%" maxWidth={700}>
        {group.messages.map((message, messageIndex) => (
          <MessageItem key={messageIndex} message={message} />
        ))}
      </Stack>
    </Stack>
  );
};

const MessageItem = ({ message }: { message: Message }) => {
  const { t } = useTranslation('patientPortal');

  return (
    <Stack gap={1.5}>
      <Typography variant="h5">
        {t('messages.subtitle')} {message.sender.name}
      </Typography>
      <SectionBox>
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'break-spaces',
          }}
        >
          {message.content}
        </Typography>
      </SectionBox>
    </Stack>
  );
};

const groupMessagesByDate = (
  messages: Message[],
  i18n: i18n,
): MessageGroupByDate[] => {
  return messages
    .sort(
      (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime(),
    )
    .reduce<MessageGroupByDate[]>((groups, message) => {
      const messageDatetime = isoToShortDate(message.datetime, i18n);

      const lastGroup = groups[groups.length - 1];
      if (!lastGroup || lastGroup.datetime !== messageDatetime) {
        groups.push({
          datetime: messageDatetime,
          messages: [message],
        });
      } else {
        lastGroup.messages.push(message);
      }

      return groups;
    }, []);
};
