import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { RadioInput } from 'components/inputs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExportFormat } from '../controllers/ExportDialogContentController';

interface ExportDialogContentViewProps {
  exportFormat: ExportFormat;
  onExportFormatChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  anonymized: boolean;
  onAnonymizedChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  isAnonymizedDisabled?: boolean;
  onCancelClick: () => void;
  onExportClick: () => void;
  isLoading: boolean;
  exportFailedMessage: string | null;
}

export function ExportDialogContentView({
  exportFormat,
  onExportFormatChange,
  anonymized,
  onAnonymizedChange,
  isAnonymizedDisabled,
  onCancelClick,
  onExportClick,
  isLoading,
  exportFailedMessage,
}: ExportDialogContentViewProps) {
  const { t } = useTranslation(['pedigreePrintExport']);
  const theme = useTheme();

  return (
    <>
      <DialogTitle>{t('export-pedigree.dialog.title')}</DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <Stack gap={2}>
            <RadioInput
              id="pedigree-export-format"
              options={[
                {
                  value: 'pdf',
                  label: t('export-pedigree.dialog.label.to-pdf'),
                },
                {
                  value: 'canRisk',
                  label: t('export-pedigree.dialog.label.to-canrisk'),
                },
              ]}
              value={exportFormat || ''}
              onChange={onExportFormatChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={anonymized}
                  onChange={onAnonymizedChange}
                  checked={anonymized}
                />
              }
              label={t('export-pedigree.dialog.label.anonymize')}
              disabled={isAnonymizedDisabled}
            />
          </Stack>
          {exportFailedMessage && (
            <Stack direction="row" alignItems="baseline" gap={0.5}>
              <Stack p="0.5px">
                <FontAwesomeIcon
                  icon={faWarning}
                  color={theme.palette.error.main}
                  style={{
                    fontSize: '11px',
                  }}
                />
              </Stack>
              <Typography color="error" variant="caption">
                {exportFailedMessage}
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          key="cancel"
          onClick={onCancelClick}
          disabled={isLoading}
          color="primary"
          variant="outlined"
        >
          {t('export-pedigree.dialog.button.cancel')}
        </Button>
        <LoadingButton
          key="export"
          onClick={onExportClick}
          loading={isLoading}
          color="primary"
          variant="contained"
        >
          {t('export-pedigree.dialog.button.export')}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
