import { WidgetProps } from '@rjsf/utils';
import { TextInput } from 'components/inputs';
import { useState } from 'react';
import { ReadonlyView } from '../views/ReadonlyView';

export const DateFieldWidget = (props: WidgetProps) => {
  const [value, setValue] = useState<string>(props.value || '');

  const label = props?.uiSchema?.['ui:title'] || '';
  const placeholder = props?.uiSchema?.['ui:placeholder'] || '';
  const error = props?.rawErrors?.length ? props.rawErrors[0] : '';

  const handleOnChange = (value: string) => {
    const formattedValue = formatDateInput(value);
    setValue(formattedValue);
    props.onChange(formattedValue);
  };

  if (props.readonly) {
    return (
      <ReadonlyView
        title={props.label}
        value={props.value}
        uiSchema={props.uiSchema}
      />
    );
  }

  return (
    <TextInput
      id={props.id}
      value={value}
      onChange={(e) => handleOnChange(e.target.value)}
      label={label}
      error={error}
      placeholder={placeholder}
      required={props.required}
    />
  );
};

export const formatDateInput = (date: string) => {
  if (date.length > 0) {
    let digitsOnly = date.replace(/\D/g, '');

    if (date.length > 4) {
      digitsOnly = `${digitsOnly.slice(0, 4)}.${digitsOnly.slice(4, 6)}${
        digitsOnly.slice(6, 8) ? `.${digitsOnly.slice(6, 8)}` : ''
      }`;
    }
    return digitsOnly;
  }
  return '';
};
