import { Pedigree } from 'api/patient/patient-service';
import React from 'react';
import { NodeMemberMenuValue } from '../nodeMenu/NodeMenu';
import { usePedigreeAddNodeAction } from './usePedigreeAddNodeAction';
import { usePedigreeDeleteNodeAction } from './usePedigreeDeleteNodeAction';

interface usePedigreeMutationProps {
  pedigree: Pedigree;
}

export function usePedigreeMutation(props: usePedigreeMutationProps) {
  const pedigreeId = props.pedigree.id;

  const { deleteNodeDialog, handleDeleteNodeAction, isLoading } =
    usePedigreeDeleteNodeAction({
      pedigreeId,
    });

  const { addNodeDialog, handleAddNodeAction } = usePedigreeAddNodeAction({
    pedigree: props.pedigree,
  });

  const handleNodeMenuOptionClick = (
    value: NodeMemberMenuValue,
    pedigreeNodeId: string,
  ) => {
    if (value === 'delete') {
      handleDeleteNodeAction(pedigreeNodeId);
      return;
    }
    return handleAddNodeAction(value, pedigreeNodeId);
  };

  const dialogs = [
    <React.Fragment key="deleteNodeDialog">{deleteNodeDialog}</React.Fragment>,
    <React.Fragment key="addNodeDialog">{addNodeDialog}</React.Fragment>,
  ];

  return {
    dialogs,
    isLoading,
    handleNodeMenuOptionClick,
  };
}
