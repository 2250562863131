import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  IconButton,
  Link,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import Dialog from 'components/common/Dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const INSTRUCTION_URL = 'https://arkus.ai/myfamilytree-guide/';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 40,
  color: theme.palette.primary.main,
}));

const InstructionalDialog: React.FC = () => {
  const { t } = useTranslation('patientPortal');
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Stack
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
        }}
      >
        <Card>
          <Stack justifyContent="center" padding={0.5}>
            <StyledIconButton onClick={handleOpen}>
              <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
            </StyledIconButton>
          </Stack>
        </Card>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        title={t('instructional-dialog.title')}
        key="instructional-dialog"
        element={
          <Typography>
            <Link href={INSTRUCTION_URL} target="_blank">
              {t('instructional.link-text')}
            </Link>{' '}
            {t('instructional.link-text2')}
          </Typography>
        }
        actions={[
          <Button
            key="instructional-dialog-button"
            onClick={handleClose}
            variant="outlined"
          >
            {t('instructional-dialog.button-text')}
          </Button>,
        ]}
      ></Dialog>
    </>
  );
};

export default InstructionalDialog;
