import { Checkbox, FormControlLabel } from '@mui/material';
import { WidgetProps } from '@rjsf/utils';
import { useMemo, useState } from 'react';
import { ReadonlyView } from '../views/ReadonlyView';

export const CheckboxWidget = (props: WidgetProps) => {
  const [checked, setChecked] = useState<boolean>(false);
  const disabled = props.uiSchema?.['ui:disabled'] || false;
  const noReadonly = props.uiSchema?.['ui:noReadonly'] || false;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    props.onChange(event.target.checked);
  };

  const newValue = useMemo(() => {
    if (!!props.value && props.value !== checked) {
      return props.value;
    } else {
      return checked;
    }
  }, [props.value, checked]);

  if (props.readonly && !noReadonly) {
    return (
      <ReadonlyView
        title={props.label}
        value={`${checked}`}
        uiSchema={props.uiSchema}
      />
    );
  }

  return (
    <FormControlLabel
      sx={{ m: 0 }}
      control={
        <Checkbox
          checked={newValue}
          onChange={handleOnChange}
          disabled={disabled}
        />
      }
      label={props.label}
    />
  );
};
