import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, Stack, Typography, useTheme } from '@mui/material';
import SectionBox from 'components/common/SectionBox';
import { ReactNode } from 'react';

interface SectionProps {
  chipLabel: string | null;
  titleIcon: IconDefinition;
  title: string;
  children: ReactNode;
  buttonVariant?: 'outlined' | 'contained';
  buttonText?: string;
  onClickButton?: () => void;
}

export const Section = (props: SectionProps) => {
  const {
    chipLabel,
    titleIcon,
    title,
    children,
    buttonVariant,
    buttonText,
    onClickButton,
  } = props;
  const theme = useTheme();

  return (
    <SectionBox sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {chipLabel && (
        <Chip
          label={chipLabel}
          sx={{
            width: 'fit-content',
            backgroundColor: theme.palette.warning.light,
          }}
        />
      )}
      <Stack direction="row" gap={1}>
        <FontAwesomeIcon
          icon={titleIcon}
          color={theme.palette.common.brand.blue}
          style={{ fontSize: '28px' }}
        />
        <Typography variant="h4">{title}</Typography>
      </Stack>
      {children}
      {onClickButton && (
        <Button
          onClick={onClickButton}
          variant={buttonVariant}
          sx={{
            width: 'fit-content',
          }}
        >
          {buttonText}
        </Button>
      )}
    </SectionBox>
  );
};
