import { usePatientService } from 'api/patient/patient-service';
import { AxiosError } from 'axios';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { NotAuthorized } from 'patientPortal/features/common/NotAuthorized';
import { PortalHome } from 'patientPortal/features/portalHome';
import { Layout } from 'patientPortal/layouts/Layout';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

export function PatientHome() {
  const { patientId } = useParams();
  const service = usePatientService();
  const navigate = useNavigate();

  const {
    data: pedigreeCase,
    error,
    isLoading: isLoadingPedigreeCase,
  } = useQuery(
    ['pedigreeCase', patientId],
    () => service?.getPedigreeCase(patientId || ''),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          navigate('../404');
          return;
        }
      },
      retry: false,
    },
  );

  const pedigreeCaseId = pedigreeCase?.id;

  const { data: pedigreeCaseInfo, isLoading: isLoadingPedigreeCaseInfo } =
    useQuery(
      ['pedigreeCaseInfo', pedigreeCaseId],
      () => service.getPedigreeCaseInformation(pedigreeCaseId || ''),
      {
        enabled: !!pedigreeCaseId,
        retry: false,
      },
    );

  const isLoading = isLoadingPedigreeCase || isLoadingPedigreeCaseInfo;

  if (isLoading) return <LoadingBackdrop open={isLoading} />;
  if (error && error.response?.status === 403) return <NotAuthorized />;
  if (!pedigreeCaseInfo) return null;

  return (
    <Layout>
      <PortalHome pedigreeCaseInfo={pedigreeCaseInfo} />
    </Layout>
  );
}
