import { Messages } from 'patientPortal/features/messages';
import { Layout } from 'patientPortal/layouts/Layout';

export const MessagesPage = () => {
  return (
    <Layout>
      <Messages />
    </Layout>
  );
};
