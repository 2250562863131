import { Stack, Typography } from '@mui/material';
import {
  PatientServiceProvider,
  usePatientService,
} from 'api/patient/patient-service';
import { AxiosError } from 'axios';
import { LoadingBackdrop } from 'components/common';
import {
  BankidAuthProvider,
  useBankidAuth,
} from 'features/auth/bankid/AuthProvider';
import { PatientPortalFooter } from 'patientPortal/layouts/PatientPortalFooter';
import { PatientPortalTopNav } from 'patientPortal/layouts/PatientPortalTopNav';
import { PatientHome } from 'patientPortal/pages';
import { FourOFour } from 'patientPortal/pages/404';
import { MessagesPage } from 'patientPortal/pages/messages';
import PedigreeCaseInactive from 'patientPortal/pages/pedigree/PedigreeCaseInactive';
import PedigreeCaseSubmitted from 'patientPortal/pages/pedigree/PedigreeCaseSubmitted';
import PedigreeEditor from 'patientPortal/pages/pedigree/PedigreeEditor';
import { Questionnaire } from 'patientPortal/pages/pedigree/Questionnaire';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

export const PATIENT_PATHS = {
  PATIENT: 'my-case',
  HOME: '',
  QUESTIONNAIRE: 'questionnaire',
  PEDIGREE: 'pedigree',
  MESSAGES: 'messages',
  PEDIGREE_SUBMITTED: 'submitted',
};

function PatientPortalRouter() {
  const service = usePatientService();
  const { patientId } = useParams<{
    patientId: string;
  }>();
  const location = useLocation();
  const is404Route = location.pathname.endsWith('/404');
  const isInactiveRoute = location.pathname.endsWith('/inactive');
  const isPedigreeRoute = location.pathname.endsWith('/pedigree');
  const isQuestionnaireRoute = location.pathname.endsWith('/questionnaire');

  const showFooter = !is404Route && !isPedigreeRoute && !isQuestionnaireRoute;

  const { data: requestingEntity, isLoading: isLoadingRequestingEntity } =
    useQuery(
      ['requestingEntity', patientId],
      () => service.getRequestingEntity(patientId as string),
      { retry: false, refetchOnWindowFocus: false },
    );

  const isLoading = isLoadingRequestingEntity;
  if (isLoading) return <LoadingBackdrop />;

  return (
    <>
      <PatientPortalTopNav />
      <Routes>
        <Route path="/404" element={<FourOFour />} />
        <Route path="/inactive" element={<PedigreeCaseInactive />} />
      </Routes>
      {!isInactiveRoute && <PatientPrivatePortalRouterWithAuthProvider />}
      {showFooter && requestingEntity && (
        <PatientPortalFooter
          phone={requestingEntity.phoneNumber}
          name={requestingEntity.name}
        />
      )}
    </>
  );
}

function PortalAuthenticatedRoutes() {
  const { accessToken, logout } = useBankidAuth();

  return (
    <PatientServiceProvider accessToken={accessToken} logout={logout}>
      <PatientPrivatePortalRouter />
    </PatientServiceProvider>
  );
}

function PatientPrivatePortalRouter() {
  const { logout } = useBankidAuth();
  const navigate = useNavigate();

  const { patientId } = useParams<{
    patientId: string;
  }>();

  const service = usePatientService();

  const { isLoading: isLoadingPatientCase, error } = useQuery(
    ['patientCase', patientId],
    () => service.getPatientCase(patientId as string),
    {
      onSuccess(data) {
        if (data.status === 'inactive') {
          return navigate('./inactive', {
            state: { inactiveCase: true },
          });
        }
      },
      onError: (error: AxiosError) => {
        if (error.response?.status !== 404) {
          logout();
        }
      },
      retry: false,
    },
  );

  const notFound = error && error?.response?.status === 404;

  const { isLoading: isLoadingPedigreeCase } = useQuery(
    ['pedigreeCaseByCaseId', patientId],
    () => service.getPedigreeCaseByPedigreeCaseId(patientId as string),
    {
      onSuccess(data) {
        return navigate(`/${PATIENT_PATHS.PATIENT}/${data.patientId}`, {
          replace: true,
        });
      },
      onError: () => {
        logout();
      },
      enabled: !!notFound,
      retry: false,
    },
  );

  if (isLoadingPatientCase || isLoadingPedigreeCase) return <LoadingBackdrop />;

  return (
    <Routes>
      <Route
        path="*"
        element={
          <Navigate to={`/${PATIENT_PATHS.PATIENT}/${patientId}`} replace />
        }
      />
      <Route path="/" element={<PatientHome />} />
      <Route
        path={`/${PATIENT_PATHS.QUESTIONNAIRE}`}
        element={<Questionnaire />}
      />
      <Route path={`/${PATIENT_PATHS.PEDIGREE}`} element={<PedigreeEditor />} />
      <Route
        path={PATIENT_PATHS.PEDIGREE_SUBMITTED}
        element={<PedigreeCaseSubmitted />}
      />
      <Route path={`/${PATIENT_PATHS.MESSAGES}`} element={<MessagesPage />} />
    </Routes>
  );
}

function PatientPrivatePortalRouterWithAuthProvider() {
  return (
    <>
      <Stack sx={{ flex: 1 }} direction="column">
        <BankidAuthProvider
          containerMaxWidth="sm"
          beforeOnLaunchPicker={<BankidLaunchTitle />}
        >
          <PortalAuthenticatedRoutes />
        </BankidAuthProvider>
      </Stack>
    </>
  );
}

function BankidLaunchTitle() {
  const { t } = useTranslation(['patientPortal']);
  return (
    <Stack gap={3} pb={2}>
      <Typography variant="h1">{t('login.title')}</Typography>
      <Typography>{t('login.content')}</Typography>
    </Stack>
  );
}

export default PatientPortalRouter;
