import { BucketProvider as BucketCoProvider } from '@bucketco/react-sdk';
import { LoadingBackdrop } from 'components/common';
import { PropsWithChildren } from 'react';
import { useUserContext } from './UserProvider';

const publishableKey = process.env.REACT_APP_BUCKET_KEY || '';

export const BucketProvider = ({ children }: PropsWithChildren) => {
  const { currentLoggedInUser } = useUserContext();

  const companyId = currentLoggedInUser?.tenantId;
  const userId = currentLoggedInUser?.id;

  return (
    <BucketCoProvider
      publishableKey={publishableKey}
      company={{ id: companyId }}
      user={{ id: userId }}
      loadingComponent={<LoadingBackdrop />}
    >
      {children}
    </BucketCoProvider>
  );
};

export const FEATURE_FLAGS = {
  geneticTestEligibility: 'genetic-test-eligibility',
  riskAssessment: 'risk-assessment',
  geneticTestApprovalManual: 'genetic-test-approval-manual',
  pedigreeRelationshipDegree: 'pedigree-relationship-degree',
} as const;
