export const useSuperscript = () => {
  const addSuperscript = (text: string): (string | JSX.Element)[] => {
    const regex = /\[\[([^\]]+)\]\]/g;
    const parts: (string | JSX.Element)[] = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      const superText = match[1];

      if (match.index > lastIndex) {
        parts.push(text.substring(lastIndex, match.index));
      }

      const nextChar = text[regex.lastIndex];
      const isComma = nextChar === ',';
      const supContent = isComma ? `${superText},` : superText;

      parts.push(
        <sup key={parts.length} style={{ fontSize: '10px' }}>
          {supContent}
        </sup>,
      );

      lastIndex = isComma ? regex.lastIndex + 1 : regex.lastIndex;
    }

    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }

    return parts;
  };

  return {
    addSuperscript,
  };
};
