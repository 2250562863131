import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import { PedigreeNodeSex } from 'common/features/pedigree/common/types';
import React, { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FemaleIcon from '../common/FemaleIcon';
import MaleIcon from '../common/MaleIcon';
import usePedigreeNodeConsumerStyles from '../hooks/usePedigreeNodeConsumerStyles';

interface NodeConsumerProps {
  sex: PedigreeNodeSex;
  name?: string;
  isIndex: boolean;
  isSelected: boolean;
  hasCancerHistory: boolean;
  hasError?: boolean;
  relativeRelationLabel?: string;
  addDiagnosisLabel?: string;
  cancerHistoryLabel?: string;
  deceasedLabel?: string;
  styleProps?: CSSProperties;
  onClick: () => void;
  nodeSize: {
    width: number;
    height: number;
  };
}

const NodeConsumer: React.FC<NodeConsumerProps> = (props) => {
  const {
    sex,
    name,
    isIndex,
    isSelected,
    onClick,
    cancerHistoryLabel,
    addDiagnosisLabel,
    hasCancerHistory,
    deceasedLabel,
    hasError = false,
    nodeSize,
    styleProps,
  } = props;

  const {
    iconColor,
    textColor,
    backgroundColor,
    hoverBackgroundColor,
    borderColor,
    hoverBorderColor,
    borderWidth,
  } = usePedigreeNodeConsumerStyles({
    isIndex,
    isSelected,
    hasCancerHistory,
    hasError,
  });

  const { t } = useTranslation(['patientPortal']);

  const relativeRelationLabel = useMemo(() => {
    return isIndex
      ? t('pedigree.node.relationship.label.self')
      : props.relativeRelationLabel;
  }, [isIndex, props.relativeRelationLabel, t]);

  const theme = useTheme();
  const palette = theme.palette;
  const applyHoverEffect = !isSelected && !isIndex;

  return (
    <Card
      elevation={isSelected ? 0 : 3}
      sx={{
        p: 1,
        paddingLeft: 0.5,
        width: nodeSize.width,
        height: nodeSize.height,
        boxSizing: 'border-box',
        backgroundColor,
        borderWidth,
        borderColor,
        borderStyle: 'solid',
        overflow: 'visible',
        ':hover': applyHoverEffect
          ? {
              backgroundColor: hoverBackgroundColor,
              cursor: 'pointer',
              borderColor: hoverBorderColor,
            }
          : {},
        ...styleProps,
      }}
      onClick={onClick}
      data-testid="memberNode"
    >
      <Stack direction="row" spacing={0.5}>
        <Box sx={{ width: 26 }}>
          <SexIcon sex={sex} iconColor={iconColor} />
        </Box>
        <Stack spacing={0.5}>
          {relativeRelationLabel && (
            <Typography variant="h6" color={textColor}>
              {relativeRelationLabel}
            </Typography>
          )}
          {name && (
            <Typography variant="caption" color={textColor}>
              {name}
            </Typography>
          )}
          {deceasedLabel && (
            <Typography variant="caption" color={textColor}>
              {deceasedLabel}
            </Typography>
          )}
          {cancerHistoryLabel && (
            <Typography variant="caption" color={textColor}>
              {cancerHistoryLabel}
            </Typography>
          )}
          {addDiagnosisLabel && (
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <FontAwesomeIcon
                icon={faWarning}
                width={12}
                color={palette.error.main}
              />
              <Typography variant="caption" color={palette.error.main}>
                {addDiagnosisLabel}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default NodeConsumer;

interface SexIconProps {
  sex: PedigreeNodeSex;
  iconColor: string;
}

export const SexIcon: React.FC<SexIconProps> = (props) => {
  const { sex, iconColor } = props;

  if (sex === PedigreeNodeSex.FEMALE) {
    return <FemaleIcon color={iconColor} />;
  }
  return <MaleIcon color={iconColor} />;
};
