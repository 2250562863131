import { Stack, Typography } from '@mui/material';
import { FieldProps } from '@rjsf/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DynamicFormContext } from '..';
import { ReadonlyView } from '../views/ReadonlyView';

export const CalculatedBmiField = (props: FieldProps) => {
  const { formState } = useContext(DynamicFormContext);
  const [value, setValue] = useState(props.formData);

  const { formData } = formState || {};
  const { weight, height } = formData?.lifeStyle || {};
  const title = props?.uiSchema?.['ui:title'] || '';

  const formatValue = useMemo(() => {
    if (weight && height) {
      return (weight / (height / 100) ** 2).toFixed(1);
    }
    return '';
  }, [height, weight]);

  const updateValue = useCallback(() => {
    setValue(formatValue);
    return props.onChange(Number(formatValue) || undefined);
  }, [formatValue, props]);

  useEffect(() => {
    const shouldUpdateValue =
      props.formData !== formatValue && !props.readonly && !!weight && !!height;

    if (shouldUpdateValue) {
      updateValue();
    }
  }, [
    formatValue,
    height,
    props.formData,
    props.readonly,
    updateValue,
    weight,
  ]);

  if (props.readonly) {
    return (
      <ReadonlyView
        title={title}
        value={props.formData}
        uiSchema={props.uiSchema}
      />
    );
  }

  return (
    <>
      <Typography variant="body1">{title}</Typography>
      <Stack height={40} justifyContent="center" mt={'4px'}>
        <Typography variant="body1">{value}</Typography>
      </Stack>
    </>
  );
};
