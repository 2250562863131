import { Stack } from '@mui/material';
import { FieldErrorProps } from '@rjsf/utils';
import { FormError } from 'components/forms';
import { useContext } from 'react';
import { FieldTemplateContext } from './FieldTemplate';

export const FieldErrorTemplate = (props: FieldErrorProps) => {
  const { errorWhiteSpace } = useContext(FieldTemplateContext);
  const { errors } = props;

  if (!errors || !errors.length) {
    return '';
  }

  return (
    <Stack>
      {errors.map((error: any, i: number) => {
        return (
          <Stack
            sx={{
              whiteSpace: errorWhiteSpace,
            }}
            key={i}
          >
            {error && <FormError error={error} />}
          </Stack>
        );
      })}
    </Stack>
  );
};
