import { faCircleInfo, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { UiSchema } from '@rjsf/utils';
import { usePatientService } from 'api/patient/patient-service';
import { useNotificationContext } from 'common/contexts/NotificationProvider';
import { DynamicForm } from 'common/features/dynamicForm';
import { useMedicalForm } from 'common/hooks/useMedicalForm';
import { LoadingBackdrop } from 'components/common';
import Dialog from 'components/common/Dialog';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const PersonalInfoForm = ({
  pedigreeCaseId,
}: {
  pedigreeCaseId: string;
}) => {
  const service = usePatientService();
  const { setNotification } = useNotificationContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(['patientPortal']);

  const navigateToHome = useCallback(() => {
    return navigate('..');
  }, [navigate]);

  const showSuccessNotification = useCallback(() => {
    setNotification({
      message: t('personal-information-form.save-success'),
      type: 'toast',
    });
  }, [setNotification, t]);

  const submitMutation = useMutation(
    (data: any) => {
      return service?.submitQuestionnaireForm(pedigreeCaseId || '', data);
    },
    {
      onSuccess: () => {
        handleOnSuccessSubmit();
        navigateToHome();
        return showSuccessNotification();
      },
    },
  );

  const onSubmit = (data: any) => {
    submitMutation.mutate(data.formData);
  };

  const {
    formRef,
    isDirty,
    dialogProps,
    setFormData,
    handleOnSuccessSubmit,
    onClickClose,
    onClickSave,
  } = useMedicalForm({
    isLoading: submitMutation.isLoading,
    dialogNameOnClickClose: 'unsavedChanges',
    handleCloseFormCalback: navigateToHome,
  });

  const { data, isLoading } = useQuery(
    ['PatientForm', pedigreeCaseId, i18n.language],
    () => service?.getQuestionnaireForm(pedigreeCaseId || ''),
    {
      enabled: !isDirty,
    },
  );

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      props: {
        disabled: true,
      },
      norender: true,
    },
  };

  if (isLoading) return <LoadingBackdrop />;
  if (!data) return null;

  return (
    <Container maxWidth="sm" disableGutters>
      <Stack gap={3}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Stack width={40} height={40} justifyContent="center">
            <FontAwesomeIcon
              icon={faUser}
              size="lg"
              color={theme.palette.common.brand.blue}
            />
          </Stack>
          <Typography variant="h2">
            {t('personal-information-form.title')}
          </Typography>
        </Stack>
        <Alert
          severity="info"
          icon={
            <FontAwesomeIcon
              icon={faCircleInfo}
              fontSize={20}
              style={{
                padding: 2,
              }}
            />
          }
        >
          {t('personal-information-form.description')}
        </Alert>
        <DynamicForm
          key={i18n.language}
          schema={data.jsonSchema}
          uiSchema={{ ...uiSchema, ...data.uiSchema }}
          formData={data.formData}
          formContext={{ border: true, summaryBackgroundColor: '#FFFFF' }}
          onChange={({ formData }) => setFormData(formData)}
          onSubmit={onSubmit}
          ref={formRef}
        />
        <Stack direction="row" gap={2} mb={4}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={onClickClose}
          >
            {t('personal-information-form.cancel-button-text')}
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onClickSave}
            disabled={!isDirty}
          >
            {t('personal-information-form.save-button-text')}
          </Button>
        </Stack>
      </Stack>
      <Dialog {...dialogProps} />
    </Container>
  );
};
