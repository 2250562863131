import {
  faCaretDown,
  faCaretUp,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GeneticRiskAssessment } from 'api/patient/staff-patient-service';
import { useSuperscript } from 'common/hooks/useSuperscript';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface GeneticRiskAssessmentsProps {
  geneticRiskAssessments: GeneticRiskAssessment[];
}

export const GeneticRiskAssessments = (props: GeneticRiskAssessmentsProps) => {
  const { t } = useTranslation(['iPedigree', 'common']);

  const { geneticRiskAssessments } = props;

  const showDivider = useCallback(
    (index: number) => {
      return index < geneticRiskAssessments.length - 1;
    },
    [geneticRiskAssessments],
  );

  if (!geneticRiskAssessments.length) {
    return (
      <Typography variant="h5">
        {t('risk-assessment.genetic-risk-assessment.no-variants')}
      </Typography>
    );
  }

  return (
    <Stack gap={3}>
      {geneticRiskAssessments.map(
        ({ gene, localizedSubtitle, associatedRisk, variants }, index) => (
          <Stack gap={2} key={index}>
            <Typography variant="h5">{gene}</Typography>
            <Typography variant="body1">{localizedSubtitle}</Typography>
            <LocalizedRisksList
              localizedRisks={associatedRisk.localizedRisks}
            />
            <SourceList sources={associatedRisk.sources} />
            <Variants variants={variants} />
            {showDivider(index) && <Divider />}
          </Stack>
        ),
      )}
    </Stack>
  );
};

const LocalizedRisksList = ({
  localizedRisks,
}: {
  localizedRisks: {
    title: string;
    paragraphs: string[];
    footnotes: {
      id: number;
      description: string;
    }[];
  }[];
}) => {
  const { addSuperscript } = useSuperscript();

  return (
    <>
      {localizedRisks.map(({ title, paragraphs, footnotes }, index) => (
        <Stack key={index} gap={1}>
          <Typography variant="h5">{addSuperscript(title)}</Typography>
          {paragraphs?.map((paragraph, index) => (
            <Typography key={index} variant="body1">
              {paragraph}
            </Typography>
          ))}
          {footnotes?.map(({ id, description }, index) => (
            <Typography key={index} variant="caption">
              <sup style={{ fontSize: '10px' }}>{id}</sup> {description}
            </Typography>
          ))}
        </Stack>
      ))}
    </>
  );
};

const SourceList = ({
  sources,
}: {
  sources: {
    localizedName: string;
    url: string;
  }[];
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['iPedigree']);

  if (!sources?.length) return null;

  return (
    <Stack direction="row" gap={2}>
      <Typography variant="body2Opacity70">
        {t('risk-assessment.genetic-risk-assessment.source')}:
      </Typography>
      <Stack>
        {sources?.map((source, index) => (
          <Link
            key={index}
            href={source.url}
            target="_blank"
            sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}
          >
            <Typography variant="body2" color={theme.palette.common.brand.blue}>
              {source.localizedName}
            </Typography>
            <FontAwesomeIcon
              icon={faExternalLinkAlt}
              color={theme.palette.common.brand.blue}
            />
          </Link>
        ))}
      </Stack>
    </Stack>
  );
};

const ShowVariantsButton = ({
  showVariants,
  onClick,
}: {
  showVariants: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslation(['iPedigree']);

  const icon = showVariants ? (
    <FontAwesomeIcon icon={faCaretUp} />
  ) : (
    <FontAwesomeIcon icon={faCaretDown} />
  );

  return (
    <Button
      onClick={onClick}
      endIcon={icon}
      variant="text"
      size="small"
      sx={{ width: 'fit-content' }}
    >
      {showVariants
        ? t('risk-assessment.genetic-risk-assessment.hide-variants.buttonText')
        : t('risk-assessment.genetic-risk-assessment.show-variants.buttonText')}
    </Button>
  );
};

const Variants = ({
  variants,
}: {
  variants: {
    rsid: string;
    identifier: string;
    location: string;
  }[];
}) => {
  const { t } = useTranslation(['iPedigree']);
  const [showVariants, setShowVariants] = useState<boolean>(false);

  if (!variants?.length) return null;

  return (
    <>
      <ShowVariantsButton
        showVariants={showVariants}
        onClick={() => setShowVariants(!showVariants)}
      />
      {showVariants && (
        <Stack gap={2} pl={1}>
          {variants.map(({ rsid, identifier, location }, index) => (
            <Stack direction="row" gap={3} key={index}>
              <Typography variant="caption">
                {t('risk-assessment.genetic-risk-assessment.variant')}{' '}
                {index + 1}
              </Typography>
              <Stack>
                <Typography variant="caption">
                  {t('risk-assessment.genetic-risk-assessment.rsID-number')}{' '}
                  {rsid}
                </Typography>
                <Typography variant="caption">
                  {t('risk-assessment.genetic-risk-assessment.identifier')}{' '}
                  {identifier}
                </Typography>
                <Stack direction="row" spacing={0.5}>
                  <Typography variant="caption">
                    {t('risk-assessment.genetic-risk-assessment.location')}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    {location}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      )}
    </>
  );
};
