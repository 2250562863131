import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography } from '@mui/material';

interface TitleWithIconProps {
  title: string;
  subtitle?: string;
  icon: IconDefinition;
  iconColor?: string;
}
export function TitleWithIcon(props: TitleWithIconProps) {
  return (
    <Stack direction="row" spacing={2}>
      <Box
        pt={0.4}
        sx={{ display: 'inline', color: props.iconColor || 'info.main' }}
      >
        <FontAwesomeIcon
          icon={props.icon}
          fontSize={20}
          style={{
            padding: 2,
          }}
        />
      </Box>
      <Stack direction="column">
        <Typography variant="h4">{props.title}</Typography>
        {props.subtitle && (
          <Typography variant="caption">{props.subtitle}</Typography>
        )}
      </Stack>
    </Stack>
  );
}
