import { Stack } from '@mui/material';
import { usePatientService } from 'api/patient/patient-service';
import { DynamicForm } from 'common/features/dynamicForm';
import { CustomizableFormData } from 'common/features/pedigree/common/types';
import { useMedicalForm } from 'common/hooks/useMedicalForm';
import Dialog from 'components/common/Dialog';
import Drawer from 'components/common/drawer/Drawer';
import { DrawerFooterActions } from 'components/common/drawer/DrawerFooterActions';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface RelativeNodeFormProps {
  pedigreeId: string;
  relativeLabel: string;
  selectedNodes: string[];
  setSelectedNodes: (nodes: string[]) => void;
  drawerWidth: number;
}

export const RelativeNodeForm = (props: RelativeNodeFormProps) => {
  const {
    pedigreeId,
    relativeLabel,
    selectedNodes,
    setSelectedNodes,
    drawerWidth,
  } = props;
  const service = usePatientService();
  const queryClient = useQueryClient();
  const { i18n } = useTranslation(['patientPortal']);

  const drawerIsOpen = props.selectedNodes.length === 1;
  const [selectedNodeId] = selectedNodes;

  const handleCloseDrawer = useCallback(() => {
    setSelectedNodes([]);
  }, [setSelectedNodes]);

  const submitRelativeMutation = useMutation(
    (data: CustomizableFormData) => {
      return service?.submitRelativeForm(
        pedigreeId,
        selectedNodeId || '',
        data,
      );
    },
    {
      onSuccess: () => {
        handleOnSuccessSubmit();
        queryClient.invalidateQueries('pedigree');
      },
      onError: () => {
        openSubmitFailureDialog();
      },
    },
  );

  const submitForm = ({ formData }: { formData?: CustomizableFormData }) => {
    if (!formData) return;
    submitRelativeMutation.mutate(formData);
  };

  const {
    formRef,
    uiSchema,
    changesSaved,
    isDirty,
    dialogProps,
    setFormData,
    handleOnSuccessSubmit,
    handleOnClickOutside,
    onClickClose,
    onClickSave,
    openSubmitFailureDialog,
  } = useMedicalForm({
    isLoading: submitRelativeMutation.isLoading,
    nodeName: relativeLabel,
    handleCloseFormCalback: handleCloseDrawer,
  });

  const { data: relativeForm } = useQuery(
    ['nodeForm', pedigreeId, selectedNodeId, i18n.language],
    () => service?.getRelativeForm(pedigreeId || '', selectedNodeId || ''),
    {
      enabled: !!selectedNodeId && !isDirty,
      refetchOnWindowFocus: false,
    },
  );

  if (!relativeForm) return null;

  return (
    <>
      <Drawer
        title={relativeLabel}
        isOpen={drawerIsOpen}
        hideBackdrop={false}
        onClose={onClickClose}
        width={drawerWidth}
        onClickOutside={handleOnClickOutside}
      >
        <Stack pb={9.5}>
          <DynamicForm
            key={selectedNodeId}
            schema={relativeForm.jsonSchema}
            uiSchema={{ ...uiSchema, ...relativeForm?.uiSchema }}
            formData={relativeForm.formData}
            onChange={({ formData }) => setFormData(formData)}
            onSubmit={submitForm}
            ref={formRef}
          />
        </Stack>
        <DrawerFooterActions
          onSave={onClickSave}
          onCancel={onClickClose}
          isSaveDisabled={!isDirty}
          hasSavedChanges={changesSaved}
        />
      </Drawer>
      <Dialog {...dialogProps} />
    </>
  );
};
