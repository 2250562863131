import { Link, Stack, Typography } from '@mui/material';
import Footer from 'components/layout/Footer';
import { useTranslation } from 'react-i18next';

interface PatientPortalFooterProps {
  phone: string;
  name: string;
}

const ARKUS_AI = 'https://arkus.ai';

export const PatientPortalFooter = (props: PatientPortalFooterProps) => {
  const { t } = useTranslation(['patientPortal']);

  return (
    <Footer>
      <Stack gap={3}>
        <Stack direction="column" gap={1}>
          <Typography variant="body1">{props.name}</Typography>
          <Typography variant="body2">
            {t('footer-contact-number')}: {props.phone}
          </Typography>
        </Stack>
        <Stack direction="row" gap={0.5}>
          <Typography variant="caption">{t('footer-service-by')}</Typography>
          <Link variant="body2" href={ARKUS_AI} target="_blank">
            {t('footer-arkus-name')}
          </Link>
        </Stack>
      </Stack>
    </Footer>
  );
};
