import { Dialog } from '@mui/material';
import { PrintDialogContentView } from '../views/PrintDialogContentView';
import {
  ExportDialogContentController,
  ExportFormat,
} from './ExportDialogContentController';

export type DialogType = 'print' | 'export';

interface PrintExportDialogControllerProps {
  onClose: () => void;
  isOpen: boolean;
  isLoading: boolean;
  exportFailedMessage: string | null;
  onPrintClick: (anonymized: boolean) => void;
  onExportClick: (format: ExportFormat, anonymized: boolean) => void;
  dialogType: DialogType;
}

export function PrintExportDialogController(
  props: PrintExportDialogControllerProps,
) {
  const {
    onClose,
    isOpen,
    onExportClick,
    onPrintClick,
    dialogType,
    isLoading,
    exportFailedMessage,
  } = props;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {dialogType === 'print' ? (
        <PrintDialogContentView
          onPrintClick={onPrintClick}
          isLoading={isLoading}
        />
      ) : (
        <ExportDialogContentController
          closeDialog={onClose}
          onExportClick={onExportClick}
          isLoading={isLoading}
          exportFailedMessage={exportFailedMessage}
        />
      )}
    </Dialog>
  );
}
