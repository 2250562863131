import {
  Diagnose,
  PedigreeNodeSex,
} from 'common/features/pedigree/common/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeMemberMenuValue, NodeMenu } from '../nodeMenu/NodeMenu';
import NodeConsumer from './NodeConsumer';

interface PedigreeMemberNodeProps {
  isIndex: boolean;
  sex: PedigreeNodeSex;
  hasDiagnoseHistory: boolean;
  relativeRelationLabel: string;
  allowNodeClick?: boolean;
  isSelected: boolean;
  nodeSize: { width: number; height: number };
  onNodeClick: (isSelected: boolean) => void;
  handleMemberMenuOptionClick?: (value: NodeMemberMenuValue) => void;
  diagnoses: Diagnose[];
  deceased: boolean;
  name?: string;
}

const PedigreeMemberNode: React.FC<PedigreeMemberNodeProps> = (props) => {
  const { t } = useTranslation(['patientPortal']);

  const nodeInfoLabel = useMemo(() => {
    const { hasDiagnoseHistory, diagnoses, deceased } = props;
    let cancerHistoryLabel = '';
    let addDiagnosisLabel = '';
    let deceasedLabel = '';

    if (!!hasDiagnoseHistory) {
      cancerHistoryLabel = t('pedigree.node.member.cancer-history.label');
    }

    const validDiagnoses = diagnoses.filter(
      ({ diseaseName }) =>
        !!diseaseName && diseaseName !== 'null' && diseaseName !== 'undefined',
    );

    if (!!hasDiagnoseHistory && !validDiagnoses.length) {
      addDiagnosisLabel = t('pedigree.node.member.add-diagnosis.label');
    }

    if (!!deceased) {
      deceasedLabel = t('pedigree.node.member.is-deceased.label');
    }

    return {
      cancerHistoryLabel,
      addDiagnosisLabel,
      deceasedLabel,
    };
  }, [props, t]);

  const handleNodeClick = () => {
    if (props.allowNodeClick && !props.isIndex) {
      props.onNodeClick(!props.isSelected);
    }
  };

  return (
    <>
      <NodeConsumer
        {...{
          sex: props.sex,
          name: props.name,
          isIndex: props.isIndex,
          isSelected: props.isSelected,
          relativeRelationLabel: props.relativeRelationLabel,
          nodeSize: props.nodeSize,
          onClick: handleNodeClick,
          hasCancerHistory: props.hasDiagnoseHistory,
          hasError: !!nodeInfoLabel.addDiagnosisLabel && !props.isSelected,
          cancerHistoryLabel: nodeInfoLabel.cancerHistoryLabel,
          addDiagnosisLabel: nodeInfoLabel.addDiagnosisLabel,
          deceasedLabel: nodeInfoLabel.deceasedLabel,
        }}
      />
      {props.handleMemberMenuOptionClick && (
        <NodeMenu
          handleMemberMenuOptionClick={props.handleMemberMenuOptionClick}
        />
      )}
    </>
  );
};

export default PedigreeMemberNode;
