import { RJSFSchema } from '@rjsf/utils/lib/types';
import AJV8Validator from '@rjsf/validator-ajv8/lib/validator';
import { SchemaValidateFunction } from 'ajv';

type ErrorParams = {
  keyword: 'maximumAge' | 'maximumAgeRange';
  params: {
    limit: number;
    dataValue: number;
  };
};

const getValueFromPaths = (
  data: any,
  paths: string | string[],
): string | number | undefined => {
  const pathArray = Array.isArray(paths) ? paths : [paths];

  for (const path of pathArray) {
    const parts = path.split('/').slice(1);
    const value = parts.reduce(
      (acc, part) => (acc ? acc[part] : undefined),
      data,
    );

    if (value !== undefined) {
      return value;
    }
  }

  return undefined;
};

const parseRange = (value: string): [number, number] => {
  const rangeMinKeywords = ['before'];
  const rangeMaxKeywords = ['after'];

  const hasRangeMinKeyword = rangeMinKeywords.some((keyword) =>
    value.includes(keyword),
  );
  const hasRangeMaxKeyword = rangeMaxKeywords.some((keyword) =>
    value.includes(keyword),
  );

  if (hasRangeMinKeyword || hasRangeMaxKeyword) {
    const match = value.match(/\d+/);
    if (match) {
      const rangeNumber = Number.parseInt(match[0], 10);
      return hasRangeMinKeyword ? [0, rangeNumber] : [rangeNumber, rangeNumber];
    }
  }

  const rangeMatch = value.match(/^(\d+)-(\d+)$/);
  if (rangeMatch) {
    const [, start, end] = rangeMatch;
    return [parseInt(start, 10), parseInt(end, 10)];
  }

  return [0, 0];
};

const calculateMaxAge = (reference: string): number => {
  const isYear = reference.length === 4;
  return isYear
    ? new Date().getFullYear() - parseInt(reference, 10)
    : parseInt(reference, 10);
};

const createValidationError = (errorParam: ErrorParams) => ({
  ...errorParam,
  message: `Value ${errorParam.params.dataValue} exceeds the limit ${errorParam.params.limit}`,
  dataPath: '',
  schemaPath: '',
});

const setValidationError = (
  validate: SchemaValidateFunction,
  errorParam: ErrorParams,
) => {
  validate.errors = [createValidationError(errorParam)];
};

const validateMaximumAge = (
  schema: { $data: string | string[] },
  data: string | number,
  validate: SchemaValidateFunction,
  rootData: any,
) => {
  const dynamicPath = schema.$data;
  const referenceValue = getValueFromPaths(rootData, dynamicPath);

  if (!referenceValue) return true;

  const dataValue = parseInt(String(data), 10);
  const maxAge = calculateMaxAge(referenceValue.toString());

  if (dataValue > maxAge) {
    setValidationError(validate, {
      keyword: 'maximumAge',
      params: { limit: maxAge, dataValue },
    });
    return false;
  }

  return true;
};

const validateMaximumAgeRange = (
  schema: { $data: string | string[] },
  data: string | number,
  validate: SchemaValidateFunction,
  rootData: any,
) => {
  const dynamicPath = schema.$data;
  const referenceValue = getValueFromPaths(rootData, dynamicPath);

  if (!referenceValue) return true;

  const [min, max] = parseRange(data.toString());
  const maxAge = calculateMaxAge(referenceValue.toString());

  if (maxAge < min && maxAge < max) {
    setValidationError(validate, {
      keyword: 'maximumAgeRange',
      params: { limit: maxAge, dataValue: min },
    });
    return false;
  }

  return true;
};

export const setupCustomValidation = (
  customValidator: AJV8Validator<any, RJSFSchema, any>,
) => {
  const ajv = customValidator.ajv;

  ajv.addKeyword({
    keyword: 'maximumAge',
    validate: function validate(schema, data, _, { rootData }: any) {
      return validateMaximumAge(schema, data, validate, rootData);
    },
  });

  ajv.addKeyword({
    keyword: 'maximumAgeRange',
    validate: function validate(schema, data, _, { rootData }: any) {
      return validateMaximumAgeRange(schema, data, validate, rootData);
    },
  });
};
