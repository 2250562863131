import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';
import { WidgetProps } from '@rjsf/utils';
import { InputWrapper } from 'components/inputs';
import { useCallback, useId, useMemo } from 'react';
import { ReadonlyView } from '../views/ReadonlyView';

export function AutocompleteWidget(props: WidgetProps) {
  const { onChange, value, multiple } = props;

  const labelInfo = (props.uiSchema?.['ui:tooltip'] as string) || undefined;
  const id = `select-widget-${useId()}`;
  const freeSolo = Boolean(props.options?.freeSolo) || false;
  const inputByValue = props.uiSchema?.['ui:inputByValue'] || false;

  const error = props?.rawErrors?.length ? props.rawErrors[0] : '';
  const placeholder = (props.uiSchema?.['ui:placeholder'] as string) || '';
  const options: { label: string; value: string }[] = useMemo(
    () =>
      props.options?.enumOptions || props.uiSchema?.['ui:enumOptions'] || [],
    [props.options, props.uiSchema],
  );

  const inputWidth = props.uiSchema?.['ui:inputWidth'] || '100%';

  const componentType = useMemo(() => {
    if (!freeSolo && !multiple) return 'simple';
    if (freeSolo && !multiple) return 'freeSolo';
    if (!freeSolo && multiple) return 'multiple';
    if (freeSolo && multiple) return 'multipleFreeSolo';
    throw new Error('Invalid component type');
  }, [freeSolo, multiple]);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        error={!!error}
        {...params}
        size="small"
        placeholder={placeholder}
        sx={{ width: inputWidth }}
      />
    ),
    [error, placeholder, inputWidth],
  );

  if (props.readonly) {
    const getOptionLabel = (value: string) =>
      options.find((option) => option.value === value)?.label || value;

    const newValue = Array.isArray(value)
      ? value.map(getOptionLabel)
      : getOptionLabel(value);

    const fieldValue = Array.isArray(newValue) ? newValue.join(', ') : newValue;

    return (
      <ReadonlyView
        title={props.label}
        value={fieldValue}
        uiSchema={props.uiSchema}
      />
    );
  }

  return (
    <InputWrapper
      label={props.label}
      childId={id}
      labelInfo={labelInfo}
      required={props.required}
      fullWidth={true}
    >
      {componentType === 'simple' && (
        <SimpleAutocomplete
          options={options}
          onChange={onChange}
          value={value as string}
          renderInput={renderInput}
        />
      )}
      {componentType === 'freeSolo' && (
        <FreesoloAutocomplete
          options={options}
          onChange={onChange}
          value={value as string}
          renderInput={renderInput}
          inputByValue={inputByValue}
        />
      )}
      {componentType === 'multiple' && (
        <MultipleAutocomplete
          options={options}
          onChange={onChange}
          value={value as string[]}
          renderInput={renderInput}
        />
      )}
      {componentType === 'multipleFreeSolo' && (
        <MultipleAndFreeSoloAutocomplete
          options={options}
          onChange={onChange}
          value={value as string[]}
          renderInput={renderInput}
        />
      )}
    </InputWrapper>
  );
}

interface BaseAutocompleteProps {
  options: { value: string; label: string }[];
  renderInput: (params: AutocompleteRenderInputParams) => JSX.Element;
}

interface AutocompleteProps extends BaseAutocompleteProps {
  onChange: (value: string) => void;
  value: string;
  inputByValue?: boolean;
}

function SimpleAutocomplete(props: AutocompleteProps) {
  const value = useMemo(() => {
    return props.options.find((option) => option.value === props.value) || null;
  }, [props.options, props.value]);

  const onChange = (_: any, value: any) => {
    props.onChange(value?.value || undefined);
  };

  return (
    <Autocomplete
      options={props.options}
      onChange={onChange}
      value={value}
      renderInput={props.renderInput}
    />
  );
}

function FreesoloAutocomplete(props: AutocompleteProps) {
  const value = useMemo(() => {
    return (
      props.options.find((option) => option.value === props.value) ||
      props.value
    );
  }, [props.options, props.value]);

  const onChange = (_: any, newValue: any) => {
    if (!!newValue?.value) {
      const value = props.inputByValue ? newValue.value : newValue.label;
      return props.onChange(value);
    }
    return props.onChange(newValue || undefined);
  };

  const onInputChange = (_: any, newInputValue: any) => {
    if (value === newInputValue) return;
    const isOption = props.options.some(
      (option) => option?.label === newInputValue,
    );
    if (!isOption) props.onChange(newInputValue);
  };

  return (
    <Autocomplete
      options={props.options}
      onChange={onChange}
      value={value || null}
      onInputChange={onInputChange}
      renderInput={props.renderInput}
      freeSolo
    />
  );
}

interface MultipleAutocompleteProps extends BaseAutocompleteProps {
  onChange: (value: string[]) => void;
  value: string[];
}

function MultipleAutocomplete(props: MultipleAutocompleteProps) {
  const value = useMemo(() => {
    return props.options.filter((option) => props.value.includes(option.value));
  }, [props.options, props.value]);

  const onChange = (_: any, value: any) => {
    props.onChange(value?.map((v: any) => v.value) || []);
  };

  return (
    <Autocomplete
      options={props.options}
      onChange={onChange}
      value={value}
      renderInput={props.renderInput}
      multiple
    />
  );
}

function MultipleAndFreeSoloAutocomplete(props: MultipleAutocompleteProps) {
  const options = useMemo(() => {
    return props.options.map((option) => option.value);
  }, [props.options]);

  const onChange = (_: any, value: any) => {
    props.onChange(value || []);
  };

  const getOptionLabel = (value: string) => {
    return (
      props.options.find((option) => option.value === value)?.label || value
    );
  };

  return (
    <Autocomplete
      options={options}
      onChange={onChange}
      value={props.value}
      renderInput={props.renderInput}
      multiple
      freeSolo
      autoSelect={true}
      getOptionLabel={getOptionLabel}
    />
  );
}
