import { Divider, Stack, Typography } from '@mui/material';
import { useStaffPatientService } from 'api/patient/staff-patient-service';
import { PedigreeCase } from 'api/pedigreeCase/staff-pedigree-case-service';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { CollapsibleSection } from '../common/CollapsibleSection';
import { CanRiskFileExporter } from './CanRiskFileExporter';
import { GeneticRiskAssessments } from './GeneticRiskAssessments';
import { GeneticTestsForm } from './GeneticTestsForm';

export const RiskAssessment = ({
  patientId,
  pedigreeCase,
}: {
  patientId: string;
  pedigreeCase?: PedigreeCase;
}) => {
  const patientService = useStaffPatientService();

  const { t } = useTranslation(['iPedigree']);

  const hasPedigree = pedigreeCase?.status === 'pedigreeProvided';

  const {
    data: geneticRiskAssessmentResults,
    isLoading,
    error,
  } = useQuery(
    ['geneticRiskAssessmentResults', patientId],
    () => patientService.getGeneticRiskAssessmentResults(patientId),
    {
      enabled: !!patientId && hasPedigree,
      retry(failureCount, error: AxiosError) {
        if (error.response?.status === 404) {
          return false;
        }
        return failureCount <= 3;
      },
      refetchOnWindowFocus: false,
    },
  );

  const description = !hasPedigree
    ? t('risk-assessment.no-pedigree.description')
    : t('risk-assessment.genetic-test-empty-state.description');

  const hasResults = geneticRiskAssessmentResults && !error;

  return (
    <CollapsibleSection
      title={t('risk-assessment.title')}
      isLoading={isLoading}
    >
      <Stack gap={3}>
        <CanRiskFileExporter
          pedigreeCaseId={pedigreeCase?.id}
          disabled={!hasPedigree}
        />
        <Divider />
        <GeneticTestsForm
          disabled={!hasPedigree}
          patientId={patientId}
          pedigreeCaseId={pedigreeCase?.id}
          patientName={pedigreeCase?.patientDetails?.name}
        />
        {(!geneticRiskAssessmentResults || error) && (
          <Typography variant="body2Opacity70">{description}</Typography>
        )}
      </Stack>
      {hasResults && (
        <GeneticRiskAssessments
          geneticRiskAssessments={
            geneticRiskAssessmentResults.geneticRiskAssessments
          }
        />
      )}
    </CollapsibleSection>
  );
};
