import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { InstructionBox } from './common/InstructionBox';

interface PickRelativesWithCancerHistoryOnboardingInfoProps {
  onConfirmClick: () => void;
  isLoading: boolean;
}

export const PickRelativesWithCancerHistoryOnboardingInfo = (
  props: PickRelativesWithCancerHistoryOnboardingInfoProps,
) => {
  const { t } = useTranslation(['patientPortal']);

  return (
    <>
      <Stack gap={3} p={2}>
        <Typography variant="h3">
          {t('pedigree.onboarding-drawer.cancer-history.title')}
        </Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="pedigree.onboarding-drawer.cancer-history.description.paragraph1"
          />
        </Typography>
        <Typography>
          {t(
            'pedigree.onboarding-drawer.cancer-history.description.paragraph2',
          )}
        </Typography>
        <LoadingButton
          variant="contained"
          onClick={props.onConfirmClick}
          loading={props.isLoading}
        >
          {t('pedigree.onboarding-drawer.cancer-history.textButton')}
        </LoadingButton>
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
        }}
      >
        <InstructionBox />
      </Stack>
    </>
  );
};
