import { usePedigreePrintExport } from 'common/features/pedigreeCase/pedigreePrintExport/hooks/usePedigreePrintExport';
import { useTranslation } from 'react-i18next';
import { SectionWithActionButton } from './common/SectionWithActionButton';

export const CanRiskFileExporter = ({
  pedigreeCaseId,
  disabled,
}: {
  pedigreeCaseId?: string;
  disabled: boolean;
}) => {
  const { t } = useTranslation(['iPedigree']);

  const { onPedigreeDownloadClick, dialog } = usePedigreePrintExport();

  const handleFileExport = () => {
    if (!pedigreeCaseId) return;
    onPedigreeDownloadClick(pedigreeCaseId);
  };

  return (
    <>
      <SectionWithActionButton
        title={t('risk-assessment.can-risk-file-exporter.title')}
        buttonText={t('risk-assessment.can-risk-file-exporter.buttonText')}
        disabled={disabled}
        onButtonClick={handleFileExport}
      />
      {dialog}
    </>
  );
};
