import { Box, Drawer } from '@mui/material';
import { usePatientService } from 'api/patient/patient-service';
import { PedigreeNode } from 'common/features/pedigree/common/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { PedigreeCreatedOnboardingInfo } from './PedigreeCreatedOnboardingInfo';
import { PedigreeEditingInfo } from './PedigreeEditingInfo';
import { PickRelativesWithCancerHistoryOnboardingInfo } from './PickRelativesWithCancerHistoryOnboardingInfo';

interface PedigreeOnboardingProps {
  pedigreeId: string;
  nodes: PedigreeNode[];
  selectedNodes: string[];
  drawerWidth: number;
  setSelectedNodes: (selectedNodes: string[]) => void;
  setAllowNodeClick: (allowNodeClick: boolean) => void;
  setDrawerState: (drawerState: 'onboarding' | 'nodeForm' | null) => void;
  setShowNodeAction: (showNodeAction: boolean) => void;
}

export const PedigreeOnboarding = (props: PedigreeOnboardingProps) => {
  const {
    pedigreeId,
    nodes,
    selectedNodes,
    drawerWidth,
    setSelectedNodes,
    setAllowNodeClick,
    setDrawerState,
    setShowNodeAction,
  } = props;

  const service = usePatientService();
  const queryClient = useQueryClient();

  const [openOnboarding, setOnboarding] = useState<
    'cancerHistory' | 'created' | 'infoEditing' | null
  >(null);

  const cancerDiagnosisIsMissing = useMemo(() => {
    const isValidDiagnosis = ({ diseaseName }: { diseaseName: string }) =>
      diseaseName &&
      !['null', 'Unspecified', 'Not sure', 'undefined'].includes(diseaseName);

    const nodesWithMissingDiagnosis = nodes.filter(
      ({ hasDiagnoseHistory, diagnoses }) => {
        const validDiagnoses = diagnoses?.filter(isValidDiagnosis);
        return (
          hasDiagnoseHistory && (!validDiagnoses || validDiagnoses.length === 0)
        );
      },
    );

    return nodesWithMissingDiagnosis.length > 0;
  }, [nodes]);

  const openNextOnboarding = useCallback(() => {
    const name = cancerDiagnosisIsMissing ? 'infoEditing' : 'created';
    setOnboarding(name);
  }, [cancerDiagnosisIsMissing]);

  const submitNodesWithCancerHistoryMutation = useMutation(
    () => service?.sendRelativesWithCancerHistory(pedigreeId, selectedNodes),
    {
      onSuccess: () => {
        openNextOnboarding();
        setSelectedNodes([]);
        queryClient.invalidateQueries('pedigree');
      },
    },
  );

  const onConfirmCancerHistory = useCallback(() => {
    const hasSelectedNodes = !!selectedNodes.length;
    if (hasSelectedNodes) {
      return submitNodesWithCancerHistoryMutation.mutate();
    }
    return openNextOnboarding();
  }, [
    openNextOnboarding,
    selectedNodes.length,
    submitNodesWithCancerHistoryMutation,
  ]);

  const handleCloseOnboarding = useCallback(() => {
    localStorage.removeItem('relativeDisease');
    setOnboarding(null);
    setAllowNodeClick(true);
    setShowNodeAction(true);
    setDrawerState('nodeForm');
  }, [setAllowNodeClick, setDrawerState, setShowNodeAction]);

  useEffect(() => {
    const allowNodeClick = openOnboarding === 'cancerHistory';
    const showNodeAction = openOnboarding !== 'cancerHistory';
    setShowNodeAction(showNodeAction);
    setAllowNodeClick(allowNodeClick);
  }, [openOnboarding, setAllowNodeClick, setShowNodeAction]);

  useEffect(() => {
    const relativeDisease = localStorage.getItem('relativeDisease');
    const relativeDiseaseValue =
      relativeDisease === 'yes' ? 'cancerHistory' : 'created';

    const initialOnboarding = relativeDisease
      ? relativeDiseaseValue
      : 'infoEditing';
    setOnboarding(initialOnboarding);
  }, []);

  return (
    <Drawer
      open={!!openOnboarding}
      onClose={handleCloseOnboarding}
      anchor="right"
      hideBackdrop={true}
      sx={{ width: 0 }}
    >
      <Box sx={{ width: drawerWidth }}>
        {openOnboarding === 'cancerHistory' && (
          <PickRelativesWithCancerHistoryOnboardingInfo
            isLoading={submitNodesWithCancerHistoryMutation.isLoading}
            onConfirmClick={onConfirmCancerHistory}
          />
        )}
        {openOnboarding === 'created' && (
          <PedigreeCreatedOnboardingInfo
            onConfirmClick={handleCloseOnboarding}
          />
        )}
        {openOnboarding === 'infoEditing' && (
          <PedigreeEditingInfo onConfirmClick={handleCloseOnboarding} />
        )}
      </Box>
    </Drawer>
  );
};
