import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Stack, Typography } from '@mui/material';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Section } from '../common/Section';

export const PersonalInformationSection = ({
  medicalFormEditedByPatient,
}: {
  medicalFormEditedByPatient: boolean;
}) => {
  const { t } = useTranslation('patientPortal');
  const navigate = useNavigate();

  const onClickButton = () => {
    return navigate(PATIENT_PATHS.QUESTIONNAIRE);
  };

  const title = t('home-personal-information-section.title');
  const chipLabel = medicalFormEditedByPatient
    ? t('home-section.not-submitted')
    : null;
  const buttonText = medicalFormEditedByPatient
    ? t('home-section.edit')
    : t('home-personal-information-section.button-text');

  return (
    <Stack gap={2}>
      <Typography variant="h3">{t('home-section.optional')}</Typography>
      <Section
        title={title}
        titleIcon={faUser}
        chipLabel={chipLabel}
        buttonText={buttonText}
        buttonVariant="outlined"
        onClickButton={onClickButton}
      >
        <Typography variant="body1">
          {t('home-personal-information-section.content')}
        </Typography>
      </Section>
    </Stack>
  );
};
