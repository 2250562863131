import { UiSchema } from '@rjsf/utils';
import { RJSFSchema } from '@rjsf/utils/lib/types';

export const getStaffSchema = (): {
  patientSchema: {
    jsonSchema: RJSFSchema | { [key: string]: any };
    uiSchema: UiSchema;
    formData?: any;
  };
  relativeSchema: {
    jsonSchema: RJSFSchema | { [key: string]: any };
    uiSchema: UiSchema;
    formData?: any;
  };
} => {
  return {
    patientSchema: {
      formData: {
        personalData: {
          age: '1989',
        },
      },
      jsonSchema: {
        type: 'object',
        properties: {
          formInformation: {
            type: 'object',
            properties: {
              medicalFormPurpose: {
                type: 'string',
                readOnly: true,
              },
            },
          },
          personalData: {
            type: 'object',
            properties: {
              personalNumber: {
                type: 'string',
                readOnly: true,
              },
              dateOfBirth: {
                type: 'string',
                readOnly: true,
              },
              age: {
                type: 'string',
                readOnly: true,
              },
              ashkenaziJewishAncestors: {
                $ref: '#/definitions/yesNoNotSureQuestion',
              },
              patientEthnicity: {
                type: 'string',
              },
              countryOfBirth: {
                type: 'string',
              },
              countryOfResidence: {
                type: 'string',
              },
              motherEthnicity: {
                type: 'string',
              },
              fatherEthnicity: {
                type: 'string',
              },
            },
          },
          medicalHistory: {
            type: 'object',
            properties: {
              isDiagnosed: {
                $ref: '#/definitions/yesNoQuestion',
              },
              endometriosis: {
                $ref: '#/definitions/yesNoQuestion',
              },
              tubalLigation: {
                $ref: '#/definitions/yesNoQuestion',
              },
              oophorectomy: {
                $ref: '#/definitions/oophorectomy',
              },
              hysterectomy: {
                $ref: '#/definitions/yesNoQuestion',
              },
              gynecologicalOperation: {
                $ref: '#/definitions/gynecologicalOperation',
              },
              mastectomy: {
                $ref: '#/definitions/mastectomy',
              },
              breastOperation: {
                $ref: '#/definitions/breastOperation',
              },
            },
            dependencies: {
              isDiagnosed: {
                oneOf: [
                  {
                    properties: {
                      isDiagnosed: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      isDiagnosed: {
                        const: 'yes',
                      },
                      diagnoses: {
                        type: 'array',
                        minItems: 1,
                        items: {
                          $ref: '#/definitions/diagnosisItemForStaff',
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTesting: {
            $ref: '#/definitions/geneticTestingStaffConsumer',
          },
          womenHealth: {
            type: 'object',
            properties: {
              menstruation: {
                $ref: '#/definitions/menstruation',
              },
              hormonalContraception: {
                $ref: '#/definitions/hormonalContraception',
              },
              mammography: {
                $ref: '#/definitions/mammography',
              },
            },
          },
          lifeStyle: {
            type: 'object',
            properties: {
              height: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              weight: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              bmi: {
                type: 'number',
              },
              smoking: {
                $ref: '#/definitions/yesNoQuestion',
              },
              alcohol: {
                $ref: '#/definitions/alcoholConsumption',
              },
            },
          },
        },
        definitions: {
          yesNoQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
            ],
          },
          yesNoNotSureQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
              {
                const: 'notSure',
                title: 'Not sure',
              },
            ],
          },
          leftRightBothQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'left',
                title: 'Left',
              },
              {
                const: 'right',
                title: 'Right',
              },
              {
                const: 'both',
                title: 'Both',
              },
            ],
          },
          oophorectomy: {
            type: 'object',
            properties: {
              hasHadAnOophorectomy: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAnOophorectomy: {
                oneOf: [
                  {
                    properties: {
                      hasHadAnOophorectomy: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAnOophorectomy: {
                        enum: ['yes'],
                      },
                      removedOvaries: {
                        $ref: '#/definitions/leftRightBothQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          gynecologicalOperation: {
            type: 'object',
            properties: {
              hasHadAGynecologicalOperation: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAGynecologicalOperation: {
                oneOf: [
                  {
                    properties: {
                      hasHadAGynecologicalOperation: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAGynecologicalOperation: {
                        enum: ['yes'],
                      },
                      gynecologicalOperationReason: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          mastectomy: {
            type: 'object',
            properties: {
              hasHadAMastectomy: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAMastectomy: {
                oneOf: [
                  {
                    properties: {
                      hasHadAMastectomy: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAMastectomy: {
                        enum: ['yes'],
                      },
                      removedBreasts: {
                        $ref: '#/definitions/leftRightBothQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          breastOperation: {
            type: 'object',
            properties: {
              hasHadABreastOperation: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadABreastOperation: {
                oneOf: [
                  {
                    properties: {
                      hasHadABreastOperation: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadABreastOperation: {
                        enum: ['yes'],
                      },
                      breastOperationReason: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          mammography: {
            type: 'object',
            properties: {
              hasHadAMammogram: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAMammogram: {
                oneOf: [
                  {
                    properties: {
                      hasHadAMammogram: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAMammogram: {
                        enum: ['yes'],
                      },
                      lastMammogramDate: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          positiveIntegerQuestion: {
            type: 'integer',
          },
          yearOfBirth: {
            type: 'integer',
          },
          menstruation: {
            type: 'object',
            properties: {
              ageStart: {
                type: 'integer',
                maximumAge: {
                  $data: [
                    '/personalData/age',
                    '/personalInfo/ageAtDeath',
                    '/personalInfo/yearOfBirth',
                  ],
                },
              },
              hasStopped: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasStopped: {
                oneOf: [
                  {
                    properties: {
                      hasStopped: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasStopped: {
                        enum: ['yes'],
                      },
                      ageStopped: {
                        type: 'string',
                        maximumAgeRange: {
                          $data: [
                            '/personalData/age',
                            '/personalInfo/ageAtDeath',
                            '/personalInfo/yearOfBirth',
                          ],
                        },
                        oneOf: [
                          {
                            const: 'before40',
                            title: 'Before 40',
                          },
                          {
                            const: '40-44',
                            title: '40 - 44',
                          },
                          {
                            const: '45-49',
                            title: '45 - 49',
                          },
                          {
                            const: '50-54',
                            title: '50 - 54',
                          },
                          {
                            const: 'after54',
                            title: 'After 54',
                          },
                        ],
                      },
                      hormonalReplacement: {
                        $ref: '#/definitions/hormonalReplacement',
                      },
                    },
                  },
                ],
              },
            },
          },
          hormonalReplacement: {
            type: 'object',
            properties: {
              hasTaken: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasTaken: {
                oneOf: [
                  {
                    properties: {
                      hasTaken: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasTaken: {
                        enum: ['yes'],
                      },
                      duration: {
                        $ref: '#/definitions/positiveIntegerQuestion',
                      },
                      usedInLast5Years: {
                        $ref: '#/definitions/yesNoQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          hormonalContraception: {
            type: 'object',
            properties: {
              hasTaken: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasTaken: {
                oneOf: [
                  {
                    properties: {
                      hasTaken: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasTaken: {
                        enum: ['yes'],
                      },
                      duration: {
                        type: 'string',
                        oneOf: [
                          {
                            const: '<1',
                            title: 'Less than 1',
                          },
                          {
                            const: '1-4',
                            title: '1 - 4',
                          },
                          {
                            const: '5-9',
                            title: '5 - 9',
                          },
                          {
                            const: '10-14',
                            title: '10 - 14',
                          },
                          {
                            const: '>14',
                            title: 'More than 14',
                          },
                        ],
                      },
                      usedInLast2Years: {
                        $ref: '#/definitions/yesNoQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          alcoholConsumption: {
            type: 'object',
            properties: {
              alcopop: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              beer: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              spirit: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              wine: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
            },
          },
          genes: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'string',
            },
          },
          geneticTestingPatientConsumer: {
            type: 'object',
            properties: {
              doneGeneticTesting: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              doneGeneticTesting: {
                oneOf: [
                  {
                    properties: {
                      doneGeneticTesting: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      doneGeneticTesting: {
                        enum: ['yes'],
                      },
                      testLocation: {
                        type: 'string',
                      },
                      hasPathogenicGenes: {
                        $ref: '#/definitions/yesNoNotSureQuestion',
                      },
                    },
                  },
                ],
              },
              hasPathogenicGenes: {
                oneOf: [
                  {
                    properties: {
                      hasPathogenicGenes: {
                        enum: ['no', 'notSure', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasPathogenicGenes: {
                        enum: ['yes'],
                      },
                      pathogenicGenes: {
                        $ref: '#/definitions/genes',
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTestingStaffConsumer: {
            type: 'object',
            properties: {
              doneGeneticTesting: {
                $ref: '#/definitions/yesNoQuestion',
              },
              geneticTests: {
                type: 'array',
                minItems: 0,
                items: {
                  $ref: '#/definitions/geneticTestingItem',
                },
              },
            },
          },
          geneticTestingItem: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              status: {
                type: 'string',
                readOnly: true,
              },
              pathogenicVariantsFound: {
                $ref: '#/definitions/yesNoNotSureQuestion',
              },
              testDate: {
                type: 'string',
              },
              testType: {
                type: 'string',
                oneOf: [
                  {
                    const: 'screening',
                    title: 'Gene panel screen',
                  },
                  {
                    const: 'targeted',
                    title: 'Targeted carrier test',
                  },
                ],
              },
              testLocation: {
                type: 'string',
              },
              testPanelGenes: {
                $ref: '#/definitions/genes',
              },
            },
            dependencies: {
              pathogenicVariantsFound: {
                oneOf: [
                  {
                    properties: {
                      pathogenicVariantsFound: {
                        enum: ['no', 'notSure', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      pathogenicVariantsFound: {
                        enum: ['yes'],
                      },
                      geneticTestVariants: {
                        type: 'array',
                        minItems: 1,
                        items: {
                          $ref: '#/definitions/geneticTestVariantsItem',
                        },
                      },
                    },
                  },
                ],
              },
              testType: {
                oneOf: [
                  {
                    properties: {
                      testType: {
                        enum: ['targeted', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      testType: {
                        enum: ['screening'],
                      },
                      testPanel: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTestVariantsItem: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              name: {
                type: 'string',
              },
            },
            dependencies: {
              name: {
                oneOf: [
                  {
                    properties: {
                      name: {
                        type: 'string',
                      },
                      variants: {
                        type: 'object',
                        properties: {
                          rsid: {
                            type: 'string',
                          },
                          identifier: {
                            type: 'string',
                          },
                          location: {
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                ],
              },
            },
            required: ['name'],
          },
          personalInfo: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
              },
              isAlive: {
                type: 'string',
                oneOf: [
                  {
                    const: 'yes',
                    title: 'Yes, living',
                  },
                  {
                    const: 'no',
                    title: 'No, deceased',
                  },
                ],
              },
            },
            dependencies: {
              isAlive: {
                oneOf: [
                  {
                    properties: {
                      isAlive: {
                        enum: ['', null],
                      },
                    },
                  },
                  {
                    properties: {
                      isAlive: {
                        enum: ['yes'],
                      },
                      yearOfBirth: {
                        $ref: '#/definitions/yearOfBirth',
                      },
                      yearOfBirthApproximate: {
                        type: 'boolean',
                      },
                    },
                  },
                  {
                    properties: {
                      isAlive: {
                        enum: ['no'],
                      },
                      yearOfBirth: {
                        $ref: '#/definitions/yearOfBirth',
                      },
                      yearOfBirthApproximate: {
                        type: 'boolean',
                      },
                      ageAtDeath: {
                        type: 'integer',
                        maximum: 125,
                        maximumAge: {
                          $data: ['/personalInfo/yearOfBirth'],
                        },
                      },
                      ageAtDeathApproximate: {
                        type: 'boolean',
                      },
                    },
                  },
                ],
              },
            },
          },
          diagnosisItemForStaff: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              confirmed: {
                type: 'boolean',
              },
              status: {
                type: 'object',
                properties: {
                  state: {
                    type: 'string',
                    readOnly: true,
                  },
                  performedBy: {
                    type: 'string',
                    readOnly: true,
                  },
                  performedAt: {
                    type: 'string',
                    readOnly: true,
                  },
                  name: {
                    type: 'string',
                    readOnly: true,
                  },
                },
              },
              disease: {
                type: 'string',
              },
              ageAtDiagnosis: {
                type: 'integer',
                maximumAge: {
                  $data: [
                    '/personalData/age',
                    '/personalInfo/ageAtDeath',
                    '/personalInfo/yearOfBirth',
                  ],
                },
              },
              ageAtDiagnosisIsApproximate: {
                type: 'boolean',
              },
              treatedBy: {
                type: 'string',
              },
              notes: {
                type: 'string',
              },
            },
            required: ['disease'],
          },
          diagnosisItemForPatient: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              readonly: {
                type: 'boolean',
                readOnly: true,
              },
              disease: {
                type: 'string',
              },
              ageAtDiagnosis: {
                type: 'integer',
                maximumAge: {
                  $data: [
                    '/personalData/age',
                    '/personalInfo/ageAtDeath',
                    '/personalInfo/yearOfBirth',
                  ],
                },
              },
              ageAtDiagnosisIsApproximate: {
                type: 'boolean',
              },
              treatedBy: {
                type: 'string',
              },
              notes: {
                type: 'string',
              },
            },
          },
        },
        additionalProperties: false,
      },
      uiSchema: {
        formInformation: {
          'ui:type': 'plainBox',
          medicalFormPurpose: {
            'ui:title': 'Currently showing information relevant to:',
          },
        },
        personalData: {
          'ui:title': 'Personal information',
          'ui:type': 'box',
          personalNumber: {
            'ui:title': 'Personal number',
            'ui:gridColumnWidth': 'auto',
            'ui:flexGrow': 1,
          },
          dateOfBirth: {
            'ui:title': 'Date of birth',
            'ui:gridColumnWidth': 'auto',
            'ui:flexGrow': 1,
          },
          age: {
            'ui:title': 'Age',
            'ui:gridColumnWidth': 'auto',
          },
          ashkenaziJewishAncestors: {
            'ui:title': 'Does the person have any Ashkenazi Jewish ancestors?',
            'ui:widget': 'radio',
            'ui:tooltip':
              "Ashkenazi Jewish ancestry has a specific impact on the genetic risk factors. We're asking this information to ensure the accuracy of your risk assessment.",
          },
          patientEthnicity: {
            'ui:title': "What is the person's ethnicity?",
            'ui:tooltip':
              'Your biological ancestry is one of the important factors when assessing genetic risk factors for common diseases.',
            'ui:placeholder': 'Select or type here',
            'ui:widget': 'AutocompleteWidget',
            'ui:enumOptions': [
              {
                value: 'african-african-american',
                label: 'African/African American',
              },
              {
                value: 'asian',
                label: 'Asian',
              },
              {
                value: 'arab',
                label: 'Arab',
              },
              {
                value: 'european',
                label: 'European',
              },
              {
                value: 'jewish',
                label: 'Jewish',
              },
              {
                value: 'latino-hispanic',
                label: 'Latino/Hispanic',
              },
              {
                value: 'pacific',
                label: 'Pacific',
              },
            ],
            'ui:freeSolo': true,
            'ui:inputWidth': '25rem',
          },
          motherEthnicity: {
            'ui:title': "Mother's ethnicity (if known)?",
            'ui:placeholder': 'Select or type here',
            'ui:widget': 'AutocompleteWidget',
            'ui:enumOptions': [
              {
                value: 'african-african-american',
                label: 'African/African American',
              },
              {
                value: 'asian',
                label: 'Asian',
              },
              {
                value: 'arab',
                label: 'Arab',
              },
              {
                value: 'european',
                label: 'European',
              },
              {
                value: 'jewish',
                label: 'Jewish',
              },
              {
                value: 'latino-hispanic',
                label: 'Latino/Hispanic',
              },
              {
                value: 'pacific',
                label: 'Pacific',
              },
            ],
            'ui:freeSolo': true,
            'ui:inputWidth': '25rem',
          },
          fatherEthnicity: {
            'ui:title': "Father's ethnicity (if known)?",
            'ui:placeholder': 'Select or type here',
            'ui:widget': 'AutocompleteWidget',
            'ui:enumOptions': [
              {
                value: 'african-african-american',
                label: 'African/African American',
              },
              {
                value: 'asian',
                label: 'Asian',
              },
              {
                value: 'arab',
                label: 'Arab',
              },
              {
                value: 'european',
                label: 'European',
              },
              {
                value: 'jewish',
                label: 'Jewish',
              },
              {
                value: 'latino-hispanic',
                label: 'Latino/Hispanic',
              },
              {
                value: 'pacific',
                label: 'Pacific',
              },
            ],
            'ui:freeSolo': true,
            'ui:inputWidth': '25rem',
          },
          countryOfBirth: {
            'ui:title': 'Country of birth',
            'ui:placeholder': 'Select',
            'ui:widget': 'AutocompleteWidget',
            'ui:inputWidth': '25rem',
            'ui:enumOptions': [
              {
                value: 'ABW',
                label: 'Aruba',
              },
              {
                value: 'AFG',
                label: 'Afghanistan',
              },
              {
                value: 'AGO',
                label: 'Angola',
              },
              {
                value: 'AIA',
                label: 'Anguilla',
              },
              {
                value: 'ALA',
                label: 'Åland Islands',
              },
              {
                value: 'ALB',
                label: 'Albania',
              },
              {
                value: 'AND',
                label: 'Andorra',
              },
              {
                value: 'ARE',
                label: 'United Arab Emirates',
              },
              {
                value: 'ARG',
                label: 'Argentina',
              },
              {
                value: 'ARM',
                label: 'Armenia',
              },
              {
                value: 'ASM',
                label: 'American Samoa',
              },
              {
                value: 'ATA',
                label: 'Antarctica',
              },
              {
                value: 'ATF',
                label: 'French Southern Territories',
              },
              {
                value: 'ATG',
                label: 'Antigua and Barbuda',
              },
              {
                value: 'AUS',
                label: 'Australia',
              },
              {
                value: 'AUT',
                label: 'Austria',
              },
              {
                value: 'AZE',
                label: 'Azerbaijan',
              },
              {
                value: 'BDI',
                label: 'Burundi',
              },
              {
                value: 'BEL',
                label: 'Belgium',
              },
              {
                value: 'BEN',
                label: 'Benin',
              },
              {
                value: 'BES',
                label: 'Bonaire, Sint Eustatius and Saba',
              },
              {
                value: 'BFA',
                label: 'Burkina Faso',
              },
              {
                value: 'BGD',
                label: 'Bangladesh',
              },
              {
                value: 'BGR',
                label: 'Bulgaria',
              },
              {
                value: 'BHR',
                label: 'Bahrain',
              },
              {
                value: 'BHS',
                label: 'Bahamas',
              },
              {
                value: 'BIH',
                label: 'Bosnia and Herzegovina',
              },
              {
                value: 'BLM',
                label: 'Saint Barthélemy',
              },
              {
                value: 'BLR',
                label: 'Belarus',
              },
              {
                value: 'BLZ',
                label: 'Belize',
              },
              {
                value: 'BMU',
                label: 'Bermuda',
              },
              {
                value: 'BOL',
                label: 'Bolivia (Plurinational State of)',
              },
              {
                value: 'BRA',
                label: 'Brazil',
              },
              {
                value: 'BRB',
                label: 'Barbados',
              },
              {
                value: 'BRN',
                label: 'Brunei Darussalam',
              },
              {
                value: 'BTN',
                label: 'Bhutan',
              },
              {
                value: 'BVT',
                label: 'Bouvet Island',
              },
              {
                value: 'BWA',
                label: 'Botswana',
              },
              {
                value: 'CAF',
                label: 'Central African Republic',
              },
              {
                value: 'CAN',
                label: 'Canada',
              },
              {
                value: 'CCK',
                label: 'Cocos (Keeling) Islands',
              },
              {
                value: 'CHE',
                label: 'Switzerland',
              },
              {
                value: 'CHL',
                label: 'Chile',
              },
              {
                value: 'CHN',
                label: 'China',
              },
              {
                value: 'CIV',
                label: "Côte d'Ivoire",
              },
              {
                value: 'CMR',
                label: 'Cameroon',
              },
              {
                value: 'COD',
                label: 'Congo, Democratic Republic of the',
              },
              {
                value: 'COG',
                label: 'Congo',
              },
              {
                value: 'COK',
                label: 'Cook Islands',
              },
              {
                value: 'COL',
                label: 'Colombia',
              },
              {
                value: 'COM',
                label: 'Comoros',
              },
              {
                value: 'CPV',
                label: 'Cabo Verde',
              },
              {
                value: 'CRI',
                label: 'Costa Rica',
              },
              {
                value: 'CUB',
                label: 'Cuba',
              },
              {
                value: 'CUW',
                label: 'Curaçao',
              },
              {
                value: 'CXR',
                label: 'Christmas Island',
              },
              {
                value: 'CYM',
                label: 'Cayman Islands',
              },
              {
                value: 'CYP',
                label: 'Cyprus',
              },
              {
                value: 'CZE',
                label: 'Czechia',
              },
              {
                value: 'DEU',
                label: 'Germany',
              },
              {
                value: 'DJI',
                label: 'Djibouti',
              },
              {
                value: 'DMA',
                label: 'Dominica',
              },
              {
                value: 'DNK',
                label: 'Denmark',
              },
              {
                value: 'DOM',
                label: 'Dominican Republic',
              },
              {
                value: 'DZA',
                label: 'Algeria',
              },
              {
                value: 'ECU',
                label: 'Ecuador',
              },
              {
                value: 'EGY',
                label: 'Egypt',
              },
              {
                value: 'ERI',
                label: 'Eritrea',
              },
              {
                value: 'ESH',
                label: 'Western Sahara',
              },
              {
                value: 'ESP',
                label: 'Spain',
              },
              {
                value: 'EST',
                label: 'Estonia',
              },
              {
                value: 'ETH',
                label: 'Ethiopia',
              },
              {
                value: 'FIN',
                label: 'Finland',
              },
              {
                value: 'FJI',
                label: 'Fiji',
              },
              {
                value: 'FLK',
                label: 'Falkland Islands (Malvinas)',
              },
              {
                value: 'FRA',
                label: 'France',
              },
              {
                value: 'FRO',
                label: 'Faroe Islands',
              },
              {
                value: 'FSM',
                label: 'Micronesia (Federated States of)',
              },
              {
                value: 'GAB',
                label: 'Gabon',
              },
              {
                value: 'GBR',
                label: 'United Kingdom of Great Britain and Northern Ireland',
              },
              {
                value: 'GEO',
                label: 'Georgia',
              },
              {
                value: 'GGY',
                label: 'Guernsey',
              },
              {
                value: 'GHA',
                label: 'Ghana',
              },
              {
                value: 'GIB',
                label: 'Gibraltar',
              },
              {
                value: 'GIN',
                label: 'Guinea',
              },
              {
                value: 'GLP',
                label: 'Guadeloupe',
              },
              {
                value: 'GMB',
                label: 'Gambia',
              },
              {
                value: 'GNB',
                label: 'Guinea-Bissau',
              },
              {
                value: 'GNQ',
                label: 'Equatorial Guinea',
              },
              {
                value: 'GRC',
                label: 'Greece',
              },
              {
                value: 'GRD',
                label: 'Grenada',
              },
              {
                value: 'GRL',
                label: 'Greenland',
              },
              {
                value: 'GTM',
                label: 'Guatemala',
              },
              {
                value: 'GUF',
                label: 'French Guiana',
              },
              {
                value: 'GUM',
                label: 'Guam',
              },
              {
                value: 'GUY',
                label: 'Guyana',
              },
              {
                value: 'HKG',
                label: 'Hong Kong',
              },
              {
                value: 'HMD',
                label: 'Heard Island and McDonald Islands',
              },
              {
                value: 'HND',
                label: 'Honduras',
              },
              {
                value: 'HRV',
                label: 'Croatia',
              },
              {
                value: 'HTI',
                label: 'Haiti',
              },
              {
                value: 'HUN',
                label: 'Hungary',
              },
              {
                value: 'IDN',
                label: 'Indonesia',
              },
              {
                value: 'IMN',
                label: 'Isle of Man',
              },
              {
                value: 'IND',
                label: 'India',
              },
              {
                value: 'IOT',
                label: 'British Indian Ocean Territory',
              },
              {
                value: 'IRL',
                label: 'Ireland',
              },
              {
                value: 'IRN',
                label: 'Iran (Islamic Republic of)',
              },
              {
                value: 'IRQ',
                label: 'Iraq',
              },
              {
                value: 'ISL',
                label: 'Iceland',
              },
              {
                value: 'ISR',
                label: 'Israel',
              },
              {
                value: 'ITA',
                label: 'Italy',
              },
              {
                value: 'JAM',
                label: 'Jamaica',
              },
              {
                value: 'JEY',
                label: 'Jersey',
              },
              {
                value: 'JOR',
                label: 'Jordan',
              },
              {
                value: 'JPN',
                label: 'Japan',
              },
              {
                value: 'KAZ',
                label: 'Kazakhstan',
              },
              {
                value: 'KEN',
                label: 'Kenya',
              },
              {
                value: 'KGZ',
                label: 'Kyrgyzstan',
              },
              {
                value: 'KHM',
                label: 'Cambodia',
              },
              {
                value: 'KIR',
                label: 'Kiribati',
              },
              {
                value: 'KNA',
                label: 'Saint Kitts and Nevis',
              },
              {
                value: 'KOR',
                label: 'Korea, Republic of',
              },
              {
                value: 'KWT',
                label: 'Kuwait',
              },
              {
                value: 'LAO',
                label: "Lao People's Democratic Republic",
              },
              {
                value: 'LBN',
                label: 'Lebanon',
              },
              {
                value: 'LBR',
                label: 'Liberia',
              },
              {
                value: 'LBY',
                label: 'Libya',
              },
              {
                value: 'LCA',
                label: 'Saint Lucia',
              },
              {
                value: 'LIE',
                label: 'Liechtenstein',
              },
              {
                value: 'LKA',
                label: 'Sri Lanka',
              },
              {
                value: 'LSO',
                label: 'Lesotho',
              },
              {
                value: 'LTU',
                label: 'Lithuania',
              },
              {
                value: 'LUX',
                label: 'Luxembourg',
              },
              {
                value: 'LVA',
                label: 'Latvia',
              },
              {
                value: 'MAC',
                label: 'Macao',
              },
              {
                value: 'MAF',
                label: 'Saint Martin (French part)',
              },
              {
                value: 'MAR',
                label: 'Morocco',
              },
              {
                value: 'MCO',
                label: 'Monaco',
              },
              {
                value: 'MDA',
                label: 'Moldova, Republic of',
              },
              {
                value: 'MDG',
                label: 'Madagascar',
              },
              {
                value: 'MDV',
                label: 'Maldives',
              },
              {
                value: 'MEX',
                label: 'Mexico',
              },
              {
                value: 'MHL',
                label: 'Marshall Islands',
              },
              {
                value: 'MKD',
                label: 'North Macedonia',
              },
              {
                value: 'MLI',
                label: 'Mali',
              },
              {
                value: 'MLT',
                label: 'Malta',
              },
              {
                value: 'MMR',
                label: 'Myanmar',
              },
              {
                value: 'MNE',
                label: 'Montenegro',
              },
              {
                value: 'MNG',
                label: 'Mongolia',
              },
              {
                value: 'MNP',
                label: 'Northern Mariana Islands',
              },
              {
                value: 'MOZ',
                label: 'Mozambique',
              },
              {
                value: 'MRT',
                label: 'Mauritania',
              },
              {
                value: 'MSR',
                label: 'Montserrat',
              },
              {
                value: 'MTQ',
                label: 'Martinique',
              },
              {
                value: 'MUS',
                label: 'Mauritius',
              },
              {
                value: 'MWI',
                label: 'Malawi',
              },
              {
                value: 'MYS',
                label: 'Malaysia',
              },
              {
                value: 'MYT',
                label: 'Mayotte',
              },
              {
                value: 'NAM',
                label: 'Namibia',
              },
              {
                value: 'NCL',
                label: 'New Caledonia',
              },
              {
                value: 'NER',
                label: 'Niger',
              },
              {
                value: 'NFK',
                label: 'Norfolk Island',
              },
              {
                value: 'NGA',
                label: 'Nigeria',
              },
              {
                value: 'NIC',
                label: 'Nicaragua',
              },
              {
                value: 'NIU',
                label: 'Niue',
              },
              {
                value: 'NLD',
                label: 'Netherlands',
              },
              {
                value: 'NOR',
                label: 'Norway',
              },
              {
                value: 'NPL',
                label: 'Nepal',
              },
              {
                value: 'NRU',
                label: 'Nauru',
              },
              {
                value: 'NZL',
                label: 'New Zealand',
              },
              {
                value: 'OMN',
                label: 'Oman',
              },
              {
                value: 'PAK',
                label: 'Pakistan',
              },
              {
                value: 'PAN',
                label: 'Panama',
              },
              {
                value: 'PCN',
                label: 'Pitcairn',
              },
              {
                value: 'PER',
                label: 'Peru',
              },
              {
                value: 'PHL',
                label: 'Philippines',
              },
              {
                value: 'PLW',
                label: 'Palau',
              },
              {
                value: 'PNG',
                label: 'Papua New Guinea',
              },
              {
                value: 'POL',
                label: 'Poland',
              },
              {
                value: 'PRI',
                label: 'Puerto Rico',
              },
              {
                value: 'PRK',
                label: "Korea (Democratic People's Republic of)",
              },
              {
                value: 'PRT',
                label: 'Portugal',
              },
              {
                value: 'PRY',
                label: 'Paraguay',
              },
              {
                value: 'PSE',
                label: 'Palestine, State of',
              },
              {
                value: 'PYF',
                label: 'French Polynesia',
              },
              {
                value: 'QAT',
                label: 'Qatar',
              },
              {
                value: 'REU',
                label: 'Réunion',
              },
              {
                value: 'ROU',
                label: 'Romania',
              },
              {
                value: 'RUS',
                label: 'Russian Federation',
              },
              {
                value: 'RWA',
                label: 'Rwanda',
              },
              {
                value: 'SAU',
                label: 'Saudi Arabia',
              },
              {
                value: 'SDN',
                label: 'Sudan',
              },
              {
                value: 'SEN',
                label: 'Senegal',
              },
              {
                value: 'SGP',
                label: 'Singapore',
              },
              {
                value: 'SGS',
                label: 'South Georgia and the South Sandwich Islands',
              },
              {
                value: 'SHN',
                label: 'Saint Helena, Ascension and Tristan da Cunha',
              },
              {
                value: 'SJM',
                label: 'Svalbard and Jan Mayen',
              },
              {
                value: 'SLB',
                label: 'Solomon Islands',
              },
              {
                value: 'SLE',
                label: 'Sierra Leone',
              },
              {
                value: 'SLV',
                label: 'El Salvador',
              },
              {
                value: 'SMR',
                label: 'San Marino',
              },
              {
                value: 'SOM',
                label: 'Somalia',
              },
              {
                value: 'SPM',
                label: 'Saint Pierre and Miquelon',
              },
              {
                value: 'SRB',
                label: 'Serbia',
              },
              {
                value: 'SSD',
                label: 'South Sudan',
              },
              {
                value: 'STP',
                label: 'Sao Tome and Principe',
              },
              {
                value: 'SUR',
                label: 'Suriname',
              },
              {
                value: 'SVK',
                label: 'Slovakia',
              },
              {
                value: 'SVN',
                label: 'Slovenia',
              },
              {
                value: 'SWE',
                label: 'Sweden',
              },
              {
                value: 'SWZ',
                label: 'Eswatini',
              },
              {
                value: 'SXM',
                label: 'Sint Maarten (Dutch part)',
              },
              {
                value: 'SYC',
                label: 'Seychelles',
              },
              {
                value: 'SYR',
                label: 'Syrian Arab Republic',
              },
              {
                value: 'TCA',
                label: 'Turks and Caicos Islands',
              },
              {
                value: 'TCD',
                label: 'Chad',
              },
              {
                value: 'TGO',
                label: 'Togo',
              },
              {
                value: 'THA',
                label: 'Thailand',
              },
              {
                value: 'TJK',
                label: 'Tajikistan',
              },
              {
                value: 'TKL',
                label: 'Tokelau',
              },
              {
                value: 'TKM',
                label: 'Turkmenistan',
              },
              {
                value: 'TLS',
                label: 'Timor-Leste',
              },
              {
                value: 'TON',
                label: 'Tonga',
              },
              {
                value: 'TTO',
                label: 'Trinidad and Tobago',
              },
              {
                value: 'TUN',
                label: 'Tunisia',
              },
              {
                value: 'TUR',
                label: 'Turkey',
              },
              {
                value: 'TUV',
                label: 'Tuvalu',
              },
              {
                value: 'TWN',
                label: 'Taiwan, Province of China',
              },
              {
                value: 'TZA',
                label: 'Tanzania, United Republic of',
              },
              {
                value: 'UGA',
                label: 'Uganda',
              },
              {
                value: 'UKR',
                label: 'Ukraine',
              },
              {
                value: 'UMI',
                label: 'United States Minor Outlying Islands',
              },
              {
                value: 'URY',
                label: 'Uruguay',
              },
              {
                value: 'USA',
                label: 'United States of America',
              },
              {
                value: 'UZB',
                label: 'Uzbekistan',
              },
              {
                value: 'VAT',
                label: 'Holy See',
              },
              {
                value: 'VCT',
                label: 'Saint Vincent and the Grenadines',
              },
              {
                value: 'VEN',
                label: 'Venezuela (Bolivarian Republic of)',
              },
              {
                value: 'VGB',
                label: 'Virgin Islands (British)',
              },
              {
                value: 'VIR',
                label: 'Virgin Islands (U.S.)',
              },
              {
                value: 'VNM',
                label: 'Viet Nam',
              },
              {
                value: 'VUT',
                label: 'Vanuatu',
              },
              {
                value: 'WLF',
                label: 'Wallis and Futuna',
              },
              {
                value: 'WSM',
                label: 'Samoa',
              },
              {
                value: 'YEM',
                label: 'Yemen',
              },
              {
                value: 'ZAF',
                label: 'South Africa',
              },
              {
                value: 'ZMB',
                label: 'Zambia',
              },
              {
                value: 'ZWE',
                label: 'Zimbabwe',
              },
            ],
          },
          countryOfResidence: {
            'ui:title': 'Country of residence',
            'ui:placeholder': 'Select',
            'ui:widget': 'AutocompleteWidget',
            'ui:inputWidth': '25rem',
            'ui:enumOptions': [
              {
                value: 'ABW',
                label: 'Aruba',
              },
              {
                value: 'AFG',
                label: 'Afghanistan',
              },
              {
                value: 'AGO',
                label: 'Angola',
              },
              {
                value: 'AIA',
                label: 'Anguilla',
              },
              {
                value: 'ALA',
                label: 'Åland Islands',
              },
              {
                value: 'ALB',
                label: 'Albania',
              },
              {
                value: 'AND',
                label: 'Andorra',
              },
              {
                value: 'ARE',
                label: 'United Arab Emirates',
              },
              {
                value: 'ARG',
                label: 'Argentina',
              },
              {
                value: 'ARM',
                label: 'Armenia',
              },
              {
                value: 'ASM',
                label: 'American Samoa',
              },
              {
                value: 'ATA',
                label: 'Antarctica',
              },
              {
                value: 'ATF',
                label: 'French Southern Territories',
              },
              {
                value: 'ATG',
                label: 'Antigua and Barbuda',
              },
              {
                value: 'AUS',
                label: 'Australia',
              },
              {
                value: 'AUT',
                label: 'Austria',
              },
              {
                value: 'AZE',
                label: 'Azerbaijan',
              },
              {
                value: 'BDI',
                label: 'Burundi',
              },
              {
                value: 'BEL',
                label: 'Belgium',
              },
              {
                value: 'BEN',
                label: 'Benin',
              },
              {
                value: 'BES',
                label: 'Bonaire, Sint Eustatius and Saba',
              },
              {
                value: 'BFA',
                label: 'Burkina Faso',
              },
              {
                value: 'BGD',
                label: 'Bangladesh',
              },
              {
                value: 'BGR',
                label: 'Bulgaria',
              },
              {
                value: 'BHR',
                label: 'Bahrain',
              },
              {
                value: 'BHS',
                label: 'Bahamas',
              },
              {
                value: 'BIH',
                label: 'Bosnia and Herzegovina',
              },
              {
                value: 'BLM',
                label: 'Saint Barthélemy',
              },
              {
                value: 'BLR',
                label: 'Belarus',
              },
              {
                value: 'BLZ',
                label: 'Belize',
              },
              {
                value: 'BMU',
                label: 'Bermuda',
              },
              {
                value: 'BOL',
                label: 'Bolivia (Plurinational State of)',
              },
              {
                value: 'BRA',
                label: 'Brazil',
              },
              {
                value: 'BRB',
                label: 'Barbados',
              },
              {
                value: 'BRN',
                label: 'Brunei Darussalam',
              },
              {
                value: 'BTN',
                label: 'Bhutan',
              },
              {
                value: 'BVT',
                label: 'Bouvet Island',
              },
              {
                value: 'BWA',
                label: 'Botswana',
              },
              {
                value: 'CAF',
                label: 'Central African Republic',
              },
              {
                value: 'CAN',
                label: 'Canada',
              },
              {
                value: 'CCK',
                label: 'Cocos (Keeling) Islands',
              },
              {
                value: 'CHE',
                label: 'Switzerland',
              },
              {
                value: 'CHL',
                label: 'Chile',
              },
              {
                value: 'CHN',
                label: 'China',
              },
              {
                value: 'CIV',
                label: "Côte d'Ivoire",
              },
              {
                value: 'CMR',
                label: 'Cameroon',
              },
              {
                value: 'COD',
                label: 'Congo, Democratic Republic of the',
              },
              {
                value: 'COG',
                label: 'Congo',
              },
              {
                value: 'COK',
                label: 'Cook Islands',
              },
              {
                value: 'COL',
                label: 'Colombia',
              },
              {
                value: 'COM',
                label: 'Comoros',
              },
              {
                value: 'CPV',
                label: 'Cabo Verde',
              },
              {
                value: 'CRI',
                label: 'Costa Rica',
              },
              {
                value: 'CUB',
                label: 'Cuba',
              },
              {
                value: 'CUW',
                label: 'Curaçao',
              },
              {
                value: 'CXR',
                label: 'Christmas Island',
              },
              {
                value: 'CYM',
                label: 'Cayman Islands',
              },
              {
                value: 'CYP',
                label: 'Cyprus',
              },
              {
                value: 'CZE',
                label: 'Czechia',
              },
              {
                value: 'DEU',
                label: 'Germany',
              },
              {
                value: 'DJI',
                label: 'Djibouti',
              },
              {
                value: 'DMA',
                label: 'Dominica',
              },
              {
                value: 'DNK',
                label: 'Denmark',
              },
              {
                value: 'DOM',
                label: 'Dominican Republic',
              },
              {
                value: 'DZA',
                label: 'Algeria',
              },
              {
                value: 'ECU',
                label: 'Ecuador',
              },
              {
                value: 'EGY',
                label: 'Egypt',
              },
              {
                value: 'ERI',
                label: 'Eritrea',
              },
              {
                value: 'ESH',
                label: 'Western Sahara',
              },
              {
                value: 'ESP',
                label: 'Spain',
              },
              {
                value: 'EST',
                label: 'Estonia',
              },
              {
                value: 'ETH',
                label: 'Ethiopia',
              },
              {
                value: 'FIN',
                label: 'Finland',
              },
              {
                value: 'FJI',
                label: 'Fiji',
              },
              {
                value: 'FLK',
                label: 'Falkland Islands (Malvinas)',
              },
              {
                value: 'FRA',
                label: 'France',
              },
              {
                value: 'FRO',
                label: 'Faroe Islands',
              },
              {
                value: 'FSM',
                label: 'Micronesia (Federated States of)',
              },
              {
                value: 'GAB',
                label: 'Gabon',
              },
              {
                value: 'GBR',
                label: 'United Kingdom of Great Britain and Northern Ireland',
              },
              {
                value: 'GEO',
                label: 'Georgia',
              },
              {
                value: 'GGY',
                label: 'Guernsey',
              },
              {
                value: 'GHA',
                label: 'Ghana',
              },
              {
                value: 'GIB',
                label: 'Gibraltar',
              },
              {
                value: 'GIN',
                label: 'Guinea',
              },
              {
                value: 'GLP',
                label: 'Guadeloupe',
              },
              {
                value: 'GMB',
                label: 'Gambia',
              },
              {
                value: 'GNB',
                label: 'Guinea-Bissau',
              },
              {
                value: 'GNQ',
                label: 'Equatorial Guinea',
              },
              {
                value: 'GRC',
                label: 'Greece',
              },
              {
                value: 'GRD',
                label: 'Grenada',
              },
              {
                value: 'GRL',
                label: 'Greenland',
              },
              {
                value: 'GTM',
                label: 'Guatemala',
              },
              {
                value: 'GUF',
                label: 'French Guiana',
              },
              {
                value: 'GUM',
                label: 'Guam',
              },
              {
                value: 'GUY',
                label: 'Guyana',
              },
              {
                value: 'HKG',
                label: 'Hong Kong',
              },
              {
                value: 'HMD',
                label: 'Heard Island and McDonald Islands',
              },
              {
                value: 'HND',
                label: 'Honduras',
              },
              {
                value: 'HRV',
                label: 'Croatia',
              },
              {
                value: 'HTI',
                label: 'Haiti',
              },
              {
                value: 'HUN',
                label: 'Hungary',
              },
              {
                value: 'IDN',
                label: 'Indonesia',
              },
              {
                value: 'IMN',
                label: 'Isle of Man',
              },
              {
                value: 'IND',
                label: 'India',
              },
              {
                value: 'IOT',
                label: 'British Indian Ocean Territory',
              },
              {
                value: 'IRL',
                label: 'Ireland',
              },
              {
                value: 'IRN',
                label: 'Iran (Islamic Republic of)',
              },
              {
                value: 'IRQ',
                label: 'Iraq',
              },
              {
                value: 'ISL',
                label: 'Iceland',
              },
              {
                value: 'ISR',
                label: 'Israel',
              },
              {
                value: 'ITA',
                label: 'Italy',
              },
              {
                value: 'JAM',
                label: 'Jamaica',
              },
              {
                value: 'JEY',
                label: 'Jersey',
              },
              {
                value: 'JOR',
                label: 'Jordan',
              },
              {
                value: 'JPN',
                label: 'Japan',
              },
              {
                value: 'KAZ',
                label: 'Kazakhstan',
              },
              {
                value: 'KEN',
                label: 'Kenya',
              },
              {
                value: 'KGZ',
                label: 'Kyrgyzstan',
              },
              {
                value: 'KHM',
                label: 'Cambodia',
              },
              {
                value: 'KIR',
                label: 'Kiribati',
              },
              {
                value: 'KNA',
                label: 'Saint Kitts and Nevis',
              },
              {
                value: 'KOR',
                label: 'Korea, Republic of',
              },
              {
                value: 'KWT',
                label: 'Kuwait',
              },
              {
                value: 'LAO',
                label: "Lao People's Democratic Republic",
              },
              {
                value: 'LBN',
                label: 'Lebanon',
              },
              {
                value: 'LBR',
                label: 'Liberia',
              },
              {
                value: 'LBY',
                label: 'Libya',
              },
              {
                value: 'LCA',
                label: 'Saint Lucia',
              },
              {
                value: 'LIE',
                label: 'Liechtenstein',
              },
              {
                value: 'LKA',
                label: 'Sri Lanka',
              },
              {
                value: 'LSO',
                label: 'Lesotho',
              },
              {
                value: 'LTU',
                label: 'Lithuania',
              },
              {
                value: 'LUX',
                label: 'Luxembourg',
              },
              {
                value: 'LVA',
                label: 'Latvia',
              },
              {
                value: 'MAC',
                label: 'Macao',
              },
              {
                value: 'MAF',
                label: 'Saint Martin (French part)',
              },
              {
                value: 'MAR',
                label: 'Morocco',
              },
              {
                value: 'MCO',
                label: 'Monaco',
              },
              {
                value: 'MDA',
                label: 'Moldova, Republic of',
              },
              {
                value: 'MDG',
                label: 'Madagascar',
              },
              {
                value: 'MDV',
                label: 'Maldives',
              },
              {
                value: 'MEX',
                label: 'Mexico',
              },
              {
                value: 'MHL',
                label: 'Marshall Islands',
              },
              {
                value: 'MKD',
                label: 'North Macedonia',
              },
              {
                value: 'MLI',
                label: 'Mali',
              },
              {
                value: 'MLT',
                label: 'Malta',
              },
              {
                value: 'MMR',
                label: 'Myanmar',
              },
              {
                value: 'MNE',
                label: 'Montenegro',
              },
              {
                value: 'MNG',
                label: 'Mongolia',
              },
              {
                value: 'MNP',
                label: 'Northern Mariana Islands',
              },
              {
                value: 'MOZ',
                label: 'Mozambique',
              },
              {
                value: 'MRT',
                label: 'Mauritania',
              },
              {
                value: 'MSR',
                label: 'Montserrat',
              },
              {
                value: 'MTQ',
                label: 'Martinique',
              },
              {
                value: 'MUS',
                label: 'Mauritius',
              },
              {
                value: 'MWI',
                label: 'Malawi',
              },
              {
                value: 'MYS',
                label: 'Malaysia',
              },
              {
                value: 'MYT',
                label: 'Mayotte',
              },
              {
                value: 'NAM',
                label: 'Namibia',
              },
              {
                value: 'NCL',
                label: 'New Caledonia',
              },
              {
                value: 'NER',
                label: 'Niger',
              },
              {
                value: 'NFK',
                label: 'Norfolk Island',
              },
              {
                value: 'NGA',
                label: 'Nigeria',
              },
              {
                value: 'NIC',
                label: 'Nicaragua',
              },
              {
                value: 'NIU',
                label: 'Niue',
              },
              {
                value: 'NLD',
                label: 'Netherlands',
              },
              {
                value: 'NOR',
                label: 'Norway',
              },
              {
                value: 'NPL',
                label: 'Nepal',
              },
              {
                value: 'NRU',
                label: 'Nauru',
              },
              {
                value: 'NZL',
                label: 'New Zealand',
              },
              {
                value: 'OMN',
                label: 'Oman',
              },
              {
                value: 'PAK',
                label: 'Pakistan',
              },
              {
                value: 'PAN',
                label: 'Panama',
              },
              {
                value: 'PCN',
                label: 'Pitcairn',
              },
              {
                value: 'PER',
                label: 'Peru',
              },
              {
                value: 'PHL',
                label: 'Philippines',
              },
              {
                value: 'PLW',
                label: 'Palau',
              },
              {
                value: 'PNG',
                label: 'Papua New Guinea',
              },
              {
                value: 'POL',
                label: 'Poland',
              },
              {
                value: 'PRI',
                label: 'Puerto Rico',
              },
              {
                value: 'PRK',
                label: "Korea (Democratic People's Republic of)",
              },
              {
                value: 'PRT',
                label: 'Portugal',
              },
              {
                value: 'PRY',
                label: 'Paraguay',
              },
              {
                value: 'PSE',
                label: 'Palestine, State of',
              },
              {
                value: 'PYF',
                label: 'French Polynesia',
              },
              {
                value: 'QAT',
                label: 'Qatar',
              },
              {
                value: 'REU',
                label: 'Réunion',
              },
              {
                value: 'ROU',
                label: 'Romania',
              },
              {
                value: 'RUS',
                label: 'Russian Federation',
              },
              {
                value: 'RWA',
                label: 'Rwanda',
              },
              {
                value: 'SAU',
                label: 'Saudi Arabia',
              },
              {
                value: 'SDN',
                label: 'Sudan',
              },
              {
                value: 'SEN',
                label: 'Senegal',
              },
              {
                value: 'SGP',
                label: 'Singapore',
              },
              {
                value: 'SGS',
                label: 'South Georgia and the South Sandwich Islands',
              },
              {
                value: 'SHN',
                label: 'Saint Helena, Ascension and Tristan da Cunha',
              },
              {
                value: 'SJM',
                label: 'Svalbard and Jan Mayen',
              },
              {
                value: 'SLB',
                label: 'Solomon Islands',
              },
              {
                value: 'SLE',
                label: 'Sierra Leone',
              },
              {
                value: 'SLV',
                label: 'El Salvador',
              },
              {
                value: 'SMR',
                label: 'San Marino',
              },
              {
                value: 'SOM',
                label: 'Somalia',
              },
              {
                value: 'SPM',
                label: 'Saint Pierre and Miquelon',
              },
              {
                value: 'SRB',
                label: 'Serbia',
              },
              {
                value: 'SSD',
                label: 'South Sudan',
              },
              {
                value: 'STP',
                label: 'Sao Tome and Principe',
              },
              {
                value: 'SUR',
                label: 'Suriname',
              },
              {
                value: 'SVK',
                label: 'Slovakia',
              },
              {
                value: 'SVN',
                label: 'Slovenia',
              },
              {
                value: 'SWE',
                label: 'Sweden',
              },
              {
                value: 'SWZ',
                label: 'Eswatini',
              },
              {
                value: 'SXM',
                label: 'Sint Maarten (Dutch part)',
              },
              {
                value: 'SYC',
                label: 'Seychelles',
              },
              {
                value: 'SYR',
                label: 'Syrian Arab Republic',
              },
              {
                value: 'TCA',
                label: 'Turks and Caicos Islands',
              },
              {
                value: 'TCD',
                label: 'Chad',
              },
              {
                value: 'TGO',
                label: 'Togo',
              },
              {
                value: 'THA',
                label: 'Thailand',
              },
              {
                value: 'TJK',
                label: 'Tajikistan',
              },
              {
                value: 'TKL',
                label: 'Tokelau',
              },
              {
                value: 'TKM',
                label: 'Turkmenistan',
              },
              {
                value: 'TLS',
                label: 'Timor-Leste',
              },
              {
                value: 'TON',
                label: 'Tonga',
              },
              {
                value: 'TTO',
                label: 'Trinidad and Tobago',
              },
              {
                value: 'TUN',
                label: 'Tunisia',
              },
              {
                value: 'TUR',
                label: 'Turkey',
              },
              {
                value: 'TUV',
                label: 'Tuvalu',
              },
              {
                value: 'TWN',
                label: 'Taiwan, Province of China',
              },
              {
                value: 'TZA',
                label: 'Tanzania, United Republic of',
              },
              {
                value: 'UGA',
                label: 'Uganda',
              },
              {
                value: 'UKR',
                label: 'Ukraine',
              },
              {
                value: 'UMI',
                label: 'United States Minor Outlying Islands',
              },
              {
                value: 'URY',
                label: 'Uruguay',
              },
              {
                value: 'USA',
                label: 'United States of America',
              },
              {
                value: 'UZB',
                label: 'Uzbekistan',
              },
              {
                value: 'VAT',
                label: 'Holy See',
              },
              {
                value: 'VCT',
                label: 'Saint Vincent and the Grenadines',
              },
              {
                value: 'VEN',
                label: 'Venezuela (Bolivarian Republic of)',
              },
              {
                value: 'VGB',
                label: 'Virgin Islands (British)',
              },
              {
                value: 'VIR',
                label: 'Virgin Islands (U.S.)',
              },
              {
                value: 'VNM',
                label: 'Viet Nam',
              },
              {
                value: 'VUT',
                label: 'Vanuatu',
              },
              {
                value: 'WLF',
                label: 'Wallis and Futuna',
              },
              {
                value: 'WSM',
                label: 'Samoa',
              },
              {
                value: 'YEM',
                label: 'Yemen',
              },
              {
                value: 'ZAF',
                label: 'South Africa',
              },
              {
                value: 'ZMB',
                label: 'Zambia',
              },
              {
                value: 'ZWE',
                label: 'Zimbabwe',
              },
            ],
          },
        },
        medicalHistory: {
          'ui:title': 'Medical history',
          'ui:type': 'box',
          'ui:order': [
            'isDiagnosed',
            'diagnoses',
            'endometriosis',
            'tubalLigation',
            'oophorectomy',
            'hysterectomy',
            'gynecologicalOperation',
            'mastectomy',
            'breastOperation',
          ],
          'ui:subsections': [
            {
              'ui:title': 'Cancer and cancer syndrome diagnoses',
              'ui:items': [
                {
                  'ui:properties': ['isDiagnosed', 'diagnoses'],
                },
              ],
            },
            {
              'ui:title': 'Other medical conditions',
              'ui:items': [
                {
                  'ui:properties': 'endometriosis',
                },
              ],
            },
            {
              'ui:title': 'Treatments',
              'ui:items': [
                {
                  'ui:title': 'Gynecological',
                  'ui:properties': [
                    'tubalLigation',
                    'oophorectomy',
                    'removedOvaries',
                    'hysterectomy',
                    'gynecologicalOperation',
                    'gynecologicalOperationReason',
                  ],
                },
                {
                  'ui:title': 'Breast health',
                  'ui:properties': [
                    'mastectomy',
                    'removedBreasts',
                    'breastOperation',
                    'breastOperationReason',
                  ],
                },
              ],
            },
          ],
          isDiagnosed: {
            'ui:title': 'Diagnosed with cancer or a cancer syndrome?',
            'ui:widget': 'radio',
          },
          diagnoses: {
            'ui:title': 'Diagnosis',
            'ui:arrayFieldTemplate': 'DiagnosisForm',
            'ui:options': {
              orderable: false,
            },
            items: {
              'ui:gapItems': 2,
              'ui:alignItems': 'end',
              id: {
                'ui:widget': 'hidden',
              },
              readonly: {
                'ui:widget': 'hidden',
              },
              disease: {
                'ui:title': 'Select diagnosis',
                'ui:placeholder': 'Select or type here',
                'ui:freeSolo': true,
                'ui:inputWidth': '25rem',
                'ui:widget': 'AutocompleteWidget',
                'ui:enumOptions': [
                  {
                    value: 'bladderCancer',
                    label: 'Bladder cancer',
                  },
                  {
                    value: 'breastCancer',
                    label: 'Breast cancer',
                  },
                  {
                    value: 'colonCancer',
                    label: 'Colorectal/Bowel cancer',
                  },
                  {
                    value: 'cowdensSyndrome',
                    label: 'Cowden syndrome',
                  },
                  {
                    value: 'endometrialCancer',
                    label: 'Endometrial cancer',
                  },
                  {
                    value: 'hnpcc',
                    label: 'Hereditary Nonpolyposis Colorectal Cancer (HNPCC)',
                  },
                  {
                    value: 'kidneyCancer',
                    label: 'Kidney/renal cancer',
                  },
                  {
                    value: 'bloodCancer',
                    label: 'Leukemia/blood cancer',
                  },
                  {
                    value: 'liFraumeniSyndrome',
                    label: 'Li-Fraumeni syndrome (LFS)',
                  },
                  {
                    value: 'lungCancer',
                    label: 'Lung cancer',
                  },
                  {
                    value: 'lynchSyndrome',
                    label: 'Lynch syndrome',
                  },
                  {
                    value: 'melanoma',
                    label: 'Melanoma',
                  },
                  {
                    value: 'myelodysplasticSyndrome',
                    label: 'Myelodysplastic syndromes (MDS)',
                  },
                  {
                    value: 'nonMelanomaSkinCancer',
                    label: 'Non-melanoma skin cancer',
                  },
                  {
                    value: 'ovarianCancer',
                    label: 'Ovarian cancer',
                  },
                  {
                    value: 'pancreaticCancer',
                    label: 'Pancreatic cancer',
                  },
                  {
                    value: 'polyps',
                    label: 'Polyps',
                  },
                  {
                    value: 'prostateCancer',
                    label: 'Prostate cancer',
                  },
                  {
                    value: 'thyroidCancer',
                    label: 'Thyroid cancer',
                  },
                  {
                    value: 'other',
                    label: 'Other',
                  },
                  {
                    value: 'notSure',
                    label: 'Not sure',
                  },
                ],
              },
              ageAtDiagnosis: {
                'ui:title': 'Age at diagnosis',
                'ui:gridColumnWidth': 'auto',
                'ui:helperTextWhiteSpace': 'nowrap',
              },
              ageAtDiagnosisIsApproximate: {
                'ui:title': 'Approximate',
                'ui:gridColumnWidth': 'auto',
                'ui:alingSelf': 'flex-start',
                'ui:marginTop': '1.5rem',
              },
              treatedBy: {
                'ui:title': 'Name of the clinic where treated',
                'ui:help': 'If treated outside of Sweden, use country name',
              },
              notes: {
                'ui:title': 'Diagnosis notes',
                'ui:widget': 'textarea',
                'ui:help': 'Optional',
              },
              confirmed: {
                'ui:widget': 'hidden',
              },
              status: {
                'ui:widget': 'hidden',
              },
            },
          },
          endometriosis: {
            'ui:title':
              'Has the person ever been diagnosed with endometriosis?',
            'ui:widget': 'radio',
          },
          tubalLigation: {
            'ui:title': 'Has the person had tubal ligation?',
            'ui:widget': 'radio',
          },
          oophorectomy: {
            hasHadAnOophorectomy: {
              'ui:title': 'Has the person had an oophorectomy?',
              'ui:widget': 'radio',
            },
            removedOvaries: {
              'ui:title': 'Please select the removed ovaries',
              'ui:widget': 'radio',
            },
          },
          hysterectomy: {
            'ui:title': 'Has the person had a hysterectomy?',
            'ui:widget': 'radio',
          },
          gynecologicalOperation: {
            hasHadAGynecologicalOperation: {
              'ui:title': 'Has the person had any gynecological operation?',
              'ui:widget': 'radio',
            },
            gynecologicalOperationReason: {
              'ui:title': 'Please add the type and reason for the operation',
              'ui:placeholder':
                'If known, what type of operation (malignant or benign) and the reason for it.',
              'ui:widget': 'textarea',
            },
          },
          mastectomy: {
            hasHadAMastectomy: {
              'ui:title': 'Has the person ever had a mastectomy?',
              'ui:widget': 'radio',
            },
            removedBreasts: {
              'ui:title': 'Please select the removed breasts:',
              'ui:widget': 'radio',
            },
          },
          breastOperation: {
            hasHadABreastOperation: {
              'ui:title': 'Has the person had any operation in their breast?',
              'ui:widget': 'radio',
            },
            breastOperationReason: {
              'ui:title': 'Please add the type and reason for the operation',
              'ui:placeholder':
                'If known, what type of operation (malignant or benign) and the reason for it.',
              'ui:widget': 'textarea',
            },
          },
        },
        geneticTesting: {
          'ui:title': 'Genetic testing',
          'ui:type': 'box',
          'ui:border': false,
          doneGeneticTesting: {
            'ui:widget': 'hidden',
          },
          geneticTests: {
            'ui:title': 'Genetic test',
            'ui:arrayFieldTemplate': 'GeneticTestsForm',
            'ui:buttonText': 'Add a genetic test result',
            items: {
              'ui:gapItems': 2,
              'ui:order': [
                'id',
                'status',
                'pathogenicVariantsFound',
                'geneticTestVariants',
                'testLocation',
                'testDate',
                'testType',
                'testPanel',
                'testPanelGenes',
              ],
              id: {
                'ui:widget': 'hidden',
              },
              status: {
                'ui:widget': 'hidden',
              },
              pathogenicVariantsFound: {
                'ui:title': 'Were any pathogenic variants identified?',
                'ui:widget': 'radio',
              },
              testDate: {
                'ui:title': 'Date of the test',
                'ui:placeholder': 'YYYY.MM.DD',
                'ui:help':
                  'You can provide the full date, the year and month, or just the year.',
                'ui:widget': 'DateFieldWidget',
              },
              testType: {
                'ui:title': 'Genetic test type',
                'ui:placeholder': 'Select',
                'ui:widget': 'AutocompleteWidget',
                'ui:inputWidth': '25rem',
                'ui:enumOptions': [
                  {
                    value: 'screening',
                    label: 'Gene panel screen',
                  },
                  {
                    value: 'targeted',
                    label: 'Targeted carrier test',
                  },
                ],
              },
              testPanel: {
                'ui:title': 'Gene panel',
                'ui:placeholder': 'Select or type here',
                'ui:inputWidth': '25rem',
                'ui:widget': 'GeneticTestPanelWidget',
                'ui:freeSolo': true,
                'ui:inputByValue': true,
                'ui:enumOptions': [
                  {
                    value: 'melanomaPanel',
                    label: 'Melanoma panel',
                    genesInTestPanel: ['CDKN2A', 'CDK4', 'BAP1', 'POT1'],
                  },
                  {
                    value: 'prostateCancerPanel',
                    label: 'Prostate cancer panel',
                    genesInTestPanel: ['BRCA1', 'MSH2', 'BRCA2', 'TP53'],
                  },
                  {
                    value: 'breastCancerPanel',
                    label: 'Breast cancer panel',
                    genesInTestPanel: [
                      'BRCA1',
                      'BARD1',
                      'ATM',
                      'CHEK2',
                      'PALB2',
                      'BRCA2',
                      'RAD51D',
                      'RAD51C',
                      'TP53',
                      'CDH1',
                      'STK11',
                      'PTEN',
                    ],
                  },
                  {
                    value: 'ovarianCancerPanel',
                    label: 'Ovarian cancer panel',
                    genesInTestPanel: [
                      'BRCA1',
                      'MSH2',
                      'PALB2',
                      'BRCA2',
                      'EPCAM',
                      'MLH1',
                      'BRIP1',
                      'MSH6',
                      'PMS2',
                      'RAD51D',
                      'RAD51C',
                    ],
                  },
                  {
                    value: 'colonCancerPanel',
                    label: 'Colon cancer panel',
                    genesInTestPanel: [
                      'APC',
                      'MUTYH',
                      'EPCAM',
                      'MLH1',
                      'MSH6',
                      'PMS2',
                      'STK11',
                      'PTEN',
                      'SMAD4',
                      'BMPR1A',
                      'BMPRI1A',
                      'MSH3',
                      'NTHL1',
                      'POLD1',
                      'POLE',
                    ],
                  },
                  {
                    value: 'other',
                    label: 'Other',
                    genesInTestPanel: [],
                  },
                ],
              },
              testLocation: {
                'ui:title': 'Where was the test done?',
                'ui:help':
                  'Clinic name or country (if tested outside of Sweden)',
              },
              testPanelGenes: {
                'ui:title': 'Genes in the test panel',
                'ui:placeholder': 'Select or type here',
                'ui:help':
                  'You can select multiple genes from the list. If a gene is not listed, you can type its name.',
                'ui:inputWidth': '25rem',
                'ui:widget': 'AutocompleteWidget',
                'ui:freeSolo': true,
                'ui:inputByValue': true,
                'ui:enumOptions': [
                  {
                    value: 'APC',
                    label: 'APC',
                  },
                  {
                    value: 'ATM',
                    label: 'ATM',
                  },
                  {
                    value: 'MLH1',
                    label: 'MLH1',
                  },
                  {
                    value: 'MSH2',
                    label: 'MSH2',
                  },
                  {
                    value: 'MSH6',
                    label: 'MSH6',
                  },
                  {
                    value: 'PMS2',
                    label: 'PMS2',
                  },
                  {
                    value: 'MUTYH',
                    label: 'MUTYH',
                  },
                  {
                    value: 'EPCAM',
                    label: 'EPCAM',
                  },
                  {
                    value: 'BRCA1',
                    label: 'BRCA1',
                  },
                  {
                    value: 'BRCA2',
                    label: 'BRCA2',
                  },
                  {
                    value: 'PALB2',
                    label: 'PALB2',
                  },
                  {
                    value: 'CHEK2',
                    label: 'CHEK2',
                  },
                  {
                    value: 'BARD1',
                    label: 'BARD1',
                  },
                  {
                    value: 'BRIP1',
                    label: 'BRIP1',
                  },
                  {
                    value: 'RAD51C',
                    label: 'RAD51C',
                  },
                  {
                    value: 'RAD51D',
                    label: 'RAD51D',
                  },
                  {
                    value: 'UNKNOWN',
                    label: 'Mutation(s) with unknown name',
                  },
                ],
              },
              geneticTestVariants: {
                'ui:title': 'List of pathogenic variants',
                'ui:arrayFieldTemplate': 'GeneticTestVariantsForm',
                'ui:buttonText': 'Add another pathogenic variant',
                items: {
                  id: {
                    'ui:widget': 'hidden',
                  },
                  name: {
                    'ui:title': 'Gene',
                    'ui:placeholder': 'Select or type here',
                    'ui:widget': 'AutocompleteWidget',
                    'ui:freeSolo': true,
                    'ui:inputByValue': true,
                    'ui:enumOptions': [
                      {
                        value: 'APC',
                        label: 'APC',
                      },
                      {
                        value: 'ATM',
                        label: 'ATM',
                      },
                      {
                        value: 'MLH1',
                        label: 'MLH1',
                      },
                      {
                        value: 'MSH2',
                        label: 'MSH2',
                      },
                      {
                        value: 'MSH6',
                        label: 'MSH6',
                      },
                      {
                        value: 'PMS2',
                        label: 'PMS2',
                      },
                      {
                        value: 'MUTYH',
                        label: 'MUTYH',
                      },
                      {
                        value: 'EPCAM',
                        label: 'EPCAM',
                      },
                      {
                        value: 'BRCA1',
                        label: 'BRCA1',
                      },
                      {
                        value: 'BRCA2',
                        label: 'BRCA2',
                      },
                      {
                        value: 'PALB2',
                        label: 'PALB2',
                      },
                      {
                        value: 'CHEK2',
                        label: 'CHEK2',
                      },
                      {
                        value: 'BARD1',
                        label: 'BARD1',
                      },
                      {
                        value: 'BRIP1',
                        label: 'BRIP1',
                      },
                      {
                        value: 'RAD51C',
                        label: 'RAD51C',
                      },
                      {
                        value: 'RAD51D',
                        label: 'RAD51D',
                      },
                      {
                        value: 'UNKNOWN',
                        label: 'Mutation(s) with unknown name',
                      },
                    ],
                  },
                  variants: {
                    'ui:title': 'Variant',
                    'ui:titleVariant': 'h5',
                    rsid: {
                      'ui:title': 'rsID number',
                    },
                    identifier: {
                      'ui:title': 'Identifier',
                    },
                    location: {
                      'ui:title': 'Location',
                    },
                  },
                },
              },
            },
          },
        },
        womenHealth: {
          'ui:title': "Women's health",
          'ui:type': 'box',
          menstruation: {
            ageStart: {
              'ui:title': 'At what age did the period start?',
              'ui:placeholder': 'Age in years',
              'ui:description': 'Approximate age is okay',
              'ui:inputWidth': '10rem',
            },
            hasStopped: {
              'ui:title': 'Have the periods stopped completely?',
              'ui:widget': 'radio',
            },
            ageStopped: {
              'ui:title': 'At what age did the periods stop completely?',
              'ui:widget': 'radio',
              'ui:direction': 'column',
            },
            hormonalReplacement: {
              hasTaken: {
                'ui:title':
                  'Did the person use hormonal replacement therapy for the menopause?',
                'ui:widget': 'radio',
              },
              duration: {
                'ui:title': 'For how many years in total?',
                'ui:inputWidth': '10rem',
              },
              usedInLast5Years: {
                'ui:title':
                  'Has the person used hormonal replacement therapy in the last 5 years?',
                'ui:widget': 'radio',
              },
            },
          },
          hormonalContraception: {
            hasTaken: {
              'ui:title':
                'Has the person ever taken hormonal contraception pills?',
              'ui:widget': 'radio',
              'ui:tooltip':
                'Includes the combined pill and the progesterone-only (mini) pill. Does not include other forms of hormonal contraception such as the contraceptive implant, injection or Mirena coil.',
            },
            duration: {
              'ui:title': 'For how many years in total?',
              'ui:widget': 'radio',
              'ui:direction': 'column',
            },
            usedInLast2Years: {
              'ui:title':
                'Has the person taken the pills in the last two years?',
              'ui:widget': 'radio',
            },
          },
          mammography: {
            hasHadAMammogram: {
              'ui:title': 'Has the person ever had a mammogram?',
              'ui:widget': 'radio',
            },
            lastMammogramDate: {
              'ui:title': 'When was the last mammogram?',
              'ui:placeholder': 'YYYY.MM.DD',
              'ui:inputWidth': '25rem',
            },
          },
        },
        lifeStyle: {
          'ui:title': 'Lifestyle',
          'ui:type': 'box',
          height: {
            'ui:title': 'Height',
            'ui:unit': 'cm',
            'ui:gridColumnWidth': 2,
          },
          weight: {
            'ui:title': 'Weight',
            'ui:unit': 'kg',
            'ui:gridColumnWidth': 2,
            key: 'lifestyle.weight',
          },
          bmi: {
            'ui:title': 'Calculated BMI',
            'ui:field': 'CalculatedBmiField',
            'ui:gridColumnWidth': 'auto',
          },
          smoking: {
            'ui:title': 'Does the person smoke?',
            'ui:widget': 'radio',
          },
          alcohol: {
            'ui:title':
              'If the person drinks alcohol, how much of the following is usually consumed per week?',
            alcopop: {
              'ui:title': 'cans of alcopop drinks (275 ml)',
              'ui:inlineLabel': true,
              'ui:inputWidth': '4rem',
            },
            beer: {
              'ui:title': 'bottles of beer (330 ml)',
              'ui:inlineLabel': true,
              'ui:inputWidth': '4rem',
            },
            spirit: {
              'ui:title': 'shots of spirits (25 ml)',
              'ui:inlineLabel': true,
              'ui:inputWidth': '4rem',
            },
            wine: {
              'ui:title': 'glasses of wine (150 ml)',
              'ui:inlineLabel': true,
              'ui:inputWidth': '4rem',
            },
          },
        },
      },
    },
    relativeSchema: {
      jsonSchema: {
        type: 'object',
        properties: {
          personalInfo: {
            $ref: '#/definitions/personalInfo',
          },
          medicalHistory: {
            type: 'object',
            properties: {
              isDiagnosed: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              isDiagnosed: {
                oneOf: [
                  {
                    properties: {
                      isDiagnosed: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      isDiagnosed: {
                        const: 'yes',
                      },
                      diagnoses: {
                        type: 'array',
                        minItems: 1,
                        items: {
                          $ref: '#/definitions/diagnosisItemForStaff',
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTesting: {
            $ref: '#/definitions/geneticTestingStaffConsumer',
          },
        },
        definitions: {
          yesNoQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
            ],
          },
          yesNoNotSureQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'yes',
                title: 'Yes',
              },
              {
                const: 'no',
                title: 'No',
              },
              {
                const: 'notSure',
                title: 'Not sure',
              },
            ],
          },
          leftRightBothQuestion: {
            type: 'string',
            oneOf: [
              {
                const: 'left',
                title: 'Left',
              },
              {
                const: 'right',
                title: 'Right',
              },
              {
                const: 'both',
                title: 'Both',
              },
            ],
          },
          oophorectomy: {
            type: 'object',
            properties: {
              hasHadAnOophorectomy: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAnOophorectomy: {
                oneOf: [
                  {
                    properties: {
                      hasHadAnOophorectomy: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAnOophorectomy: {
                        enum: ['yes'],
                      },
                      removedOvaries: {
                        $ref: '#/definitions/leftRightBothQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          gynecologicalOperation: {
            type: 'object',
            properties: {
              hasHadAGynecologicalOperation: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAGynecologicalOperation: {
                oneOf: [
                  {
                    properties: {
                      hasHadAGynecologicalOperation: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAGynecologicalOperation: {
                        enum: ['yes'],
                      },
                      gynecologicalOperationReason: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          mastectomy: {
            type: 'object',
            properties: {
              hasHadAMastectomy: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAMastectomy: {
                oneOf: [
                  {
                    properties: {
                      hasHadAMastectomy: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAMastectomy: {
                        enum: ['yes'],
                      },
                      removedBreasts: {
                        $ref: '#/definitions/leftRightBothQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          breastOperation: {
            type: 'object',
            properties: {
              hasHadABreastOperation: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadABreastOperation: {
                oneOf: [
                  {
                    properties: {
                      hasHadABreastOperation: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadABreastOperation: {
                        enum: ['yes'],
                      },
                      breastOperationReason: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          mammography: {
            type: 'object',
            properties: {
              hasHadAMammogram: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasHadAMammogram: {
                oneOf: [
                  {
                    properties: {
                      hasHadAMammogram: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasHadAMammogram: {
                        enum: ['yes'],
                      },
                      lastMammogramDate: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          positiveIntegerQuestion: {
            type: 'integer',
          },
          yearOfBirth: {
            type: 'integer',
          },
          menstruation: {
            type: 'object',
            properties: {
              ageStart: {
                type: 'integer',
                maximumAge: {
                  $data: [
                    '/personalData/age',
                    '/personalInfo/ageAtDeath',
                    '/personalInfo/yearOfBirth',
                  ],
                },
              },
              hasStopped: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasStopped: {
                oneOf: [
                  {
                    properties: {
                      hasStopped: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasStopped: {
                        enum: ['yes'],
                      },
                      ageStopped: {
                        type: 'string',
                        maximumAgeRange: {
                          $data: [
                            '/personalData/age',
                            '/personalInfo/ageAtDeath',
                            '/personalInfo/yearOfBirth',
                          ],
                        },
                        oneOf: [
                          {
                            const: 'before40',
                            title: 'Before 40',
                          },
                          {
                            const: '40-44',
                            title: '40 - 44',
                          },
                          {
                            const: '45-49',
                            title: '45 - 49',
                          },
                          {
                            const: '50-54',
                            title: '50 - 54',
                          },
                          {
                            const: 'after54',
                            title: 'After 54',
                          },
                        ],
                      },
                      hormonalReplacement: {
                        $ref: '#/definitions/hormonalReplacement',
                      },
                    },
                  },
                ],
              },
            },
          },
          hormonalReplacement: {
            type: 'object',
            properties: {
              hasTaken: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasTaken: {
                oneOf: [
                  {
                    properties: {
                      hasTaken: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasTaken: {
                        enum: ['yes'],
                      },
                      duration: {
                        $ref: '#/definitions/positiveIntegerQuestion',
                      },
                      usedInLast5Years: {
                        $ref: '#/definitions/yesNoQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          hormonalContraception: {
            type: 'object',
            properties: {
              hasTaken: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              hasTaken: {
                oneOf: [
                  {
                    properties: {
                      hasTaken: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasTaken: {
                        enum: ['yes'],
                      },
                      duration: {
                        type: 'string',
                        oneOf: [
                          {
                            const: '<1',
                            title: 'Less than 1',
                          },
                          {
                            const: '1-4',
                            title: '1 - 4',
                          },
                          {
                            const: '5-9',
                            title: '5 - 9',
                          },
                          {
                            const: '10-14',
                            title: '10 - 14',
                          },
                          {
                            const: '>14',
                            title: 'More than 14',
                          },
                        ],
                      },
                      usedInLast2Years: {
                        $ref: '#/definitions/yesNoQuestion',
                      },
                    },
                  },
                ],
              },
            },
          },
          alcoholConsumption: {
            type: 'object',
            properties: {
              alcopop: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              beer: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              spirit: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
              wine: {
                $ref: '#/definitions/positiveIntegerQuestion',
              },
            },
          },
          genes: {
            type: 'array',
            uniqueItems: true,
            items: {
              type: 'string',
            },
          },
          geneticTestingPatientConsumer: {
            type: 'object',
            properties: {
              doneGeneticTesting: {
                $ref: '#/definitions/yesNoQuestion',
              },
            },
            dependencies: {
              doneGeneticTesting: {
                oneOf: [
                  {
                    properties: {
                      doneGeneticTesting: {
                        enum: ['no', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      doneGeneticTesting: {
                        enum: ['yes'],
                      },
                      testLocation: {
                        type: 'string',
                      },
                      hasPathogenicGenes: {
                        $ref: '#/definitions/yesNoNotSureQuestion',
                      },
                    },
                  },
                ],
              },
              hasPathogenicGenes: {
                oneOf: [
                  {
                    properties: {
                      hasPathogenicGenes: {
                        enum: ['no', 'notSure', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      hasPathogenicGenes: {
                        enum: ['yes'],
                      },
                      pathogenicGenes: {
                        $ref: '#/definitions/genes',
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTestingStaffConsumer: {
            type: 'object',
            properties: {
              doneGeneticTesting: {
                $ref: '#/definitions/yesNoQuestion',
              },
              geneticTests: {
                type: 'array',
                minItems: 0,
                items: {
                  $ref: '#/definitions/geneticTestingItem',
                },
              },
            },
          },
          geneticTestingItem: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              status: {
                type: 'string',
                readOnly: true,
              },
              pathogenicVariantsFound: {
                $ref: '#/definitions/yesNoNotSureQuestion',
              },
              testDate: {
                type: 'string',
              },
              testType: {
                type: 'string',
                oneOf: [
                  {
                    const: 'screening',
                    title: 'Gene panel screen',
                  },
                  {
                    const: 'targeted',
                    title: 'Targeted carrier test',
                  },
                ],
              },
              testLocation: {
                type: 'string',
              },
              testPanelGenes: {
                $ref: '#/definitions/genes',
              },
            },
            dependencies: {
              pathogenicVariantsFound: {
                oneOf: [
                  {
                    properties: {
                      pathogenicVariantsFound: {
                        enum: ['no', 'notSure', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      pathogenicVariantsFound: {
                        enum: ['yes'],
                      },
                      geneticTestVariants: {
                        type: 'array',
                        minItems: 1,
                        items: {
                          $ref: '#/definitions/geneticTestVariantsItem',
                        },
                      },
                    },
                  },
                ],
              },
              testType: {
                oneOf: [
                  {
                    properties: {
                      testType: {
                        enum: ['targeted', ''],
                      },
                    },
                  },
                  {
                    properties: {
                      testType: {
                        enum: ['screening'],
                      },
                      testPanel: {
                        type: 'string',
                      },
                    },
                  },
                ],
              },
            },
          },
          geneticTestVariantsItem: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              name: {
                type: 'string',
              },
            },
            dependencies: {
              name: {
                oneOf: [
                  {
                    properties: {
                      name: {
                        type: 'string',
                      },
                      variants: {
                        type: 'object',
                        properties: {
                          rsid: {
                            type: 'string',
                          },
                          identifier: {
                            type: 'string',
                          },
                          location: {
                            type: 'string',
                          },
                        },
                      },
                    },
                  },
                ],
              },
            },
            required: ['name'],
          },
          personalInfo: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
              },
              isAlive: {
                type: 'string',
                oneOf: [
                  {
                    const: 'yes',
                    title: 'Yes, living',
                  },
                  {
                    const: 'no',
                    title: 'No, deceased',
                  },
                ],
              },
            },
            dependencies: {
              isAlive: {
                oneOf: [
                  {
                    properties: {
                      isAlive: {
                        enum: ['', null],
                      },
                    },
                  },
                  {
                    properties: {
                      isAlive: {
                        enum: ['yes'],
                      },
                      yearOfBirth: {
                        $ref: '#/definitions/yearOfBirth',
                      },
                      yearOfBirthApproximate: {
                        type: 'boolean',
                      },
                    },
                  },
                  {
                    properties: {
                      isAlive: {
                        enum: ['no'],
                      },
                      yearOfBirth: {
                        $ref: '#/definitions/yearOfBirth',
                      },
                      yearOfBirthApproximate: {
                        type: 'boolean',
                      },
                      ageAtDeath: {
                        type: 'integer',
                        maximum: 125,
                        maximumAge: {
                          $data: ['/personalInfo/yearOfBirth'],
                        },
                      },
                      ageAtDeathApproximate: {
                        type: 'boolean',
                      },
                    },
                  },
                ],
              },
            },
          },
          diagnosisItemForStaff: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              confirmed: {
                type: 'boolean',
              },
              status: {
                type: 'object',
                properties: {
                  state: {
                    type: 'string',
                    readOnly: true,
                  },
                  performedBy: {
                    type: 'string',
                    readOnly: true,
                  },
                  performedAt: {
                    type: 'string',
                    readOnly: true,
                  },
                  name: {
                    type: 'string',
                    readOnly: true,
                  },
                },
              },
              disease: {
                type: 'string',
              },
              ageAtDiagnosis: {
                type: 'integer',
                maximumAge: {
                  $data: [
                    '/personalData/age',
                    '/personalInfo/ageAtDeath',
                    '/personalInfo/yearOfBirth',
                  ],
                },
              },
              ageAtDiagnosisIsApproximate: {
                type: 'boolean',
              },
              treatedBy: {
                type: 'string',
              },
              notes: {
                type: 'string',
              },
            },
            required: ['disease'],
          },
          diagnosisItemForPatient: {
            type: 'object',
            properties: {
              id: {
                oneOf: [
                  {
                    type: 'string',
                  },
                  {
                    type: 'null',
                  },
                ],
                readOnly: true,
              },
              readonly: {
                type: 'boolean',
                readOnly: true,
              },
              disease: {
                type: 'string',
              },
              ageAtDiagnosis: {
                type: 'integer',
                maximumAge: {
                  $data: [
                    '/personalData/age',
                    '/personalInfo/ageAtDeath',
                    '/personalInfo/yearOfBirth',
                  ],
                },
              },
              ageAtDiagnosisIsApproximate: {
                type: 'boolean',
              },
              treatedBy: {
                type: 'string',
              },
              notes: {
                type: 'string',
              },
            },
          },
        },
        additionalProperties: false,
      },
      uiSchema: {
        personalInfo: {
          'ui:title': 'Personal information',
          'ui:type': 'box',
          'ui:border': false,
          'ui:alignItems': 'end',
          name: {
            'ui:title': 'Full name',
          },
          isAlive: {
            'ui:title': 'Living?',
            'ui:widget': 'radio',
          },
          yearOfBirth: {
            'ui:title': 'Year of birth',
            'ui:gridColumnWidth': 3,
            'ui:helperTextWhiteSpace': 'nowrap',
          },
          yearOfBirthApproximate: {
            'ui:title': 'Approximate',
            'ui:widget': 'checkbox',
            'ui:gridColumnWidth': 6,
            'ui:alingSelf': 'flex-start',
            'ui:marginTop': '1.5rem',
          },
          ageAtDeath: {
            'ui:title': 'Age at death',
            'ui:gridColumnWidth': 3,
            'ui:helperTextWhiteSpace': 'nowrap',
          },
          ageAtDeathApproximate: {
            'ui:title': 'Approximate',
            'ui:widget': 'checkbox',
            'ui:gridColumnWidth': 6,
            'ui:alingSelf': 'flex-start',
            'ui:marginTop': '1.5rem',
          },
        },
        medicalHistory: {
          'ui:title': 'Medical history',
          'ui:type': 'box',
          'ui:border': false,
          isDiagnosed: {
            'ui:title': 'Diagnosed with cancer or a cancer syndrome?',
            'ui:widget': 'radio',
          },
          diagnoses: {
            'ui:title': 'Diagnosis',
            'ui:arrayFieldTemplate': 'DiagnosisForm',
            'ui:options': {
              orderable: false,
            },
            items: {
              'ui:gapItems': 2,
              'ui:alignItems': 'end',
              id: {
                'ui:widget': 'hidden',
              },
              readonly: {
                'ui:widget': 'hidden',
              },
              disease: {
                'ui:title': 'Select diagnosis',
                'ui:placeholder': 'Select or type here',
                'ui:widget': 'AutocompleteWidget',
                'ui:freeSolo': true,
                'ui:inputWidth': '25rem',
                'ui:enumOptions': [
                  {
                    value: 'bladderCancer',
                    label: 'Bladder cancer',
                  },
                  {
                    value: 'breastCancer',
                    label: 'Breast cancer',
                  },
                  {
                    value: 'colonCancer',
                    label: 'Colorectal/Bowel cancer',
                  },
                  {
                    value: 'cowdensSyndrome',
                    label: 'Cowden syndrome',
                  },
                  {
                    value: 'endometrialCancer',
                    label: 'Endometrial cancer',
                  },
                  {
                    value: 'hnpcc',
                    label: 'Hereditary Nonpolyposis Colorectal Cancer (HNPCC)',
                  },
                  {
                    value: 'kidneyCancer',
                    label: 'Kidney/renal cancer',
                  },
                  {
                    value: 'bloodCancer',
                    label: 'Leukemia/blood cancer',
                  },
                  {
                    value: 'liFraumeniSyndrome',
                    label: 'Li-Fraumeni syndrome (LFS)',
                  },
                  {
                    value: 'lungCancer',
                    label: 'Lung cancer',
                  },
                  {
                    value: 'lynchSyndrome',
                    label: 'Lynch syndrome',
                  },
                  {
                    value: 'melanoma',
                    label: 'Melanoma',
                  },
                  {
                    value: 'myelodysplasticSyndrome',
                    label: 'Myelodysplastic syndromes (MDS)',
                  },
                  {
                    value: 'nonMelanomaSkinCancer',
                    label: 'Non-melanoma skin cancer',
                  },
                  {
                    value: 'ovarianCancer',
                    label: 'Ovarian cancer',
                  },
                  {
                    value: 'pancreaticCancer',
                    label: 'Pancreatic cancer',
                  },
                  {
                    value: 'polyps',
                    label: 'Polyps',
                  },
                  {
                    value: 'prostateCancer',
                    label: 'Prostate cancer',
                  },
                  {
                    value: 'thyroidCancer',
                    label: 'Thyroid cancer',
                  },
                  {
                    value: 'other',
                    label: 'Other',
                  },
                  {
                    value: 'notSure',
                    label: 'Not sure',
                  },
                ],
              },
              ageAtDiagnosis: {
                'ui:title': 'Age at diagnosis',
                'ui:gridColumnWidth': 'auto',
                'ui:helperTextWhiteSpace': 'nowrap',
              },
              ageAtDiagnosisIsApproximate: {
                'ui:title': 'Approximate',
                'ui:gridColumnWidth': 'auto',
                'ui:alingSelf': 'flex-start',
                'ui:marginTop': '1.5rem',
              },
              treatedBy: {
                'ui:title': 'Name of the clinic where treated',
                'ui:help': 'If treated outside of Sweden, use country name',
              },
              notes: {
                'ui:title': 'Diagnosis notes',
                'ui:widget': 'textarea',
                'ui:help': 'Optional',
              },
              confirmed: {
                'ui:widget': 'hidden',
              },
              status: {
                'ui:widget': 'hidden',
              },
            },
          },
        },
        geneticTesting: {
          'ui:title': 'Genetic testing',
          'ui:type': 'box',
          'ui:border': false,
          doneGeneticTesting: {
            'ui:widget': 'hidden',
          },
          geneticTests: {
            'ui:title': 'Genetic test',
            'ui:arrayFieldTemplate': 'GeneticTestsForm',
            'ui:buttonText': 'Add a genetic test result',
            items: {
              'ui:gapItems': 2,
              'ui:order': [
                'id',
                'status',
                'pathogenicVariantsFound',
                'geneticTestVariants',
                'testLocation',
                'testDate',
                'testType',
                'testPanel',
                'testPanelGenes',
              ],
              id: {
                'ui:widget': 'hidden',
              },
              status: {
                'ui:widget': 'hidden',
              },
              pathogenicVariantsFound: {
                'ui:title': 'Were any pathogenic variants identified?',
                'ui:widget': 'radio',
              },
              testDate: {
                'ui:title': 'Date of the test',
                'ui:placeholder': 'YYYY.MM.DD',
                'ui:help':
                  'You can provide the full date, the year and month, or just the year.',
                'ui:widget': 'DateFieldWidget',
              },
              testType: {
                'ui:title': 'Genetic test type',
                'ui:placeholder': 'Select',
                'ui:widget': 'AutocompleteWidget',
                'ui:inputWidth': '25rem',
                'ui:enumOptions': [
                  {
                    value: 'screening',
                    label: 'Gene panel screen',
                  },
                  {
                    value: 'targeted',
                    label: 'Targeted carrier test',
                  },
                ],
              },
              testPanel: {
                'ui:title': 'Gene panel',
                'ui:placeholder': 'Select or type here',
                'ui:inputWidth': '25rem',
                'ui:widget': 'GeneticTestPanelWidget',
                'ui:freeSolo': true,
                'ui:inputByValue': true,
                'ui:enumOptions': [
                  {
                    value: 'melanomaPanel',
                    label: 'Melanoma panel',
                    genesInTestPanel: ['CDKN2A', 'CDK4', 'BAP1', 'POT1'],
                  },
                  {
                    value: 'prostateCancerPanel',
                    label: 'Prostate cancer panel',
                    genesInTestPanel: ['BRCA1', 'MSH2', 'BRCA2', 'TP53'],
                  },
                  {
                    value: 'breastCancerPanel',
                    label: 'Breast cancer panel',
                    genesInTestPanel: [
                      'BRCA1',
                      'BARD1',
                      'ATM',
                      'CHEK2',
                      'PALB2',
                      'BRCA2',
                      'RAD51D',
                      'RAD51C',
                      'TP53',
                      'CDH1',
                      'STK11',
                      'PTEN',
                    ],
                  },
                  {
                    value: 'ovarianCancerPanel',
                    label: 'Ovarian cancer panel',
                    genesInTestPanel: [
                      'BRCA1',
                      'MSH2',
                      'PALB2',
                      'BRCA2',
                      'EPCAM',
                      'MLH1',
                      'BRIP1',
                      'MSH6',
                      'PMS2',
                      'RAD51D',
                      'RAD51C',
                    ],
                  },
                  {
                    value: 'colonCancerPanel',
                    label: 'Colon cancer panel',
                    genesInTestPanel: [
                      'APC',
                      'MUTYH',
                      'EPCAM',
                      'MLH1',
                      'MSH6',
                      'PMS2',
                      'STK11',
                      'PTEN',
                      'SMAD4',
                      'BMPR1A',
                      'BMPRI1A',
                      'MSH3',
                      'NTHL1',
                      'POLD1',
                      'POLE',
                    ],
                  },
                  {
                    value: 'other',
                    label: 'Other',
                    genesInTestPanel: [],
                  },
                ],
              },
              testLocation: {
                'ui:title': 'Where was the test done?',
                'ui:help':
                  'Clinic name or country (if tested outside of Sweden)',
              },
              testPanelGenes: {
                'ui:title': 'Genes in the test panel',
                'ui:placeholder': 'Select or type here',
                'ui:help':
                  'You can select multiple genes from the list. If a gene is not listed, you can type its name.',
                'ui:inputWidth': '25rem',
                'ui:widget': 'AutocompleteWidget',
                'ui:freeSolo': true,
                'ui:inputByValue': true,
                'ui:enumOptions': [
                  {
                    value: 'APC',
                    label: 'APC',
                  },
                  {
                    value: 'ATM',
                    label: 'ATM',
                  },
                  {
                    value: 'MLH1',
                    label: 'MLH1',
                  },
                  {
                    value: 'MSH2',
                    label: 'MSH2',
                  },
                  {
                    value: 'MSH6',
                    label: 'MSH6',
                  },
                  {
                    value: 'PMS2',
                    label: 'PMS2',
                  },
                  {
                    value: 'MUTYH',
                    label: 'MUTYH',
                  },
                  {
                    value: 'EPCAM',
                    label: 'EPCAM',
                  },
                  {
                    value: 'BRCA1',
                    label: 'BRCA1',
                  },
                  {
                    value: 'BRCA2',
                    label: 'BRCA2',
                  },
                  {
                    value: 'PALB2',
                    label: 'PALB2',
                  },
                  {
                    value: 'CHEK2',
                    label: 'CHEK2',
                  },
                  {
                    value: 'BARD1',
                    label: 'BARD1',
                  },
                  {
                    value: 'BRIP1',
                    label: 'BRIP1',
                  },
                  {
                    value: 'RAD51C',
                    label: 'RAD51C',
                  },
                  {
                    value: 'RAD51D',
                    label: 'RAD51D',
                  },
                  {
                    value: 'BAP1',
                    label: 'BAP1',
                  },
                  {
                    value: 'BMPR1A',
                    label: 'BMPR1A',
                  },
                  {
                    value: 'CDH1',
                    label: 'CDH1',
                  },
                  {
                    value: 'CDK4',
                    label: 'CDK4',
                  },
                  {
                    value: 'CDKN2A',
                    label: 'CDKN2A',
                  },
                  {
                    value: 'MSH3',
                    label: 'MSH3',
                  },
                  {
                    value: 'NTHL1',
                    label: 'NTHL1',
                  },
                  {
                    value: 'POLD1',
                    label: 'POLD1',
                  },
                  {
                    value: 'POT1',
                    label: 'POT1',
                  },
                  {
                    value: 'PTEN',
                    label: 'PTEN',
                  },
                  {
                    value: 'SMAD4',
                    label: 'SMAD4',
                  },
                  {
                    value: 'STK11',
                    label: 'STK11',
                  },
                  {
                    value: 'TP53',
                    label: 'TP53',
                  },
                  {
                    value: 'UNKNOWN',
                    label: 'Mutation(s) with unknown name',
                  },
                ],
              },
              geneticTestVariants: {
                'ui:title': 'List of pathogenic variants',
                'ui:arrayFieldTemplate': 'GeneticTestVariantsForm',
                'ui:buttonText': 'Add another pathogenic variant',
                items: {
                  id: {
                    'ui:widget': 'hidden',
                  },
                  name: {
                    'ui:title': 'Gene',
                    'ui:placeholder': 'Select or type here',
                    'ui:widget': 'AutocompleteWidget',
                    'ui:freeSolo': true,
                    'ui:inputByValue': true,
                    'ui:enumOptions': [
                      {
                        value: 'APC',
                        label: 'APC',
                      },
                      {
                        value: 'ATM',
                        label: 'ATM',
                      },
                      {
                        value: 'MLH1',
                        label: 'MLH1',
                      },
                      {
                        value: 'MSH2',
                        label: 'MSH2',
                      },
                      {
                        value: 'MSH6',
                        label: 'MSH6',
                      },
                      {
                        value: 'PMS2',
                        label: 'PMS2',
                      },
                      {
                        value: 'MUTYH',
                        label: 'MUTYH',
                      },
                      {
                        value: 'EPCAM',
                        label: 'EPCAM',
                      },
                      {
                        value: 'BRCA1',
                        label: 'BRCA1',
                      },
                      {
                        value: 'BRCA2',
                        label: 'BRCA2',
                      },
                      {
                        value: 'PALB2',
                        label: 'PALB2',
                      },
                      {
                        value: 'CHEK2',
                        label: 'CHEK2',
                      },
                      {
                        value: 'BARD1',
                        label: 'BARD1',
                      },
                      {
                        value: 'BRIP1',
                        label: 'BRIP1',
                      },
                      {
                        value: 'RAD51C',
                        label: 'RAD51C',
                      },
                      {
                        value: 'RAD51D',
                        label: 'RAD51D',
                      },
                      {
                        value: 'BAP1',
                        label: 'BAP1',
                      },
                      {
                        value: 'BMPR1A',
                        label: 'BMPR1A',
                      },
                      {
                        value: 'CDH1',
                        label: 'CDH1',
                      },
                      {
                        value: 'CDK4',
                        label: 'CDK4',
                      },
                      {
                        value: 'CDKN2A',
                        label: 'CDKN2A',
                      },
                      {
                        value: 'MSH3',
                        label: 'MSH3',
                      },
                      {
                        value: 'NTHL1',
                        label: 'NTHL1',
                      },
                      {
                        value: 'POLD1',
                        label: 'POLD1',
                      },
                      {
                        value: 'POT1',
                        label: 'POT1',
                      },
                      {
                        value: 'PTEN',
                        label: 'PTEN',
                      },
                      {
                        value: 'SMAD4',
                        label: 'SMAD4',
                      },
                      {
                        value: 'STK11',
                        label: 'STK11',
                      },
                      {
                        value: 'TP53',
                        label: 'TP53',
                      },
                      {
                        value: 'UNKNOWN',
                        label: 'Mutation(s) with unknown name',
                      },
                    ],
                  },
                  variants: {
                    'ui:title': 'Variant',
                    'ui:titleVariant': 'h5',
                    rsid: {
                      'ui:title': 'rsID number',
                    },
                    identifier: {
                      'ui:title': 'Identifier',
                    },
                    location: {
                      'ui:title': 'Location',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};
