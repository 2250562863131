import { Container, Stack, Typography } from '@mui/material';
import { PedigreeCaseInformation } from 'api/patient/patient-service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpSection } from './common/HelpSection';
import { ConsentsSection } from './consents';
import { MessageSection } from './message';
import { PedigreeSection } from './pedigree';

interface PortalHomeProps {
  pedigreeCaseInfo: PedigreeCaseInformation;
}

export const PortalHome = (props: PortalHomeProps) => {
  const { pedigreeCaseInfo } = props;
  const { patientName, pedigreeCaseStatus } = pedigreeCaseInfo;
  const { t } = useTranslation('patientPortal');
  const [hasUnfinishedTasks, setHasUnfinishedTasks] = useState<boolean>(false);

  return (
    <Stack gap={4} mb={4}>
      <MessageSection patientName={patientName} />
      <Stack gap={3}>
        <Container maxWidth="sm">
          <Typography variant="h2">
            {t('home-next-steps-section.title')}
          </Typography>
        </Container>
        <Stack gap={4}>
          <PedigreeSection
            pedigreeCaseStatus={pedigreeCaseStatus}
            hasUnfinishedTasks={hasUnfinishedTasks}
          />
          <ConsentsSection setHasUnfinishedTasks={setHasUnfinishedTasks} />
        </Stack>
      </Stack>
      <HelpSection />
    </Stack>
  );
};
