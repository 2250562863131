import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Container, Stack } from '@mui/material';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb, { BreadcrumbItem } from './Breadcrumbs';

type ItemKey = 'home' | 'questionnaire' | 'pedigree' | 'messages';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout(props: LayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['patientPortal']);
  const currentPath = location.pathname;

  const activeItem = useMemo(() => {
    if (currentPath.includes(PATIENT_PATHS.QUESTIONNAIRE)) {
      return PATIENT_PATHS.QUESTIONNAIRE;
    }
    if (currentPath.includes(PATIENT_PATHS.PEDIGREE)) {
      return PATIENT_PATHS.PEDIGREE;
    }
    if (currentPath.includes(PATIENT_PATHS.MESSAGES)) {
      return PATIENT_PATHS.MESSAGES;
    }
    return 'home';
  }, [currentPath]);

  const handleLinkClick = (breadcrumbKey: string) => {
    if (breadcrumbKey === PATIENT_PATHS.QUESTIONNAIRE) {
      return navigate('../' + PATIENT_PATHS.QUESTIONNAIRE, {
        relative: 'path',
      });
    }

    if (breadcrumbKey === PATIENT_PATHS.PEDIGREE) {
      return navigate('../' + PATIENT_PATHS.PEDIGREE, { relative: 'path' });
    }

    if (breadcrumbKey === PATIENT_PATHS.MESSAGES) {
      return navigate('../' + PATIENT_PATHS.MESSAGES, { relative: 'path' });
    }

    return navigate('..');
  };

  const breadcrumbs = useMemo(() => {
    let path: BreadcrumbItem<ItemKey>[] = [
      {
        key: 'home',
        label: t('breadcrumbs.home.title'),
        icon: faHome,
      },
    ];

    switch (activeItem) {
      case PATIENT_PATHS.QUESTIONNAIRE:
        path.push({
          key: 'questionnaire',
          label: t('breadcrumbs.personal-info.title'),
          icon: null,
        });
        break;
      case PATIENT_PATHS.PEDIGREE:
        path.push({
          key: 'pedigree',
          label: t('breadcrumbs.pedigree.title'),
          icon: null,
        });
        break;
      case PATIENT_PATHS.MESSAGES:
        path.push({
          key: 'messages',
          label: t('breadcrumbs.all-messages.title'),
          icon: null,
        });
        break;
    }

    return path;
  }, [activeItem, t]);

  return (
    <>
      <Container>
        <Stack pt={2} pb={3}>
          <Breadcrumb
            path={breadcrumbs}
            handleLinkClick={handleLinkClick}
            activeItem={activeItem}
          />
        </Stack>
      </Container>
      {props.children}
    </>
  );
}
