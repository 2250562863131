import { useStaffPatientService } from 'api/patient/staff-patient-service';
import { PedigreeCase } from 'api/pedigreeCase/staff-pedigree-case-service';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { CollapsibleSection } from '../common/CollapsibleSection';
import { EligibilityResultsContent } from './EligibilityResultsContent';
import { InfoBox } from './InfoBox';
import { NextSteps } from './NextSteps';

export const EligibilityOfGeneticTesting = ({
  patientId,
  pedigreeCase,
}: {
  patientId: string;
  pedigreeCase?: PedigreeCase;
}) => {
  const patientService = useStaffPatientService();

  const { t } = useTranslation(['iPedigree', 'common']);

  const hasPedigree = pedigreeCase?.status === 'pedigreeProvided';

  const { data: eligibilityResults, isLoading } = useQuery(
    ['eligibilityResults', patientId],
    () => patientService.getEligibilityResults(patientId),
    {
      retry(failureCount, error: AxiosError) {
        if (error.response?.status === 404) {
          return false;
        }
        return failureCount <= 3;
      },
      refetchOnWindowFocus: false,
    },
  );

  const geneticTestEligibilityAssessmentResults =
    eligibilityResults?.geneticTestEligibilityAssessmentResults ?? [];

  const hasEligibleOrPotentiallyEligible =
    geneticTestEligibilityAssessmentResults.some(
      (criteria) =>
        criteria.eligibilityStatus === 'ELIGIBLE' ||
        criteria.eligibilityStatus === 'POTENTIALLY_ELIGIBLE',
    );

  const hasUnconfirmedDiagnosis = geneticTestEligibilityAssessmentResults.some(
    (criteria) =>
      criteria.eligibilityStatus === 'POTENTIALLY_ELIGIBLE' ||
      criteria.eligibilityStatus === 'NOT_ELIGIBLE',
  );

  const hasConfirmedDiagnosis = geneticTestEligibilityAssessmentResults.some(
    (criteria) => criteria.eligibilityStatus === 'ELIGIBLE',
  );

  return (
    <CollapsibleSection
      title={t('eligibility-genetic-testing.title')}
      isLoading={isLoading}
    >
      <>
        {hasEligibleOrPotentiallyEligible && <InfoBox />}
        {geneticTestEligibilityAssessmentResults?.map((result, index) => (
          <EligibilityResultsContent
            key={index}
            criteriaType={result.criteriaType}
            eligibilityStatus={result.eligibilityStatus}
            satisfiedCriteria={result.satisfiedCriteria}
            footnotes={result.footnotes}
            hasPedigree={hasPedigree}
            hasEligibleOrPotentiallyEligible={hasEligibleOrPotentiallyEligible}
            hiddenUnconfirmedDiagnosis={
              hasConfirmedDiagnosis && hasUnconfirmedDiagnosis
            }
          />
        ))}
        {hasUnconfirmedDiagnosis && (
          <NextSteps patientId={patientId} hasPedigree={hasPedigree} />
        )}
      </>
    </CollapsibleSection>
  );
};
