import { Box } from '@mui/material';
import { ConsentManagementServiceProvider } from 'api/consentManagement/ConsentManagementServiceProvider';
import PdfServiceProvider from 'api/pdf/PdfServiceProvider';
import Layout from 'components/layout';
import {
  useStaffAuth,
  withStaffAuthProvider,
} from 'features/auth/staff/StaffAuthProvider';
import AddRelatives from 'pages/consentManagement/AddRelatives';
import Dashboard from 'pages/consentManagement/Dashboard';
import NewPatient from 'pages/consentManagement/NewPatient';
import SendReminder from 'pages/consentManagement/SendReminder';
import { useEffect } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

function ConsentManagementRouter() {
  const { logout, accessToken } = useStaffAuth();
  const { i18n } = useTranslation(['common']);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };
  const handleLogoutClick = () => {
    logout();
  };

  useEffect(() => {
    const currentLanguage = i18n.language === 'se' ? 'SE' : 'EN';
    if (currentLanguage === 'EN') {
      i18n.changeLanguage('se');
    }
  }, [accessToken, i18n]);

  return (
    <I18nextProvider i18n={i18n}>
      <ConsentManagementServiceProvider
        accessToken={accessToken}
        logout={logout}
      >
        <PdfServiceProvider accessToken={accessToken} logout={logout}>
          <Routes>
            <Route
              element={
                <Layout
                  signOut={handleLogoutClick}
                  onLogoClick={handleLogoClick}
                >
                  <Box sx={{ pb: 2 }}>
                    <Outlet />
                  </Box>
                </Layout>
              }
            >
              <Route path="/" element={<Dashboard />} />
              <Route path="/new-patient" element={<NewPatient />} />
              <Route
                path="/:consentRequestId/add-relative"
                element={<AddRelatives />}
              />
              <Route
                path="/:consentRequestId/send-reminder"
                element={<SendReminder />}
              />
            </Route>
          </Routes>
        </PdfServiceProvider>
      </ConsentManagementServiceProvider>
    </I18nextProvider>
  );
}

export default withStaffAuthProvider(ConsentManagementRouter);
