import { Stack } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { DynamicForm } from 'common/features/dynamicForm';
import { CustomizableFormData } from 'common/features/pedigree/common/types';
import { useMedicalForm } from 'common/hooks/useMedicalForm';
import Dialog from 'components/common/Dialog';
import Drawer from 'components/common/drawer/Drawer';
import { DrawerFooterActions } from 'components/common/drawer/DrawerFooterActions';
import isEqual from 'lodash/isEqual';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SectionWithActionButton } from './common/SectionWithActionButton';

type InitialFormDataState = {
  geneticTesting: {
    geneticTests: {
      testPanelGenes: string[];
    }[];
  };
};

interface GeneticTestsFormProps {
  pedigreeCaseId?: string;
  patientName?: string;
  patientId: string;
  disabled: boolean;
}

export const GeneticTestsForm = (props: GeneticTestsFormProps) => {
  const { patientName, patientId, pedigreeCaseId, disabled } = props;

  const queryClient = useQueryClient();
  const service = useStaffPedigreeCaseService();
  const { i18n, t } = useTranslation(['iPedigree', 'common']);

  const [openForm, setOpenForm] = useState<boolean>(false);

  const submitMutation = useMutation(
    (data: CustomizableFormData) => {
      return service?.submitPatientForm(pedigreeCaseId || '', data);
    },
    {
      onSuccess: () => {
        handleOnSuccessSubmit();
      },
      onError: () => {
        openSubmitFailureDialog();
      },
    },
  );

  const handleCloseForm = useCallback(() => {
    setOpenForm(false);
    queryClient.invalidateQueries(['geneticRiskAssessmentResults', patientId]);
  }, [patientId, queryClient]);

  const submitForm = ({ formData }: { formData?: CustomizableFormData }) => {
    if (!formData) return;
    const validFormData = isEqual(initialFormDataEmptyState, formData)
      ? {
          geneticTesting: {},
        }
      : formData;
    submitMutation.mutate(validFormData);
  };

  const {
    formRef,
    isDirty,
    uiSchema,
    dialogProps,
    changesSaved,
    setFormData,
    handleOnClickOutside,
    handleOnSuccessSubmit,
    openSubmitFailureDialog,
    onClickClose,
    onClickSave,
  } = useMedicalForm({
    isLoading: submitMutation.isLoading,
    handleCloseFormCalback: handleCloseForm,
  });

  const { data: patientForm } = useQuery(
    ['geneticTestsForm', i18n.language],
    () =>
      service.getPatientForm(pedigreeCaseId || '', {
        categories: 'geneticTesting',
        context: 'riskAssessment',
      }),
    {
      enabled: !!openForm && !isDirty,
    },
  );

  return (
    <>
      <SectionWithActionButton
        title={t('risk-assessment.genetic-risk-assessment.title')}
        buttonText={t('risk-assessment.genetic-test.add-or-select.buttonText')}
        onButtonClick={() => setOpenForm(true)}
        disabled={disabled}
      />
      <Drawer
        isOpen={openForm}
        title={patientName || ''}
        onClose={onClickClose}
        onClickOutside={handleOnClickOutside}
        hideBackdrop={false}
      >
        <Stack pb={9.5}>
          {patientForm && (
            <DynamicForm
              schema={patientForm.jsonSchema}
              uiSchema={{ ...uiSchema, ...patientForm.uiSchema }}
              formData={patientForm.formData}
              formContext={{ narrowForm: true }}
              onChange={({ formData }) => setFormData(formData)}
              onSubmit={submitForm}
              ref={formRef}
            />
          )}
        </Stack>
        <DrawerFooterActions
          onSave={onClickSave}
          onCancel={onClickClose}
          isSaveDisabled={!isDirty}
          hasSavedChanges={changesSaved}
        />
      </Drawer>
      <Dialog {...dialogProps} />
    </>
  );
};

const initialFormDataEmptyState: InitialFormDataState = {
  geneticTesting: {
    geneticTests: [
      {
        testPanelGenes: [],
      },
    ],
  },
};
