import { LoadingButton } from '@mui/lab';
import { Button, DialogContentText } from '@mui/material';
import { UiSchema } from '@rjsf/utils';
import { CustomizableFormData } from 'common/features/pedigree/common/types';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type OpenDialogState =
  | { cancelEdit: true; failedSubmit: false; unsavedChanges: false }
  | { cancelEdit: false; failedSubmit: true; unsavedChanges: false }
  | { cancelEdit: false; failedSubmit: false; unsavedChanges: true }
  | { cancelEdit: false; failedSubmit: false; unsavedChanges: false };

interface UseMedicalForm {
  isLoading?: boolean;
  nodeName?: string;
  dialogNameOnClickClose?: 'cancelEdit' | 'unsavedChanges';
  handleCloseFormCalback: () => void;
}

export const useMedicalForm = (props: UseMedicalForm) => {
  const {
    isLoading,
    nodeName,
    dialogNameOnClickClose,
    handleCloseFormCalback,
  } = props;

  const formRef = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation('common');

  const [changesSaved, setChangesSaved] = useState(false);
  const [formData, setFormData] = useState<CustomizableFormData | null>(null);

  const [openDialog, setOpenDialog] = useState<OpenDialogState>({
    cancelEdit: false,
    unsavedChanges: false,
    failedSubmit: false,
  });

  const isDirty = useMemo(() => {
    const initialFormData = formRef.current?.getDefaultFormState();
    if (Object.keys(formData || {}).length >= 1) {
      return !isEqual(initialFormData, formData);
    }
    return false;
  }, [formData]);

  const dialogIsOpen =
    openDialog.cancelEdit ||
    openDialog.failedSubmit ||
    openDialog.unsavedChanges;

  const openSubmitFailureDialog = () => {
    setOpenDialog({
      cancelEdit: false,
      unsavedChanges: false,
      failedSubmit: true,
    });
  };

  const openCancelEditDialog = () => {
    setOpenDialog({
      cancelEdit: true,
      unsavedChanges: false,
      failedSubmit: false,
    });
  };

  const openUnsavedChangesDialog = () => {
    setOpenDialog({
      cancelEdit: false,
      unsavedChanges: true,
      failedSubmit: false,
    });
  };

  const closeDialog = () => {
    setOpenDialog({
      cancelEdit: false,
      unsavedChanges: false,
      failedSubmit: false,
    });
  };

  const onClickSave = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  const handleOnSuccessSubmit = useCallback(() => {
    formRef?.current?.setUpdateFormState(true);
    setFormData(null);
    setChangesSaved(true);
  }, []);

  const handleCloseForm = useCallback(() => {
    if (dialogIsOpen) closeDialog();
    setFormData(null);
    handleCloseFormCalback();
  }, [dialogIsOpen, handleCloseFormCalback]);

  const onClickClose = useCallback(() => {
    if (isDirty) {
      const opendDialog =
        dialogNameOnClickClose === 'unsavedChanges'
          ? openUnsavedChangesDialog
          : openCancelEditDialog;

      return opendDialog();
    }

    return handleCloseForm();
  }, [dialogNameOnClickClose, handleCloseForm, isDirty]);

  const handleOnClickOutside = () => {
    if (dialogIsOpen) return;
    return onClickClose();
  };

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      props: {
        disabled: true,
      },
      norender: true,
    },
    'ui:accordionsGap': 0.5,
  };

  const cancelEditDialogProps = useMemo(() => {
    return {
      open: openDialog.cancelEdit,
      title: t('dialog.form-edit-cancel.title'),
      content: t('dialog.form-edit-cancel.content'),
      actions: [
        <Button
          key="close-button-dialog"
          variant="outlined"
          onClick={handleCloseForm}
        >
          {t('dialog.form-edit-cancel.buttonCancel')}
        </Button>,
        <Button
          key="confirm-button-dialog"
          variant="contained"
          onClick={() =>
            setTimeout(() => {
              closeDialog();
            }, 50)
          }
        >
          {t('dialog.form-edit-cancel.buttonConfirm')}
        </Button>,
      ],
    };
  }, [handleCloseForm, openDialog.cancelEdit, t]);

  const unsavedChangesDialogProps = useMemo(() => {
    return {
      open: openDialog.unsavedChanges,
      title: t('dialog.form-edit-unsaved.title'),
      content: t('dialog.form-edit-unsaved.content'),
      actions: [
        <Button
          key="close-unsaved-dialog"
          variant="outlined"
          onClick={handleCloseForm}
          disabled={isLoading}
        >
          {t('dialog.form-edit-unsaved.close-button-text')}
        </Button>,
        <LoadingButton
          key="confirm-unsaved-dialog"
          variant="contained"
          onClick={onClickSave}
          loading={isLoading}
        >
          {t('dialog.form-edit-unsaved.confirm-button-text')}
        </LoadingButton>,
      ],
    };
  }, [handleCloseForm, isLoading, openDialog.unsavedChanges, t]);

  const failedFormSubmitDialogProps = useMemo(() => {
    return {
      open: openDialog.failedSubmit,
      title: t('dialog.form-failed-submit.title'),
      element: (
        <DialogContentText
          id="patient-form-dialog"
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {t(`dialog.form-failed-submit.content`, {
            relative: nodeName,
          })}
        </DialogContentText>
      ),
      actions: [
        <Button
          key="close-submit-dialog"
          variant="outlined"
          onClick={handleCloseForm}
          disabled={isLoading}
        >
          {t('dialog.form-failed-submit.buttonCancel')}
        </Button>,
        <LoadingButton
          key="confirm-submit-dialog"
          variant="contained"
          onClick={onClickSave}
          loading={isLoading}
        >
          {t('dialog.form-failed-submit.buttonConfirm')}
        </LoadingButton>,
      ],
    };
  }, [handleCloseForm, isLoading, nodeName, openDialog.failedSubmit, t]);

  useEffect(() => {
    if (!!changesSaved && isDirty) {
      setChangesSaved(false);
    }
  }, [changesSaved, isDirty]);

  const dialogProps = openDialog.failedSubmit
    ? failedFormSubmitDialogProps
    : openDialog.unsavedChanges
    ? unsavedChangesDialogProps
    : cancelEditDialogProps;

  return {
    formRef,
    isDirty,
    changesSaved,
    uiSchema,
    dialogProps,
    setFormData,
    openSubmitFailureDialog,
    openCancelEditDialog,
    handleOnSuccessSubmit,
    handleOnClickOutside,
    onClickClose,
    onClickSave,
  };
};
