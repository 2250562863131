import { RJSFSchema, UiSchema } from '@rjsf/utils/lib/types';
import { AxiosResponse } from 'axios';
import { GetPedigreePdfQueryParamsWithoutAnonymise } from 'common/features/pedigreeCase/pedigreePrintExport/hooks/usePedigreePrintExport';
import {
  CreateChildNodeParams,
  CreateHalfSiblingNodeParams,
  CreateParentsNodeParams,
  CreatePartnerNodeParams,
  CreateSiblingNodeParams,
  CreateTwinNodeParams,
} from './generate-create-node-params';

export enum PedigreeNodeSex {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
}

export type SharedParentRelationshipType = 'mother' | 'father';

export type Diagnose = {
  diseaseName: string;
  ageOfOnset: string;
};

export type TwinRelation = {
  id: string;
  type: 'identical' | 'fraternal';
};

export type GeneticTestResult = 'positive' | 'negative' | 'unknown';

export type GeneticTest = {
  result: GeneticTestResult;
  variants: string[];
};

export type PedigreeNode = {
  id: string;
  fatherId?: string;
  motherId?: string;
  partnerIds: string[];
  twinRelation?: TwinRelation;
  isIndex: boolean;
  sex: PedigreeNodeSex;
  hasDiagnoseHistory?: boolean;
  diagnoses: Diagnose[];
  geneticTest?: GeneticTest;
  deceased?: boolean;
  ageAtDeath?: number;
  name?: string;
  yearOfBirth?: number;
  created?: boolean;
  updated?: boolean;
  relationshipDegreeToIndex?: number;
};

export type Pedigree = {
  id: string;
  nodes: PedigreeNode[];
  receivedAt?: string;
};

export type CreateNodeParams =
  | CreateParentsNodeParams
  | CreatePartnerNodeParams
  | CreateSiblingNodeParams
  | CreateChildNodeParams
  | CreateHalfSiblingNodeParams
  | CreateTwinNodeParams;

export type ParentsIds = {
  fatherId?: number;
  motherId?: number;
};

export type CustomizableFormData = {
  [key: string]: string | number | boolean | CustomizableFormData;
};

export type QuestionnaireForm = {
  jsonSchema: RJSFSchema;
  uiSchema: UiSchema;
  formData?: CustomizableFormData;
};

export type Question = {
  title: string;
  answer?: string;
};

export type QuestionsGroup = {
  title: string;
  questions: Question[];
};

export type Questionnaire = {
  groups: QuestionsGroup[];
};

export type NodeSexNotChangeableReason =
  | 'NODE_IS_INDEX'
  | 'NODE_HAS_PARTNER'
  | 'NODE_IS_DIRECT_ASCENDANT_OF_INDEX'
  | 'NODE_HAS_IDENTICAL_TWIN_WITH_PARTNER'
  | 'NODE_IS_IDENTICAL_TWIN_TO_INDEX'
  | 'NODE_IS_IDENTICAL_TWIN_TO_DIRECT_ASCENDANT_OF_INDEX'
  | 'SINGLE_PARENT_NODE_CANNOT_HAVE_UNKNOWN_SEX';

export type NodeNotDeletableReason =
  | 'NODE_IS_INDEX'
  | 'NODE_IS_INDEX_PARENT'
  | 'NODE_IS_SINGLE_CONNECTION_IN_PEDIGREE';

export type DeletablePedigreResponse = {
  canDelete: boolean;
  reason: NodeNotDeletableReason;
};

export type DeletePedigreeNode = (
  pedigreeId: string,
  nodeId: number,
) => Promise<Pedigree>;

export type DeletablePedigreeNode = (
  pedigreeId: string,
  nodeId: number,
) => Promise<DeletablePedigreResponse>;

export type AddNewPedigreeNode = (
  pedigreeId: string,
  nodeParams: CreateNodeParams,
) => Promise<Pedigree>;

export type CanChangeNodeSexResponse =
  | {
      canChange: false;
      reason: NodeSexNotChangeableReason;
    }
  | { canChange: true };

export type canChangeSex = (
  pedigreeId: string,
  nodeId: number,
  queryParams: {
    newSex: PedigreeNodeSex;
  },
) => Promise<CanChangeNodeSexResponse>;

export type UpdateNodeSex = (
  pedigreeId: string,
  nodeId: number,
  sex: PedigreeNodeSex,
) => Promise<Pedigree>;

export type NodePersonalInformation = {
  personalNumber: string;
  dateOfBirth: string;
  age: string;
};

export type UpdatePedigreeNodeParents = (
  pedigreeId: string,
  pedigreeNodeId: string,
  parentsIds: ParentsIds,
) => Promise<Pedigree>;

export type PedigreeService = {
  addNewPedigreeNode: AddNewPedigreeNode;
  updatePedigreeNodeParents: UpdatePedigreeNodeParents;
  getPedigree: () => Promise<Pedigree>;
  onPedigreePrintClick?: (
    params: GetPedigreePdfQueryParamsWithoutAnonymise,
  ) => void;
  onPedigreeDownloadClick?: (
    params: GetPedigreePdfQueryParamsWithoutAnonymise,
  ) => void;
  canChangeSex: canChangeSex;
  updateNodeSex: UpdateNodeSex;
  deletePedigreeNode: DeletePedigreeNode;
  deletablePedigreeNode: DeletablePedigreeNode;
  putRelativeForm: (
    pedigreeId: string,
    pedigreeNodeId: string,
    formData: CustomizableFormData,
  ) => Promise<AxiosResponse>;
  putPatientForm: (formData: CustomizableFormData) => Promise<AxiosResponse>;
  getRelativeForm: (
    pedigreeId: string,
    pedigreeNodeId: string,
  ) => Promise<QuestionnaireForm>;
  getPatientForm: () => Promise<QuestionnaireForm>;
  sendRelativesWithCancerHistory?: (
    pedigreeId: string,
    nodeList: string[],
  ) => Promise<AxiosResponse>;
};
