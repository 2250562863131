export const PATIENT_PATHS = {
  HOME: '',
  QUESTIONNAIRE: 'questionnaire',
  PEDIGREE: 'pedigree',
  SAVE_PROGRESS: 'saved',
};

function MyFamilyTreePatientRouter() {
  const fullUrl = window.location.href;
  const newUrl = fullUrl.replace('myfamilytree', 'patient');
  window.location.href = newUrl;
  return null;
}

export default MyFamilyTreePatientRouter;
