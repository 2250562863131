import { WidgetProps } from '@rjsf/utils';
import { RadioInput } from 'components/inputs';
import { useMemo, useState } from 'react';
import { ReadonlyView } from '../views/ReadonlyView';

export const RadioWidget = (props: WidgetProps) => {
  const [value, setValue] = useState('');
  const disabledLabel = props.uiSchema?.['ui:options']?.label === false;

  const options = props.options?.enumOptions || [];
  const label = disabledLabel ? '' : props.label;
  const labelInfo = (props.uiSchema?.['ui:tooltip'] as string) || undefined;
  const disabled = props.uiSchema?.['ui:disabled'] || false;
  const rowDirection = props.uiSchema?.['ui:direction'] !== 'column';

  const isBoolean = props.schema.type === 'boolean';

  const handleOnChange = (value: string) => {
    const booleanValue = value === 'true';
    const newValue = isBoolean ? booleanValue : value;
    setValue(value);
    props.onChange(newValue);
  };

  const newValue = useMemo(() => {
    if (!!props.value && props.value !== value) {
      return props.value;
    } else {
      return value;
    }
  }, [props.value, value]);

  if (props.readonly) {
    const value =
      options.find((option) => option.value === newValue)?.label || newValue;
    return (
      <ReadonlyView title={label} value={value} uiSchema={props.uiSchema} />
    );
  }

  return (
    <RadioInput
      id={props.id}
      value={newValue}
      label={label}
      labelInfo={labelInfo}
      rowDirection={rowDirection}
      radioProps={rowDirection ? { sx: { marginRight: 6 } } : {}}
      required={props.required}
      onChange={(e) => handleOnChange(e.target.value)}
      options={options}
      disabled={disabled}
    />
  );
};
