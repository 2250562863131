import { Container, Link, Stack, Typography } from '@mui/material';
import completedImage from 'assets/images/consent-completed-check.png';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

export const PedigreeCaseConfirmation = () => {
  const { t } = useTranslation(['patientPortal']);
  const location = useLocation();

  const { patientId } = useParams<{
    patientId: string;
  }>();

  const hasUnfinishedTasks = location.state?.hasUnfinishedTasks;

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      }}
    >
      <Stack alignItems="center" spacing={4}>
        <img src={completedImage} alt="completed checked" />
        <Typography variant="h1">
          {t(`pedigree-confirm.received.title`)}
        </Typography>
        <>
          <Typography textAlign="center">
            {t('pedigree-confirm.received.content1')}{' '}
            {hasUnfinishedTasks
              ? t('pedigree-confirm.received.content3')
              : t('pedigree-confirm.received.content2')}{' '}
            {hasUnfinishedTasks && (
              <Link
                variant="body1"
                href={`/${PATIENT_PATHS.PATIENT}/${patientId}`}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {t('pedigree-confirm.received.link')}
              </Link>
            )}
          </Typography>
        </>
      </Stack>
    </Container>
  );
};
