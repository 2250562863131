import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { Link, Stack, Typography } from '@mui/material';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Section } from '../common/Section';

const INSTRUCTION_URL = 'https://arkus.ai/myfamilytree-guide/';

export const FamilyTreeSection = ({
  hasPedigree,
}: {
  hasPedigree: boolean;
}) => {
  const { t } = useTranslation('patientPortal');
  const navigate = useNavigate();

  const onClickButton = () => {
    return navigate(PATIENT_PATHS.PEDIGREE);
  };

  const title = t('home-family-tree-section.title');
  const chipLabel = hasPedigree ? t('home-section.not-submitted') : null;
  const buttonText = hasPedigree
    ? t('home-section.edit')
    : t('home-family-tree-section.button-text');

  const content1 = hasPedigree
    ? t('home-family-tree-section.edit-content')
    : t('home-family-tree-section.create-content');

  return (
    <Stack gap={2}>
      <Typography variant="h3">{t('home-section.required')}</Typography>
      <Section
        title={title}
        titleIcon={faSitemap}
        chipLabel={chipLabel}
        buttonText={buttonText}
        buttonVariant={hasPedigree ? 'outlined' : 'contained'}
        onClickButton={onClickButton}
      >
        <Typography variant="body1">
          {content1}{' '}
          <Link variant="body1" href={INSTRUCTION_URL} target="_blank">
            {t('home-family-tree-section.link')}
          </Link>{' '}
          {t('home-family-tree-section.content2')}
        </Typography>
      </Section>
    </Stack>
  );
};
