import { RJSFValidationError } from '@rjsf/utils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

function useCustomTransformErrors() {
  const { t } = useTranslation('dynamicForm');

  const getStandardErrorMessage = useCallback(
    (error: RJSFValidationError) => {
      switch (error.name) {
        case 'required':
          return t('input-validation.array.min');
        case 'minLength':
          return t('input-validation.array.min');
        case 'pattern':
          return t('input-validation.string.pattern.base');
        case 'minimum':
          return t('input-validation.number.value.minimum', {
            value: error.params.limit,
          });
        case 'maximum':
        case 'maximumAge':
        case 'maximumAgeRange':
          return t('input-validation.number.value.maximum', {
            value: error.params.limit,
          });
        case 'must match format "email"':
          return t('input-validation.string.pattern.email');
        case 'enum':
          if (error?.params?.allowedValues?.length < 0) {
            return t('input-validation.array.min');
          }
          return undefined;
        case 'oneOf':
        case 'const':
          return undefined;
        default:
          return error.message;
      }
    },
    [t],
  );

  const getCustomErrorMessage = useCallback(
    (error: RJSFValidationError, name: string) => {
      if (name === 'ageAtDeath') {
        return t('input-validation.number.age-at-death.value.maximum', {
          value: error.params.limit,
        });
      }
    },
    [t],
  );

  const transformErrors = useCallback(
    (errors: RJSFValidationError[]) => {
      const uniqueErrors = errors.filter(
        (error, index, self) =>
          index === self.findIndex((t) => t.property === error.property),
      );
      return uniqueErrors.map((error) => {
        let errorMessage = getStandardErrorMessage(error);

        if (error?.params?.allowedValues?.length > 0) {
          errorMessage = undefined;
        }

        if (error?.property?.includes('ageAtDeath')) {
          errorMessage = getCustomErrorMessage(error, 'ageAtDeath');
        }

        error.message = errorMessage;
        return error;
      });
    },
    [getCustomErrorMessage, getStandardErrorMessage],
  );

  return {
    transformErrors,
  };
}

export default useCustomTransformErrors;
