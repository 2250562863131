import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from '../translations/en/all.json';
import commonEn from '../translations/en/common.json';
import diagnosisFormEn from '../translations/en/diagnosisForm.json';
import dynamicFormEn from '../translations/en/dynamicForm.json';
import geneticTestFormEn from '../translations/en/geneticTestForm.json';
import patientPortalEn from '../translations/en/patientPortal.json';
import pedigreeEn from '../translations/en/pedigree.json';
import pedigreePrintExportEn from '../translations/en/pedigreePrintExport.json';
import relationshipEn from '../translations/en/relationship.json';
import se from '../translations/se/all.json';
import commonSe from '../translations/se/common.json';
import diagnosisFormSe from '../translations/se/diagnosisForm.json';
import dynamicFormSe from '../translations/se/dynamicForm.json';
import geneticTestFormSe from '../translations/se/geneticTestForm.json';
import patientPortalSe from '../translations/se/patientPortal.json';
import pedigreeSe from '../translations/se/pedigree.json';
import pedigreePrintExportSe from '../translations/se/pedigreePrintExport.json';
import relationshipSe from '../translations/se/relationship.json';

const patientPortalI18nInstance = i18n.createInstance();

patientPortalI18nInstance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        all: en,
        pedigreePrintExport: pedigreePrintExportEn,
        relationship: relationshipEn,
        dynamicForm: dynamicFormEn,
        common: commonEn,
        pedigree: pedigreeEn,
        diagnosisForm: diagnosisFormEn,
        geneticTestForm: geneticTestFormEn,
        patientPortal: patientPortalEn,
      },
      se: {
        all: se,
        pedigreePrintExport: pedigreePrintExportSe,
        relationship: relationshipSe,
        dynamicForm: dynamicFormSe,
        common: commonSe,
        pedigree: pedigreeSe,
        diagnosisForm: diagnosisFormSe,
        geneticTestForm: geneticTestFormSe,
        patientPortal: patientPortalSe,
      },
    },
    fallbackLng: 'se',
    detection: {
      order: ['cookie', 'localStorage'],
      caches: ['cookie', 'localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

patientPortalI18nInstance.services.formatter?.add(
  'capitalize',
  (value: string) => value.charAt(0).toUpperCase() + value.slice(1),
);

patientPortalI18nInstance.services.formatter?.add(
  'capitalizeWords',
  (value: string) =>
    value
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
);

export default patientPortalI18nInstance;
