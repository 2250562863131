import { Typography } from '@mui/material';
import { FieldTemplateProps } from '@rjsf/utils';
import { createContext } from 'react';

export const FieldTemplateContext = createContext<{
  errorWhiteSpace: string;
}>({
  errorWhiteSpace: 'normal',
});

export const FieldTemplate = (props: FieldTemplateProps) => {
  const { help, description, errors, children } = props;
  const helperTextWhiteSpace =
    props.uiSchema?.['ui:helperTextWhiteSpace'] ?? 'normal';

  if (props.readonly) {
    return <>{children}</>;
  }

  return (
    <FieldTemplateContext.Provider
      value={{
        errorWhiteSpace: helperTextWhiteSpace,
      }}
    >
      {children}
      <Typography variant="caption" color="textSecondary">
        {description}
      </Typography>
      <Typography
        variant="caption"
        color="textSecondary"
        sx={{
          whiteSpace: helperTextWhiteSpace,
        }}
      >
        {help}
      </Typography>
      {errors}
    </FieldTemplateContext.Provider>
  );
};
