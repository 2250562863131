import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { Pedigree } from 'api/patient/patient-service';
import { usePedigreeLayout } from 'common/features/pedigree/common/hooks/usePedigreeLayout';
import usePedigreeNodeRelationshipLabels from 'common/features/pedigree/common/hooks/usePedigreeNodeRelationshipLabels';
import { PedigreeNode } from 'common/features/pedigree/common/types';
import { PartnerConnections } from 'common/features/pedigree/common/views/pedigreeRenderer/PartnerConnections';
import PedigreeEdges from 'common/features/pedigree/common/views/pedigreeRenderer/PedigreeEdges';
import usePedigreeResponsiveActions from 'common/hooks/usePedigreeResponsiveActions';
import { LoadingBackdrop } from 'components/common';
import React, { useEffect, useState } from 'react';
import { Html } from 'react-konva-utils';
import { usePedigreeMutation } from '../hooks/usePedigreeMutation';
import { PedigreeOnboarding } from '../onboarding';
import PedigreeMemberNode from '../pedigreeNode/PedigreeMemberNode';
import { RelativeNodeForm } from '../relativeNodeForm';
import PedigreeControlButtons from './PedigreeControlButtons';
import ResponsiveStage from './ResponsiveStage';

interface PedigreeStageProps {
  pedigree: Pedigree;
}

const nodeSize = {
  width: 160,
  height: 152,
};

const PedigreeStage = (props: PedigreeStageProps) => {
  const theme = useTheme();
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [relativeLabel, setRelativeLabel] = useState<string>('');
  const [allowNodeClick, setAllowNodeClick] = useState<boolean>(true);
  const [showNodeAction, setShowNodeAction] = useState<boolean>(true);
  const [drawerState, setDrawerState] = useState<
    'onboarding' | 'nodeForm' | null
  >(null);

  const { dialogs, isLoading, handleNodeMenuOptionClick } = usePedigreeMutation(
    {
      pedigree: props.pedigree,
    },
  );

  const { getLocalizedLabelOf, getLabelKeyOf } =
    usePedigreeNodeRelationshipLabels(props.pedigree.nodes);

  const { layout, layoutSize } = usePedigreeLayout({
    pedigreeNodes: props.pedigree.nodes,
    pedigreeType: 'patient',
  });

  const stageRef = React.useRef<any>(null);

  const { handleZoomIn, handleZoomOut, handleZoomReset } =
    usePedigreeResponsiveActions({
      nodeSize,
      stageRef,
    });

  const onNodeClick = (
    isSelected: boolean,
    pedigreeNode: PedigreeNode,
    relationshipLabel: string,
  ) => {
    setRelativeLabel(relationshipLabel);
    setSelectedNodes((prevSelectedNodes) =>
      isSelected
        ? [...prevSelectedNodes, pedigreeNode.id]
        : prevSelectedNodes.filter((id) => id !== pedigreeNode.id),
    );
  };

  const drawerDefaultWidth = 465;
  const drawerIsOpen =
    drawerState === 'onboarding' ||
    (drawerState === 'nodeForm' && !!selectedNodes.length);

  const openOnboardingDrawer = () => {
    setSelectedNodes([]);
    setDrawerState('onboarding');
  };

  useEffect(() => {
    const relativeDisease = localStorage.getItem('relativeDisease');
    if (relativeDisease) {
      return openOnboardingDrawer();
    }
    return setDrawerState('nodeForm');
  }, []);

  return (
    <>
      <PedigreeControlButtons
        direction="column"
        right={drawerIsOpen ? drawerDefaultWidth + 16 : 16}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleZoomReset={handleZoomReset}
        onDrawerOpen={openOnboardingDrawer}
      />
      {drawerState === 'onboarding' && (
        <PedigreeOnboarding
          pedigreeId={props.pedigree.id}
          nodes={props.pedigree.nodes}
          selectedNodes={selectedNodes}
          drawerWidth={drawerDefaultWidth}
          setSelectedNodes={setSelectedNodes}
          setAllowNodeClick={setAllowNodeClick}
          setDrawerState={setDrawerState}
          setShowNodeAction={setShowNodeAction}
        />
      )}
      {drawerState === 'nodeForm' && (
        <RelativeNodeForm
          pedigreeId={props.pedigree.id}
          relativeLabel={relativeLabel}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          drawerWidth={drawerDefaultWidth}
        />
      )}
      <ResponsiveStage stageRef={stageRef} layoutSize={layoutSize}>
        {props.pedigree.nodes.map((pedigreeNode) => {
          const { x, y } = layout?.nodes[pedigreeNode.id]?.pos || {
            x: 0,
            y: 0,
          };
          const relationshipKey = getLabelKeyOf(pedigreeNode.id);
          const showRelationshipLabel = relationshipKey !== 'relative';
          const relationshipLabel = getLocalizedLabelOf(pedigreeNode.id);
          return (
            <Html
              groupProps={{
                x: x,
                y: y,
                offsetX: nodeSize.width / 2,
                offsetY: nodeSize.height / 2,
              }}
              key={pedigreeNode.id}
            >
              <ThemeProvider theme={theme}>
                <PedigreeMemberNode
                  isIndex={pedigreeNode.isIndex}
                  sex={pedigreeNode.sex}
                  hasDiagnoseHistory={pedigreeNode.hasDiagnoseHistory || false}
                  relativeRelationLabel={
                    showRelationshipLabel ? relationshipLabel : ''
                  }
                  nodeSize={nodeSize}
                  name={pedigreeNode.name}
                  diagnoses={pedigreeNode.diagnoses || []}
                  deceased={pedigreeNode.deceased || false}
                  isSelected={selectedNodes.includes(pedigreeNode.id)}
                  allowNodeClick={allowNodeClick}
                  onNodeClick={(isSelected) =>
                    onNodeClick(isSelected, pedigreeNode, relationshipLabel)
                  }
                  {...(showNodeAction
                    ? {
                        handleMemberMenuOptionClick: (value) => {
                          handleNodeMenuOptionClick(value, pedigreeNode.id);
                        },
                      }
                    : undefined)}
                />
              </ThemeProvider>
            </Html>
          );
        })}
        <PedigreeEdges
          links={layout?.links}
          strokeOptions={{
            color: theme.palette.common.brand.darkBlue70,
            width: 1,
          }}
        />
        <PartnerConnections
          pedigreeLayoutNodes={layout?.nodes}
          color={theme.palette.common.brand.darkBlue}
        />
      </ResponsiveStage>
      {dialogs}
      <LoadingBackdrop open={isLoading} />
    </>
  );
};

export default PedigreeStage;
