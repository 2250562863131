import { Button, Container, Stack, Typography } from '@mui/material';
import { useBankidAuth } from 'features/auth/bankid/AuthProvider';
import { useTranslation } from 'react-i18next';

export function NotAuthorized() {
  const { t } = useTranslation(['patientPortal']);
  const { logout } = useBankidAuth();
  return (
    <Container maxWidth="xs" sx={{ height: '70vh' }}>
      <Stack
        direction="column"
        sx={{
          height: '100%',
        }}
        alignItems="center"
        justifyContent="center"
        gap={3}
      >
        <Typography variant="h1">{t('not-authorized.title')}</Typography>
        <Typography textAlign="center">
          {t('not-authorized.content')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={logout}
          fullWidth={false}
        >
          {t('not-authorized.button')}
        </Button>
      </Stack>
    </Container>
  );
}
