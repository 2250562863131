import { Stack } from '@mui/material';
import { usePatientService } from 'api/patient/patient-service';
import { AxiosError } from 'axios';
import { LoadingBackdrop } from 'components/common';
import InstructionalDialog from 'patientPortal/features/instructionalDialog/InstructionalDialog';
import { PersonalInfoForm } from 'patientPortal/features/patientForm/PersonalInfoForm';
import { Layout } from 'patientPortal/layouts/Layout';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

export function Questionnaire() {
  const service = usePatientService();
  const navigate = useNavigate();

  const { patientId } = useParams();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service?.getPedigreeCase(patientId || ''),
    {
      onSuccess: (data) => {
        const isCaseConfirmation =
          data?.status === 'pedigreeReceived' || data?.status === 'caseViewed';
        if (isCaseConfirmation) {
          return navigate('../' + PATIENT_PATHS.PEDIGREE_SUBMITTED, {
            relative: 'path',
          });
        }
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          return navigate('../404');
        }
        if (error.response?.status === 403) {
          return navigate('../');
        }
      },
    },
  );

  if (isLoading) return <LoadingBackdrop open={isLoading} />;

  if (!pedigreeCase) return null;

  return (
    <Stack position="relative" flexGrow={1}>
      <Layout>
        <PersonalInfoForm pedigreeCaseId={pedigreeCase?.id} />
      </Layout>
      <InstructionalDialog />
    </Stack>
  );
}
