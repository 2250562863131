import { Button, Stack, Typography } from '@mui/material';

export const SectionWithActionButton = ({
  onButtonClick,
  disabled,
  title,
  buttonText,
}: {
  onButtonClick: () => void;
  disabled: boolean;
  title: string;
  buttonText: string;
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h4">{title}</Typography>
      <Button
        variant="outlined"
        onClick={onButtonClick}
        disabled={disabled}
        sx={{
          maxWidth: 'fit-content',
        }}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};
