import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CircularLoading } from 'components/common/CircularLoading';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CollapsibleSection = ({
  title,
  isLoading,
  children,
}: {
  title: string;
  isLoading: boolean;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);
  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        border: `1px solid ${theme.palette.common.opacity.darkBlue[25]}`,
        borderRadius: '4px',
        '&:before': {
          height: 0,
        },
      }}
    >
      <AccordionSummary
        sx={{
          padding: theme.spacing(3),
          minHeight: '100%',
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
        expandIcon={
          <FontAwesomeIcon
            color={theme.palette.common.brand.darkBlue}
            icon={faCaretDown}
          />
        }
      >
        <Typography variant="h4">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: theme.spacing(3), paddingTop: 1.5 }}>
        <Stack gap={3}>
          {isLoading && (
            <Stack alignItems="center">
              <CircularLoading label={t('common:loading-label')} />
            </Stack>
          )}
          {!isLoading && children}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
