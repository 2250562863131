import { useFeature } from '@bucketco/react-sdk';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, List, Stack, Typography, useTheme } from '@mui/material';
import { useStaffPatientService } from 'api/patient/staff-patient-service';
import { FEATURE_FLAGS } from 'common/contexts/BucketProvider';
import { isoToShortDate } from 'common/utils';
import { ListItem } from 'components/common/ListItem';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const NextSteps = ({
  patientId,
  hasPedigree,
}: {
  patientId: string;
  hasPedigree: boolean;
}) => {
  const { i18n, t } = useTranslation(['iPedigree']);
  const patientService = useStaffPatientService();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { isEnabled } = useFeature(FEATURE_FLAGS.geneticTestApprovalManual);

  const { data: geneticTestingApproval, isLoading } = useQuery(
    ['geneticTestingApproval', patientId],
    () => patientService.getGeneticTestingApproval(patientId),
    { refetchOnWindowFocus: false },
  );

  const { mutate: approveGeneticTest } = useMutation(
    () => patientService.putGeneticTestingApproval(patientId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['geneticTestingApproval', patientId]),
    },
  );

  const handleApproveGeneticTest = () => {
    approveGeneticTest();
  };

  if (isLoading) return null;

  if (geneticTestingApproval?.approved) {
    const { approvedBy, approvedAt } = geneticTestingApproval;
    const localizedApprovedOn = isoToShortDate(approvedAt, i18n);

    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <FontAwesomeIcon
          icon={faCheckCircle}
          color={theme.palette.success.main}
          fontSize={20}
          style={{
            padding: 2,
          }}
        />
        <Typography variant="body2">
          {t('eligibility-genetic-testing.next-steps.genetic-test-approved', {
            approvedBy,
            approvedOn: localizedApprovedOn,
          })}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Typography variant="h5">
        {t('eligibility-genetic-testing.next-steps.title')}
      </Typography>
      <Stack
        gap={3}
        sx={{
          borderLeft: 1,
          borderColor: theme.palette.common.opacity.darkBlue[25],
          pl: 2,
        }}
      >
        <List sx={{ paddingLeft: 3, paddingY: 0 }}>
          {!hasPedigree && (
            <ListItem>
              <Typography variant="body1">
                {t(
                  'eligibility-genetic-testing.next-steps.create-pedigree.description',
                )}
              </Typography>
            </ListItem>
          )}
          <ListItem>
            <Typography variant="body1">
              {t(
                'eligibility-genetic-testing.next-steps.approve-genetic-test.description',
              )}
            </Typography>
          </ListItem>
        </List>
        <Button
          variant="outlined"
          onClick={handleApproveGeneticTest}
          disabled={!isEnabled}
          sx={{ maxWidth: 'fit-content' }}
        >
          {t(
            'eligibility-genetic-testing.next-steps.approve-genetic-test.buttonText',
          )}
        </Button>
      </Stack>
    </Stack>
  );
};
