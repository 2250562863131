import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Container, Stack, Typography } from '@mui/material';
import { usePatientService } from 'api/patient/patient-service';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Section } from '../common/Section';
import { SectionDivider } from '../common/SectionDivider';
import { ListOfPendingConsents } from './ListOfPendingConsents';
import { ListOfRespondedConsents } from './ListOfRespondedConsents';

export const ConsentsSection = ({
  setHasUnfinishedTasks,
}: {
  setHasUnfinishedTasks: (hasUnfinishedTasks: boolean) => void;
}) => {
  const service = usePatientService();
  const { patientId } = useParams();
  const { t } = useTranslation('patientPortal');

  const { data: consents, isLoading } = useQuery(
    ['consents', patientId],
    () => service?.getConsents(patientId || ''),
    {
      onSuccess: (data) => {
        setHasUnfinishedTasks(data.pendingConsents.length > 0);
      },
    },
  );

  const hasPendingConsents = !!consents?.pendingConsents.length;
  const hasRespondedConsents = !!consents?.respondedConsents.length;

  if (isLoading) return null;
  if (!consents) return null;
  if (!hasPendingConsents) return null;

  return (
    <>
      <Container maxWidth="sm">
        <Stack gap={2}>
          <Typography variant="h3">
            {t('home-consent-section.sup-title')}
          </Typography>
          <Section
            title={t('home-consent-section.title')}
            titleIcon={faUsers}
            chipLabel={null}
          >
            {hasPendingConsents && (
              <ListOfPendingConsents
                pendingConsents={consents.pendingConsents}
                hasRespondedConsents={hasRespondedConsents}
              />
            )}
            {hasRespondedConsents && (
              <ListOfRespondedConsents
                respondedConsents={consents.respondedConsents}
              />
            )}
          </Section>
        </Stack>
      </Container>
      <SectionDivider />
    </>
  );
};
