import {
  GetPedigreePdfQueryParams,
  useStaffPedigreeCaseService,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportFormat } from '../controllers/ExportDialogContentController';
import {
  DialogType,
  PrintExportDialogController,
} from '../controllers/PrintExportDialogController';

export function usePedigreePrintExport() {
  const { t } = useTranslation(['pedigreePrintExport']);

  const [pedigreeCaseId, setPedigreeCaseId] = useState<string>('');
  const [dialogType, setDialogType] = useState<DialogType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [exportFailedMessage, setExportFailedMessage] = useState<string | null>(
    null,
  );
  const [queryParams, setQueryParams] =
    useState<GetPedigreePdfQueryParamsWithoutAnonymise>({});

  const isDialogOpen = useMemo(() => dialogType !== null, [dialogType]);
  const service = useStaffPedigreeCaseService();

  const resetStates = useCallback(() => {
    setPedigreeCaseId('');
    setDialogType(null);
    setIsLoading(false);
    setExportFailedMessage(null);
  }, [setPedigreeCaseId, setDialogType, setIsLoading]);

  const onPrintClick = useCallback(
    (anonymized: boolean) => {
      setIsLoading(true);

      service
        .printPedigreePdf(pedigreeCaseId, {
          anonymise: anonymized,
          ...queryParams,
        })
        .then(() => {
          resetStates();
        });
    },
    [service, pedigreeCaseId, resetStates, queryParams],
  );

  const onExportClick = useCallback(
    (format: ExportFormat, anonymized: boolean) => {
      if (format === 'pdf') {
        setIsLoading(true);
        service
          .exportPedigreePdf(pedigreeCaseId, {
            anonymise: anonymized,
            ...queryParams,
          })
          .then(() => {
            resetStates();
          });
        return;
      } else if (format === 'canRisk') {
        setIsLoading(true);
        service
          .exportCanriskFile(pedigreeCaseId, anonymized)
          .then(() => {
            resetStates();
          })
          .catch(() => {
            setIsLoading(false);
            setExportFailedMessage(t('export-pedigree-failed.message'));
          });
      } else {
        console.error('Unsupported format:', format);
      }
    },
    [service, pedigreeCaseId, queryParams, resetStates, t],
  );

  return {
    onPedigreePrintClick: (
      pedigreeCaseId: string,
      queryParams?: GetPedigreePdfQueryParamsWithoutAnonymise,
    ) => {
      setPedigreeCaseId(pedigreeCaseId);
      setQueryParams(queryParams ?? {});
      setDialogType('print');
    },
    onPedigreeDownloadClick: (
      pedigreeCaseId: string,
      queryParams?: GetPedigreePdfQueryParamsWithoutAnonymise,
    ) => {
      setPedigreeCaseId(pedigreeCaseId);
      setQueryParams(queryParams ?? {});
      setDialogType('export');
    },
    dialog: (
      <PrintExportDialogController
        onClose={resetStates}
        isOpen={isDialogOpen}
        onPrintClick={onPrintClick}
        onExportClick={onExportClick}
        isLoading={isLoading}
        exportFailedMessage={exportFailedMessage}
        dialogType={dialogType as DialogType}
      />
    ),
  };
}

export type GetPedigreePdfQueryParamsWithoutAnonymise = Omit<
  GetPedigreePdfQueryParams,
  'anonymise'
>;
