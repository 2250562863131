import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  Link,
  List,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  EligibilityStatus,
  SatisfiedCriteria,
  SupportingEvidence,
} from 'api/patient/staff-patient-service';
import { useSuperscript } from 'common/hooks/useSuperscript';
import { ListItem } from 'components/common/ListItem';
import { useRelationshipOptions } from 'features/common/hooks/useRelationshipOptions';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface EligibilityCriteriaListProps {
  satisfiedCriteria: SatisfiedCriteria[];
  eligibilityStatus: EligibilityStatus;
  showMore: boolean;
  hiddenCriteria: boolean;
}

export const EligibilityCriteriaList = (
  props: EligibilityCriteriaListProps,
) => {
  const { satisfiedCriteria, showMore, eligibilityStatus, hiddenCriteria } =
    props;

  const isPotentiallyEligible = eligibilityStatus === 'POTENTIALLY_ELIGIBLE';
  const multipleCriteria = satisfiedCriteria.length > 1;

  const allItemsHasDivider = multipleCriteria && isPotentiallyEligible;

  const showCriteria = useCallback(
    (index: number) => {
      return showMore || (index === 0 && !hiddenCriteria);
    },
    [hiddenCriteria, showMore],
  );

  const showDivider = useCallback(
    (index: number) => {
      return (
        (showMore && allItemsHasDivider) ||
        (multipleCriteria && index === satisfiedCriteria.length - 1)
      );
    },
    [allItemsHasDivider, multipleCriteria, satisfiedCriteria.length, showMore],
  );

  return (
    <Stack gap={3}>
      {satisfiedCriteria.map((criteria, index) =>
        showCriteria(index) ? (
          <Stack key={index} gap={3}>
            <CriteriaItem
              description={criteria.description}
              sourceUrl={criteria.sourceUrl}
              supportingEvidence={criteria.supportingEvidence}
            />
            {showDivider(index) && <Divider />}
          </Stack>
        ) : null,
      )}
    </Stack>
  );
};

interface CriteriaItemProps {
  description: string;
  sourceUrl: string | null;
  supportingEvidence: SupportingEvidence[] | null;
}

const CriteriaItem = (props: CriteriaItemProps) => {
  const { description, sourceUrl, supportingEvidence } = props;

  const { t } = useTranslation(['iPedigree']);
  const theme = useTheme();
  const { addSuperscript } = useSuperscript();

  const allConfirmed = useMemo(
    () => supportingEvidence?.every((e) => e.isDiagnosisConfirmed),
    [supportingEvidence],
  );

  const statusColor = allConfirmed
    ? theme.palette.success.main
    : theme.palette.warning.main;

  const icon = allConfirmed ? faCheckCircle : faCircleInfo;

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={1}>
        <FontAwesomeIcon
          icon={icon}
          color={statusColor}
          fontSize={20}
          style={{
            padding: 2,
          }}
        />
        <Typography variant="body1">
          {addSuperscript(description)}
          {sourceUrl && (
            <Link href={sourceUrl} sx={{ marginLeft: 0.5 }}>
              <span>
                {t(
                  'eligibility-genetic-testing.satisfied-criteria.download-guidelines',
                )}
              </span>
              <span style={{ marginLeft: '4px' }}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </span>
            </Link>
          )}
        </Typography>
      </Stack>
      {supportingEvidence && (
        <SupportingEvidenceList supportingEvidence={supportingEvidence} />
      )}
    </Stack>
  );
};

const SupportingEvidenceList = ({
  supportingEvidence,
}: {
  supportingEvidence: SupportingEvidence[];
}) => {
  const { t } = useTranslation(['iPedigree']);
  return (
    <Stack gap={1} ml={3.5}>
      <Typography variant="h5">
        {t(
          'eligibility-genetic-testing.satisfied-criteria.supporting-evidence',
        )}
        :
      </Typography>
      <List
        sx={{
          paddingLeft: 3,
          paddingY: 0,
        }}
      >
        {supportingEvidence?.map((evidence, index) => (
          <ListItem key={index}>
            <SupportingEvidenceItem key={index} {...evidence} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

interface SupportingEvidenceItemProps extends SupportingEvidence {}

const SupportingEvidenceItem = (props: SupportingEvidenceItemProps) => {
  const { isDiagnosisConfirmed, relationship, diseaseName, ageAtDiagnosis } =
    props;

  const theme = useTheme();
  const { t } = useTranslation(['iPedigree']);
  const { translateRelationship } = useRelationshipOptions();

  const confirmedTextColor = isDiagnosisConfirmed
    ? theme.palette.success.main
    : theme.palette.warning.main;

  const translatedRelationship = translateRelationship(relationship);
  const baseLabel = 'eligibility-genetic-testing.supporting-evidence';
  const translatedAt = t(`${baseLabel}.at`);
  const diagnosisStatusText = isDiagnosisConfirmed
    ? t(`${baseLabel}.confirmed`)
    : t(`${baseLabel}.not-confirmed`);

  const evidenceText = getEvidenceText({
    relationship: translatedRelationship,
    diseaseName,
    ageAtDiagnosis,
    translatedAt,
  });

  return (
    <Typography variant="body1">
      {evidenceText}
      <span style={{ color: confirmedTextColor }}>{diagnosisStatusText}</span>
    </Typography>
  );
};

const getEvidenceText = ({
  relationship,
  diseaseName,
  ageAtDiagnosis,
  translatedAt,
}: {
  relationship: string;
  diseaseName: string;
  ageAtDiagnosis: number | null | undefined;
  translatedAt: string;
}) => {
  const ageString = ageAtDiagnosis ? `${translatedAt} ${ageAtDiagnosis}` : '';

  const compiledString = `${relationship}, ${diseaseName}${
    ageString ? ` ${ageString}` : ''
  }, `;

  return compiledString;
};
