import { BaseService } from 'api/base-service';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from 'api/serviceProviderFactory';
import { PersonalNumberValidationResponse, Sex } from 'api/types';
import { AxiosResponse } from 'axios';
import { NumberOfRelativesFormData } from 'common/features/newPedigreeForm/types';
import {
  CanChangeNodeSexResponse,
  CreateNodeParams,
  CustomizableFormData,
  DeletablePedigreResponse,
  ParentsIds,
  Pedigree,
  PedigreeNodeSex,
  QuestionnaireForm,
} from 'common/features/pedigree/common/types';
import React from 'react';

export type Disease = {
  id: number | null;
};

export type LocalizedDisease = {
  id: number;
  identifier: string;
  localizedName: string;
};

export type Purpose = Disease;

export type Diagnose = {
  disease: string;
  ageAtDiagnosis: number | null;
  ageIsApproximate: boolean;
  treatedBy: string;
  notes: string;
};

export type PatientDetails = {
  name: string;
  dateOfBirth: string;
  personalNumber: string;
  sex: Sex.FEMALE | Sex.MALE | '';
  email?: string;
  phoneNumber?: string;
};

export type NewPedigreeCaseRequest = {
  patientDetails: PatientDetails;
  diagnoses: Diagnose[];
};

export type PedigreeCaseStatus =
  | 'caseCreated'
  | 'pedigreeRequested'
  | 'pedigreeCreatedByPatient'
  | 'pedigreeReceived'
  | 'caseViewed'
  | 'pedigreeProvided';

export type PedigreeCase = {
  id: string;
  patientId: string;
  patientDetails: PatientDetails;
  diagnoses: Diagnose[];
  purpose: LocalizedDisease[];
  status: PedigreeCaseStatus;
  receivedAt?: string;
  viewed?: boolean;
  familyId?: string;
};

type PaginationQueryParams = {
  limit: string;
  offset: string;
  forUserId?: string;
};

type PedigreeCasesQueryParams = PaginationQueryParams & {
  sortQuery: string;
  searchQuery: string;
};

type PedigreeCasesUpdateQueryParams = PaginationQueryParams & {
  submittedAfter: string;
};

export type Question = {
  title: string;
  answer?: string;
};

export type QuestionsGroup = {
  title: string;
  questions: Question[];
};

export type Questionnaire = {
  groups: QuestionsGroup[];
};

type Categories =
  | 'formInformation'
  | 'personalInformation'
  | 'personalData'
  | 'medicalHistory'
  | 'geneticTesting'
  | 'lifeStyle';

type Context = 'riskAssessment';

export type PedigreeCaseEventName =
  | 'caseCreated'
  | 'pedigreeRequested'
  | 'pedigreeRequestReminded'
  | 'pedigreeCreatedByPatient'
  | 'pedigreeEditedByPatient'
  | 'pedigreeSavedByPatient'
  | 'pedigreeRequestCancelled'
  | 'pedigreeSubmittedByPatient'
  | 'caseViewed'
  | 'pedigreeAccepted'
  | 'pedigreeRejected'
  | 'pedigreeCreatedByStaff'
  | 'pedigreeEditedByStaff';

export type PedigreeCaseEvent = {
  name: PedigreeCaseEventName;
  createdAt: string;
  createdBy?: string;
};

export type PedigreeCaseEvents = {
  id: string;
  items: PedigreeCaseEvent[];
};

export type StaffPedigree = Pedigree & { receivedAt: string };

type PedigreeCaseStats = {
  receivedNotViewedCount: number;
  requestedNotReceivedCount: number;
  totalCount: number;
};

type PaginatedResponse<T> = {
  items: T[];
  count: number;
};

type PedigreeStatus = 'notCreated' | 'created' | 'received' | 'requested';
export type IPedigreeItem = {
  new: boolean;
  patientId: string;
  pedigreeCaseId: string;
  patientName: string;
  patientPersonalNumber: string;
  pedigreeStatusDate: string;
  pedigreeStatus: PedigreeStatus;
  familyId: string;
};

const getRelativeFormDataWithNonEmptyDiagnoses = (nodeFormData: any) => {
  const { medicalHistory } = nodeFormData;

  const hasListDiagnoses =
    medicalHistory?.diagnosedWithCancer?.diagnoses?.length >= 1;

  if (hasListDiagnoses) {
    const filteredNonEmptyDiagnoses =
      medicalHistory.diagnosedWithCancer.diagnoses.filter(
        (diagnose: { type: string }) => !!diagnose?.type,
      );
    return {
      ...nodeFormData,
      medicalHistory: {
        ...medicalHistory,
        diagnosedWithCancer: {
          ...medicalHistory.diagnosedWithCancer,
          diagnoses: !!filteredNonEmptyDiagnoses.length
            ? filteredNonEmptyDiagnoses
            : [{}],
        },
      },
    };
  } else {
    return nodeFormData;
  }
};

class StaffPedigreeCaseService extends BaseService {
  getDiseaseOptionsForPurpose = async (): Promise<LocalizedDisease[]> => {
    const diseases = await this.axiosInstance
      .get('/staff/pedigree-cases/disease-options?usecase=purpose')
      .then((response: any) => response.data);

    return sortDiseases(diseases);
  };

  getDiseaseOptionsForDiagnose = async (): Promise<LocalizedDisease[]> => {
    return this.axiosInstance
      .get('/staff/pedigree-cases/disease-options?usecase=diagnose')
      .then((response: any) => response.data);
  };

  createNewPedigreeCaseRequest = async (
    pedigreeCase: NewPedigreeCaseRequest,
  ) => {
    const filteredDiagnoses = pedigreeCase.diagnoses.filter(
      (diagnose) => diagnose.disease !== '',
    );
    return this.axiosInstance
      .post('/staff/pedigree-cases', {
        ...pedigreeCase,
        diagnoses: filteredDiagnoses,
      })
      .then((response: any) => response.data);
  };

  getPedigreeStats = async ({
    forUserId,
  }: {
    forUserId?: string;
  }): Promise<PedigreeCaseStats> => {
    const url = forUserId
      ? `/staff/pedigree-cases/stats?forUserId=${forUserId}`
      : '/staff/pedigree-cases/stats';

    return this.axiosInstance.get(url).then((response: any) => response.data);
  };

  validatePersonalNumber = async (
    personalNumber: string,
  ): Promise<PersonalNumberValidationResponse> => {
    return this.axiosInstance
      .get(`/personal-number-validation?personalNumber=${personalNumber}`)
      .then((response: any) => response.data);
  };

  getPedigreeCase = async (patientId: string): Promise<PedigreeCase> => {
    return this.axiosInstance
      .get(`/staff/patients/${patientId}/pedigree-case`)
      .then((response: any) => response.data);
  };

  reviewPedigreeCase = async (
    caseId: string,
    reviewStatus: 'accept' | 'reject',
  ) => {
    return this.axiosInstance
      .put(`/staff/pedigree-cases/${caseId}/reviews`, { reviewStatus })
      .then((response: any) => response.data);
  };

  updatePatientContactDetails = async (
    pedigreeCaseId: string,
    formData: { email: string; phoneNumber: string },
  ) => {
    return this.axiosInstance
      .put(`/staff/pedigree-cases/${pedigreeCaseId}/patient-details`, {
        ...formData,
      })
      .then((response: any) => response.data);
  };

  updatePedigreeCasePurpose = async (
    pedigreeCaseId: string,
    purpose: { id: number },
  ): Promise<void> => {
    return this.axiosInstance
      .put(`/staff/pedigree-cases/${pedigreeCaseId}/purpose`, {
        purpose: [purpose],
      })
      .then((response: any) => response.data);
  };

  createNewPedigree = async (
    pedigreeCaseId: string,
    formData: NumberOfRelativesFormData,
  ) => {
    return this.axiosInstance
      .put(`/staff/pedigree-cases/${pedigreeCaseId}/pedigree`, formData)
      .then((response) => response.data);
  };

  getPedigreeCases = async ({
    sortQuery,
    searchQuery,
    limit,
    offset,
  }: PedigreeCasesQueryParams) => {
    const params = new URLSearchParams({
      sortBy: sortQuery,
      search: searchQuery,
      limit,
      offset,
    });

    return this.axiosInstance
      .get(`/staff/pedigree-cases?${params}`)
      .then((response: any) => response.data);
  };

  getPedigreeCasesUpdates = async ({
    submittedAfter,
    limit,
    offset,
  }: PedigreeCasesUpdateQueryParams): Promise<
    PaginatedResponse<PedigreeCase>
  > => {
    const params = new URLSearchParams({
      submittedAfter,
      limit,
      offset,
    });

    return this.axiosInstance
      .get(`/staff/pedigree-cases/updates?${params}`)
      .then((response: any) => response.data);
  };

  getIpedigreeCases = async ({
    searchQuery,
    limit,
    offset,
    forUserId,
  }: Omit<PedigreeCasesQueryParams, 'sortQuery'>): Promise<
    PaginatedResponse<IPedigreeItem>
  > => {
    const params = new URLSearchParams({
      search: searchQuery,
      limit,
      offset,
    });

    if (forUserId) {
      params.append('forUserId', forUserId);
    }

    return this.axiosInstance
      .get(`/staff/ipedigree-cases?${params}`)
      .then((response: any) => response.data);
  };

  getQuestionnaire = async (caseId: string): Promise<Questionnaire> => {
    return this.axiosInstance
      .get(`/staff/pedigree-cases/${caseId}/questionnaire`)
      .then((response: AxiosResponse) => response.data);
  };

  createRequest = async (pedigreeCaseId: string) => {
    return this.axiosInstance
      .put(`/staff/pedigree-cases/${pedigreeCaseId}/requests/create`)
      .then((response: AxiosResponse) => response);
  };

  cancelRequest = async (pedigreeCaseId: string) => {
    return this.axiosInstance
      .put(`/staff/pedigree-cases/${pedigreeCaseId}/requests/cancel`)
      .then((response: AxiosResponse) => response);
  };

  createReminder = async (pedigreeCaseId: string) => {
    return this.axiosInstance
      .post(`/staff/pedigree-cases/${pedigreeCaseId}/request-reminders`)
      .then((response: AxiosResponse) => response);
  };

  getPedigreeByPatientId = async (
    patientId: string,
    options?: { includeRelationshipDegree?: boolean },
  ): Promise<StaffPedigree> => {
    const params = new URLSearchParams();
    if (options?.includeRelationshipDegree) {
      params.append('includeRelationshipDegree', 'true');
    }

    return this.axiosInstance
      .get(`/staff/patients/${patientId}/pedigree?${params}`)
      .then((response: AxiosResponse) => response.data);
  };

  getPedigreeNodeDetails = async (
    pedigreeCaseId: string,
    nodeId: string,
  ): Promise<Questionnaire> => {
    return this.axiosInstance
      .get(
        `/staff/pedigree-cases/${pedigreeCaseId}/pedigree-nodes/${nodeId}/questionnaire`,
      )
      .then((response: AxiosResponse) => response.data);
  };

  getPatientForm = async (
    pedigreeCaseId: string,
    queryParams?: { categories?: Categories; context?: Context },
  ): Promise<QuestionnaireForm> => {
    const params = new URLSearchParams();
    if (queryParams?.categories) {
      params.append('categories', queryParams?.categories);
    }

    if (queryParams?.context) {
      params.append('context', queryParams?.context);
    }

    const response = await this.axiosInstance.get(
      `/staff/pedigree-cases/${pedigreeCaseId}/questionnaire-form?${params}`,
    );
    return response.data;
  };

  addNewPedigreeNode = async (
    pedigreeId: string,
    nodeParams: CreateNodeParams,
  ): Promise<Pedigree> => {
    return this.axiosInstance
      .post(`/staff/pedigrees/${pedigreeId}/nodes/`, nodeParams)
      .then((response) => response.data);
  };

  updatePedigreeNodeParents = async (
    pedigreeId: string,
    pedigreeNodeId: string,
    parentsIds: ParentsIds,
  ): Promise<Pedigree> => {
    return this.axiosInstance
      .put(`/staff/pedigrees/${pedigreeId}/nodes/${pedigreeNodeId}/parents`, {
        parentsIds: parentsIds,
      })
      .then((response) => response.data);
  };

  canChangeSex = async (
    pedigreeId: string,
    nodeId: number,
    queryParams: {
      newSex: PedigreeNodeSex;
    },
  ): Promise<CanChangeNodeSexResponse> => {
    const params = new URLSearchParams({
      newSex: queryParams.newSex,
    });
    const response = await this.axiosInstance.get(
      `/staff/pedigrees/${pedigreeId}/nodes/${nodeId}/can-change-sex?${params}`,
    );
    return response.data;
  };

  updateNodeSex = async (
    pedigreeId: string,
    nodeId: number,
    sex: PedigreeNodeSex,
  ): Promise<Pedigree> => {
    return this.axiosInstance
      .put(`/staff/pedigrees/${pedigreeId}/nodes/${nodeId}/sex`, {
        sex,
      })
      .then((response) => response.data);
  };

  deletePedigreeNode = async (
    pedigreeId: string,
    nodeId: number,
  ): Promise<Pedigree> => {
    const response = await this.axiosInstance.delete(
      `/staff/pedigrees/${pedigreeId}/nodes/${nodeId}`,
    );
    return response.data;
  };

  deletablePedigreeNode = async (
    pedigreeId: string,
    nodeId: number,
  ): Promise<DeletablePedigreResponse> => {
    const response = await this.axiosInstance.get(
      `/staff/pedigrees/${pedigreeId}/nodes/${nodeId}/deletable`,
    );
    return response.data;
  };

  getRelativeForm = async (
    pedigreeId: string,
    pedigreeNodeId: string,
  ): Promise<QuestionnaireForm> => {
    const response = await this.axiosInstance.get(
      `/staff/pedigrees/${pedigreeId}/nodes/${pedigreeNodeId}/details-form`,
    );
    return response.data;
  };

  submitRelativeForm = async (
    pedigreeId: string,
    pedigreeNodeId: string,
    formData: CustomizableFormData,
  ) => {
    const relativeFormData = getRelativeFormDataWithNonEmptyDiagnoses(formData);
    const response = await this.axiosInstance.put(
      `/staff/pedigrees/${pedigreeId}/nodes/${pedigreeNodeId}/details-form`,
      relativeFormData,
    );
    return response.data;
  };

  submitPatientForm = async (
    pedigreeCaseId: string,
    formData: CustomizableFormData,
  ) => {
    const response = await this.axiosInstance.put(
      `/staff/pedigree-cases/${pedigreeCaseId}/questionnaire-form`,
      formData,
    );
    return response.data;
  };

  printPedigreePdf = async (
    pedigreeCaseId: string,
    queryParams: GetPedigreePdfQueryParams,
  ) => {
    const blob = await this.getPedigreePdfBlob(pedigreeCaseId, queryParams);
    const objUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.src = objUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow?.print();
    iframe.hidden = true;
  };

  exportPedigreePdf = async (
    pedigreeCaseId: string,
    queryParams: GetPedigreePdfQueryParams,
  ) => {
    const blob = await this.getPedigreePdfBlob(pedigreeCaseId, queryParams);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `pedigree-${pedigreeCaseId}.pdf`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  exportCanriskFile = async (pedigreeCaseId: string, anonymized: boolean) => {
    const params = new URLSearchParams({
      anonymise: anonymized ? 'true' : 'false',
    });
    const response = await this.axiosInstance.get(
      `/canrisk/staff/pedigree-cases/${pedigreeCaseId}/file?${params}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'plain/text',
        },
      },
    );

    const blob = new Blob([response.data], { type: 'plain/text' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `canrisk-${pedigreeCaseId}.txt`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  private getPedigreePdfBlob = async (
    pedigreeCaseId: string,
    queryParams: GetPedigreePdfQueryParams,
  ) => {
    const params = new URLSearchParams({
      anonymise: queryParams.anonymise ? 'true' : 'false',
      showNames: parseOptionalBooleanQueryParam(queryParams.showNames, 'true'),
      showAges: parseOptionalBooleanQueryParam(queryParams.showAges, 'true'),
      showDiagnoses: parseOptionalBooleanQueryParam(
        queryParams.showDiagnoses,
        'true',
      ),
      showGenes: parseOptionalBooleanQueryParam(queryParams.showGenes, 'true'),
    });
    const response = await this.axiosInstance.get(
      `/staff/pedigree-cases/${pedigreeCaseId}/pedigree/pdf?${params}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: 'application/pdf',
        },
      },
    );

    return new Blob([response.data], { type: 'application/pdf' });
  };

  getPedigreeCaseEvents = async (
    pedigreeCaseId: string,
  ): Promise<PedigreeCaseEvents> => {
    const response = await this.axiosInstance.get(
      '/staff/pedigree-cases/' + pedigreeCaseId + '/events',
    );
    return response.data;
  };

  submitCanriskFile = async (pedigreeCaseId: string, formData: FormData) => {
    const response = await this.axiosInstance.put(
      `canrisk/staff/pedigree-cases/${pedigreeCaseId}/pedigree`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  };
}

const sortDiseases = (diseases: LocalizedDisease[]): LocalizedDisease[] => {
  const otherLocalizations = ['Other', 'Övrig'];
  const diseasesWithoutOther = diseases.filter(
    (disease) => !otherLocalizations.includes(disease.localizedName),
  );
  const otherOption = diseases.find((disease) =>
    otherLocalizations.includes(disease.localizedName),
  );
  diseasesWithoutOther.sort((a, b) => {
    return a.localizedName.localeCompare(b.localizedName);
  });
  if (!otherOption) {
    return diseasesWithoutOther;
  }

  return [...diseasesWithoutOther, otherOption];
};

const StaffPedigreeCaseServiceContext = React.createContext<{
  service: StaffPedigreeCaseService | null;
}>({ service: null });

export const StaffPedigreeCaseServiceProvider = serviceProviderFactory(
  StaffPedigreeCaseService,
  StaffPedigreeCaseServiceContext,
);

export const useStaffPedigreeCaseService = serviceHookFactory(
  StaffPedigreeCaseService,
  StaffPedigreeCaseServiceContext,
);

export type GetPedigreePdfQueryParams = {
  anonymise: boolean;
  showNames?: boolean;
  showAges?: boolean;
  showDiagnoses?: boolean;
  showGenes?: boolean;
};

const parseOptionalBooleanQueryParam = (
  value: boolean | undefined,
  defaultValue: 'true' | 'false',
): 'true' | 'false' => {
  if (value === undefined) {
    return defaultValue;
  }

  return value ? 'true' : 'false';
};
