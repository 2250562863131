import { WidgetProps } from '@rjsf/utils';
import { TextAreaInput } from 'components/inputs';
import { ReadonlyView } from '../views/ReadonlyView';

export const TextareaWidget = (props: WidgetProps) => {
  const labelInfo =
    (props.uiSchema?.['ui:options']?.tooltip as string) || undefined;

  const error = props?.rawErrors?.length ? props.rawErrors[0] : '';

  if (props.readonly) {
    return (
      <ReadonlyView
        title={props.label}
        value={props.value}
        uiSchema={props.uiSchema}
      />
    );
  }

  return (
    <TextAreaInput
      labelInfo={labelInfo}
      value={props.value}
      error={error}
      required={props.required}
      onChange={(event) => props.onChange(event.target.value)}
      id={props.id}
      label={props.label}
      placeholder={props.placeholder}
    />
  );
};
