import { Stack } from '@mui/material';
import { PatientServiceProvider } from 'api/patient/patient-service';
import i18n from 'assets/localization/i18n/patientPortalI18nConfig';
import { FourOFour } from 'patientPortal/pages/404';
import PatientPortalRouter from 'patientPortal/routes/PatientPortalRouter';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/my-case/:patientId/*',
    element: (
      <PatientServiceProvider accessToken={''}>
        <PatientPortalRouter />
      </PatientServiceProvider>
    ),
  },
  {
    path: '*',
    element: <FourOFour />,
  },
]);

function PatientRouter() {
  return (
    <I18nextProvider i18n={i18n}>
      <Stack direction="column" minHeight="100vh">
        <RouterProvider router={router} />
      </Stack>
    </I18nextProvider>
  );
}

export default PatientRouter;
