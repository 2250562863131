import { Container, Link, Stack, Typography } from '@mui/material';
import { usePatientService } from 'api/patient/patient-service';
import { SectionBox } from 'components/common';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { SectionDivider } from '../common/SectionDivider';

export const MessageSection = ({ patientName }: { patientName: string }) => {
  const service = usePatientService();
  const { patientId } = useParams();
  const location = useLocation();
  const { t } = useTranslation('patientPortal');
  const currentPath = location.pathname;

  const { data, isLoading } = useQuery(
    ['messages', patientId],
    () => service?.getMessages(patientId || ''),
    {
      refetchOnWindowFocus: false,
    },
  );

  const messagePagePath = currentPath.endsWith('/')
    ? currentPath + PATIENT_PATHS.MESSAGES
    : currentPath + `/${PATIENT_PATHS.MESSAGES}`;

  const hasMultipleMessages = useMemo(() => {
    if (!data) return false;
    return data.messages.length > 1;
  }, [data]);

  const message = useMemo(() => {
    if (!data) return null;
    const sortedMessages = data.messages.sort((a, b) => {
      return new Date(b.datetime).getTime() - new Date(a.datetime).getTime();
    });

    return sortedMessages[0];
  }, [data]);

  if (isLoading) return null;
  if (!message) return null;

  return (
    <>
      <Container maxWidth="sm">
        <Stack gap={3}>
          <Typography variant="h1">
            {t('home-message-section.title')} {patientName}
          </Typography>
          <Stack gap={2}>
            <Typography variant="h2">
              {t('home-message-section.description')} {message.sender.name}
            </Typography>
            <SectionBox>
              <Typography
                variant="body1"
                sx={{
                  whiteSpace: 'break-spaces',
                }}
              >
                {message.content}
              </Typography>
            </SectionBox>
          </Stack>
          {hasMultipleMessages && (
            <Link variant="body1" width="fit-content" href={messagePagePath}>
              {t('home-message-section.see-all-messages')}
            </Link>
          )}
        </Stack>
      </Container>
      <SectionDivider />
    </>
  );
};
