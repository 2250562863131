import { Button, Stack } from '@mui/material';
import { ArrayFieldTemplateProps } from '@rjsf/utils';
import { useCallback } from 'react';
import { GeneticTestItem } from './GeneticTestItem';

export const GeneticTestTemplate = (props: ArrayFieldTemplateProps) => {
  const addButtonText = props.uiSchema?.['ui:buttonText'] || undefined;
  const title = props.uiSchema?.['ui:title'] ?? '';
  const hasInitialForm = props.schema.minItems === 1;

  const showAddButton = props.canAdd && !props.readonly;

  const showAction = useCallback(
    (index: number) => {
      const geneticTest = props.formData[index];
      const hasActionRemainingItem = props.items.length > 1;

      if (props.readonly) return false;
      if (!hasInitialForm) return true;
      return !!(geneticTest?.id || hasActionRemainingItem);
    },
    [props.formData, props.items, props.readonly, hasInitialForm],
  );

  const handleOnDropIndexClick = (item: any, index: number): void => {
    const onlyOneItemRemained = props.items.length === 1;
    if (hasInitialForm && onlyOneItemRemained) {
      return item.children.props.onChange({});
    }
    return item.onDropIndexClick(index)();
  };

  return (
    <Stack spacing={3}>
      {props.items.map((item, index) => (
        <GeneticTestItem
          key={props.formData[index]?.id || item.key}
          title={props.items.length > 1 ? `${title} ${index + 1}` : title}
          geneticTest={props.formData[index]}
          children={item.children}
          deleteGeneticTest={() => handleOnDropIndexClick(item, index)}
          showAction={showAction(index)}
        />
      ))}
      {showAddButton && (
        <Button variant="outlined" onClick={props.onAddClick}>
          {addButtonText}
        </Button>
      )}
    </Stack>
  );
};
