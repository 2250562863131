import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Link, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const INSTRUCTION_URL = 'https://arkus.ai/myfamilytree-guide/';

export const HelpSection = () => {
  const { t } = useTranslation('patientPortal');
  const theme = useTheme();

  return (
    <Container maxWidth="sm">
      <Stack gap={3}>
        <Typography variant="h2">{t('home-help-section-title')}</Typography>
        <Typography variant="body1">
          {t('home-help-section.description')}
        </Typography>
        <Link
          href={INSTRUCTION_URL}
          target="_blank"
          sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}
        >
          <Typography variant="body2" color={theme.palette.common.brand.blue}>
            {t('home-help-section.link-text')}
          </Typography>
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            color={theme.palette.common.brand.blue}
          />
        </Link>
      </Stack>
    </Container>
  );
};
