import { usePatientService } from 'api/patient/patient-service';
import { AxiosError } from 'axios';
import { NewPedigreeFormController } from 'common/features/newPedigreeForm/controllers/NewPedigreeFormController';
import { LoadingBackdrop } from 'components/common';
import PedigreeStage from 'patientPortal/features/pedigree/pedigreeRenderer/pedigreeStage/PedigreeStage';
import { Layout } from 'patientPortal/layouts/Layout';
import { PATIENT_PATHS } from 'patientPortal/routes/PatientPortalRouter';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

function PedigreeEditor() {
  const service = usePatientService();
  const { patientId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(['patientPortal']);

  const { data: pedigreeCase, isLoading: isPedigreeCaseLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service.getPedigreeCase(patientId || ''),
    {
      onError: (error: AxiosError) => {
        if (error.response?.status === 404 || error.response?.status === 400) {
          navigate('../404');
          return;
        }
        if (error.response?.status === 403) {
          navigate('../');
          return;
        }
      },
    },
  );

  const { data: pedigree, isLoading: isPedigreeLoading } = useQuery(
    ['pedigree', pedigreeCase?.pedigreeId],
    () => service?.getPedigree(pedigreeCase?.pedigreeId || ''),
    {
      enabled: !!pedigreeCase?.pedigreeId,
    },
  );

  const pedigreeCaseId = pedigreeCase?.id;

  const { isLoading: isPedigreeCaseInfoLoading } = useQuery(
    ['pedigreeCaseInfo', pedigreeCaseId],
    () => service.getPedigreeCaseInformation(pedigreeCaseId as string),
    {
      onSuccess(data) {
        if (data.pedigreeCaseStatus === 'submitted') {
          return navigate('../' + PATIENT_PATHS.PEDIGREE_SUBMITTED, {
            relative: 'path',
          });
        }
      },
      enabled: !!pedigreeCaseId,
    },
  );

  const isLoading =
    isPedigreeCaseLoading || isPedigreeLoading || isPedigreeCaseInfoLoading;

  if (isLoading) return <LoadingBackdrop open={isLoading} />;

  const hasPedigree = pedigreeCase?.pedigreeId || pedigree;

  return (
    <Layout>
      {!hasPedigree && (
        <NewPedigreeFormController
          heading={{
            title: t('new-pedigree-form.title'),
            description: t('new-pedigree-form.relatives'),
            info: t('new-pedigree-form.description'),
            showIcon: true,
          }}
          showRelativeDiseaseField
          createNewPedigree={(formData) =>
            service.createNewPedigree(pedigreeCaseId || '', formData)
          }
        />
      )}
      {pedigree && hasPedigree && <PedigreeStage pedigree={pedigree} />}
    </Layout>
  );
}

export default PedigreeEditor;
