import { Button, CardContent, Stack, Typography } from '@mui/material';
import { PedigreeNodeSex } from 'common/features/pedigree/common/types';
import CardWithBorder from 'components/cards/CardWithBorder';
import { useTranslation } from 'react-i18next';
import { InstructionBox } from './common/InstructionBox';
import PedigreeNodeCard from './common/PedigreeNodeCard';

interface PedigreeCreatedOnboardingInfoProps {
  onConfirmClick: () => void;
}

export const PedigreeCreatedOnboardingInfo = (
  props: PedigreeCreatedOnboardingInfoProps,
) => {
  const { t } = useTranslation(['patientPortal']);
  return (
    <>
      <Stack gap={3} p={3}>
        <Typography variant="h3">
          {t('pedigree.onboarding-drawer.created.title')}
        </Typography>
        <Typography>
          {t('pedigree.onboarding-drawer.created.description.paragraph1')}
        </Typography>
        <CardWithBorder variant="outlined">
          <CardContent>
            <PedigreeNodeCard
              sex={PedigreeNodeSex.FEMALE}
              relativeRelationLabel={t(
                'onboarding-drawer.description.relative-relation-label',
              )}
            />
          </CardContent>
        </CardWithBorder>
        <Button variant="contained" onClick={props.onConfirmClick}>
          {t('pedigree.onboarding-drawer.created.textButton')}
        </Button>
      </Stack>
      <Stack
        sx={{
          position: 'absolute',
          width: '100%',
          bottom: 0,
        }}
      >
        <InstructionBox />
      </Stack>
    </>
  );
};
