import { useFeature } from '@bucketco/react-sdk';
import { Box, Button, Snackbar, Stack } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { useUserService } from 'api/user/user-service';
import { FEATURE_FLAGS } from 'common/contexts/BucketProvider';
import { NumberOfRelativesFormData } from 'common/features/newPedigreeForm/types';
import Pedigree from 'common/features/pedigree';
import { CustomizableFormData } from 'common/features/pedigree/common/types';
import {
  GetPedigreePdfQueryParamsWithoutAnonymise,
  usePedigreePrintExport,
} from 'common/features/pedigreeCase/pedigreePrintExport/hooks/usePedigreePrintExport';
import Dialog from 'components/common/Dialog';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { ToastMessage } from 'components/common/ToastMessage';
import NewPedigreeForm from 'iPedigree/features/patient/pedigree/NewPedigreeForm';
import PedigreeOverviewPanel from 'iPedigree/features/patient/pedigree/PedigreeOverviewPanel';
import PedigreeReviewPanel from 'iPedigree/features/patient/pedigree/PedigreeReviewPanel';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

export const PatientPedigreePage = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { t } = useTranslation('iPedigree');
  const service = useStaffPedigreeCaseService();
  const userService = useUserService();

  const [showCreatePedigreeForm, setShowCreatePedigreeForm] =
    useState<boolean>(false);
  const [createdPedigreeDialogOpen, setCreatedPedigreeDialogOpen] =
    useState<boolean>(false);
  const location = useLocation();
  const [reviewToastType, setReviewToastType] = useState<
    'accept' | 'reject' | null
  >(null);

  const { isEnabled: showRelationshipDegree } = useFeature(
    FEATURE_FLAGS.pedigreeRelationshipDegree,
  );

  const {
    onPedigreePrintClick,
    onPedigreeDownloadClick,
    dialog: printExportDialog,
  } = usePedigreePrintExport();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', patientId, 'pedigreePage'],
    () => service.getPedigreeCase(patientId || ''),
  );

  const pedigreeCaseId = pedigreeCase?.id;

  const queryClient = useQueryClient();
  const reviewMutation = useMutation(
    (review: 'accept' | 'reject') =>
      service.reviewPedigreeCase(pedigreeCaseId || '', review),
    {
      onSuccess: (_, review) => {
        setReviewToastType(review);
        invalidatePedigreeCaseQuery();
      },
    },
  );

  const { data: user } = useQuery(['user'], userService.getCurrentUser);

  const invalidatePedigreeCaseQuery = useCallback(() => {
    queryClient.invalidateQueries(['pedigreeCase', patientId, 'pedigreePage']);
  }, [patientId, queryClient]);

  useEffect(() => {
    const { status } = pedigreeCase || {};
    const { showCreatePedigreeForm, showCreatePedigreeDialog } =
      location.state || {};

    if (showCreatePedigreeForm && status === 'caseCreated') {
      setShowCreatePedigreeForm(true);
    }

    if (showCreatePedigreeDialog) {
      invalidatePedigreeCaseQuery();
    }

    if (showCreatePedigreeDialog && status === 'pedigreeProvided') {
      location.state.showCreatePedigreeDialog = false;
      setCreatedPedigreeDialogOpen(true);
    }
  }, [
    invalidatePedigreeCaseQuery,
    location.state,
    patientId,
    pedigreeCase,
    queryClient,
  ]);

  if (!pedigreeCaseId || !patientId) return null;

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  const showPedigreeOverviewPanel =
    !showCreatePedigreeForm &&
    (pedigreeCase?.status === 'pedigreeRequested' ||
      pedigreeCase?.status === 'pedigreeCreatedByPatient' ||
      pedigreeCase?.status === 'caseCreated');

  if (showPedigreeOverviewPanel) {
    return (
      <Box pt={3} display="flex" justifyContent="center">
        <Snackbar
          open={reviewToastType !== null}
          onClose={() => setReviewToastType(null)}
          message={
            reviewToastType === 'reject' ? (
              <ToastMessage message={t('review.rejected')} />
            ) : null
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={1800}
        />
        <Stack display="flex" width="100%" maxWidth={700}>
          <PedigreeOverviewPanel patientId={patientId} />
        </Stack>
      </Box>
    );
  }

  const askForConcerningCancer =
    (pedigreeCase && pedigreeCase?.purpose.length === 0) || false;

  const handleSubmitNewPedigreeForm = async ({
    numberOfRelatives,
    purpose,
  }: {
    numberOfRelatives: NumberOfRelativesFormData;
    purpose?: { id: number };
  }) => {
    if (purpose) {
      await service.updatePedigreeCasePurpose(pedigreeCaseId, purpose);
    }
    await service.createNewPedigree(pedigreeCaseId, numberOfRelatives);
    invalidatePedigreeCaseQuery();
    setShowCreatePedigreeForm(false);
  };

  if (!!showCreatePedigreeForm) {
    return (
      <NewPedigreeForm
        submitFormCallback={handleSubmitNewPedigreeForm}
        onCancelClick={() => setShowCreatePedigreeForm(false)}
        askForConcerningCancer={askForConcerningCancer}
      />
    );
  }

  const isProfessionalReadonly =
    pedigreeCase?.status === 'pedigreeReceived' ||
    pedigreeCase?.status === 'caseViewed';

  const pedigreeType = isProfessionalReadonly
    ? 'professionalReadonly'
    : 'professionalEditable';

  return (
    <>
      <Snackbar
        open={reviewToastType !== null}
        onClose={() => setReviewToastType(null)}
        message={
          reviewToastType === 'accept' ? (
            <ToastMessage message={t('review.accepted')} />
          ) : null
        }
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={1800}
      />
      <Dialog
        open={createdPedigreeDialogOpen}
        title={t('pedigree-created-dialog.title')}
        content={t('pedigree-created-dialog.content')}
        actions={[
          <Button
            key="created-pedigree-button"
            onClick={() => setCreatedPedigreeDialogOpen(false)}
            variant="contained"
          >
            {t('pedigree-created-dialog.button-text')}
          </Button>,
        ]}
      ></Dialog>
      {printExportDialog}
      {isProfessionalReadonly && (
        <PedigreeReviewPanel
          onAcceptClick={() => reviewMutation.mutate('accept')}
          onRejectClick={() => reviewMutation.mutate('reject')}
        />
      )}
      <Pedigree
        pedigreeType={pedigreeType}
        user={user}
        service={{
          getPedigree: () =>
            service.getPedigreeByPatientId(patientId, {
              includeRelationshipDegree: showRelationshipDegree,
            }),
          addNewPedigreeNode: service.addNewPedigreeNode,
          updatePedigreeNodeParents: service.updatePedigreeNodeParents,
          canChangeSex: service.canChangeSex,
          updateNodeSex: service.updateNodeSex,
          deletePedigreeNode: service.deletePedigreeNode,
          deletablePedigreeNode: service.deletablePedigreeNode,
          getPatientForm: () => service.getPatientForm(pedigreeCaseId),
          getRelativeForm: service.getRelativeForm,
          putRelativeForm: service.submitRelativeForm,
          putPatientForm: (formData: CustomizableFormData) =>
            service.submitPatientForm(pedigreeCaseId, formData),
          onPedigreePrintClick: isProfessionalReadonly
            ? undefined
            : (params: GetPedigreePdfQueryParamsWithoutAnonymise) =>
                onPedigreePrintClick(pedigreeCaseId, params),
          onPedigreeDownloadClick: isProfessionalReadonly
            ? undefined
            : (params: GetPedigreePdfQueryParamsWithoutAnonymise) =>
                onPedigreeDownloadClick(pedigreeCaseId, params),
        }}
      />
    </>
  );
};
