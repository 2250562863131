import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Fade, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DrawerFooterActionsProps {
  onSave: () => void;
  onCancel: () => void;
  isSaveDisabled?: boolean;
  hasSavedChanges?: boolean;
}

export const DrawerFooterActions = ({
  onSave,
  onCancel,
  isSaveDisabled = false,
  hasSavedChanges = false,
}: DrawerFooterActionsProps) => {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <Stack
      p={2}
      px={3}
      direction="row"
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: '0px -3px 6px rgba(35, 55, 73, 0.06)',
        borderTop: `1px solid ${theme.palette.common.opacity.darkBlue[25]}`,
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Fade in={hasSavedChanges} timeout={1000}>
        <Stack direction="row" alignItems="center" gap={1.3}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{ fontSize: 24, opacity: 0.7 }}
          />
          <Typography variant="body1Opacity70">{t('saved-label')}</Typography>
        </Stack>
      </Fade>

      <Stack direction="row" gap={3} justifyContent="flex-end">
        <Button onClick={onCancel} variant="outlined">
          {t('button.cancel')}
        </Button>
        <Button onClick={onSave} variant="contained" disabled={isSaveDisabled}>
          {t('button.save')}
        </Button>
      </Stack>
    </Stack>
  );
};
