import { ListItem as MuiListItem } from '@mui/material';
import { ReactNode } from 'react';

export const ListItem = ({ children }: { children: ReactNode }) => {
  return (
    <MuiListItem
      sx={{
        display: 'list-item',
        listStylePosition: 'outside',
        listStyleType: 'disc',
        alignItems: 'flex-start',
        padding: 0,
      }}
    >
      {children}
    </MuiListItem>
  );
};
