import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const InfoBox = () => {
  const { t } = useTranslation(['iPedigree']);

  return (
    <Alert
      severity="info"
      icon={
        <FontAwesomeIcon
          icon={faInfoCircle}
          fontSize={20}
          style={{
            padding: 2,
          }}
        />
      }
    >
      {t('eligibility-genetic-testing.info-box-text')}
    </Alert>
  );
};
