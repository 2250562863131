import { DynamicForm } from 'common/features/dynamicForm';
import { getStaffSchema } from './staffSchema';

export const DynamicFormPlayground = () => {
  const onSubmit = (data: any) => {
    console.log('submitted data:', data.formData);
  };

  const { patientSchema } = getStaffSchema();
  const schema = patientSchema.jsonSchema;
  const uiSchema = patientSchema.uiSchema;
  const formData = patientSchema.formData;

  return (
    <DynamicForm
      formData={formData}
      schema={schema}
      uiSchema={{ ...uiSchema, ...{ 'ui:accordionsGap': 0.5 } }}
      onSubmit={onSubmit}
    />
  );
};
