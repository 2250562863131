import { WidgetProps } from '@rjsf/utils';
import DatePicker from 'components/inputs/DatePicker';
import { DateTime } from 'luxon';
import { ReadonlyView } from '../views/ReadonlyView';

export function DateWidget(props: WidgetProps) {
  const inputWidth = props.uiSchema?.['ui:inputWidth'] || '100%';

  if (props.readonly) {
    return (
      <ReadonlyView
        title={props.label}
        value={props.value}
        uiSchema={props.uiSchema}
      />
    );
  }

  return (
    <DatePicker
      value={
        props.value
          ? DateTime.fromISO(props.value as string)
          : DateTime.fromISO('')
      }
      onChange={(newValue) => {
        props.onChange(newValue.toISODate());
      }}
      label={props.label}
      id={props.id}
      required={props.required}
      width={inputWidth}
      format="dd/MM/yyyy"
    />
  );
}
