import { Stack } from '@mui/material';
import { useMedicalForm } from 'common/hooks/useMedicalForm';
import Dialog from 'components/common/Dialog';
import Drawer from 'components/common/drawer/Drawer';
import { DrawerFooterActions } from 'components/common/drawer/DrawerFooterActions';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { usePedigreeContext } from '../../../common/pedigreeContext';
import { CustomizableFormData } from '../../types';
import { PatientNodeFormController } from './PatientNodeFormController';
import { RelativeNodeFormController } from './RelativeNodeFormController';

export const NodeFormController = () => {
  const {
    selectedNodeId,
    setSelectedNodeId,
    selectedNode,
    service,
    pedigree,
    getLocalizedLabelOf,
    drawerDefaultWidth,
  } = usePedigreeContext();
  const queryClient = useQueryClient();
  const { i18n } = useTranslation(['pedigree', 'common']);

  const selectedRelativeRelationLabel = useMemo(() => {
    if (selectedNode?.isIndex) {
      return selectedNode.name || '';
    }
    return selectedNodeId ? getLocalizedLabelOf(selectedNodeId) : '';
  }, [selectedNodeId, getLocalizedLabelOf, selectedNode]);

  const removeFormQueries = useCallback(() => {
    queryClient.removeQueries(['patientForm', selectedNodeId, i18n.language]);
    queryClient.removeQueries(['relativeForm', selectedNodeId, i18n.language]);
  }, [i18n.language, queryClient, selectedNodeId]);

  const handleCloseForm = useCallback(() => {
    removeFormQueries();
    setSelectedNodeId(null);
  }, [removeFormQueries, setSelectedNodeId]);

  const submitRelativeMutation = useMutation(
    (data: CustomizableFormData) => {
      return service?.putRelativeForm(pedigree.id, selectedNodeId || '', data);
    },
    {
      onSuccess: () => {
        handleOnSuccessSubmit();
        queryClient.invalidateQueries('pedigree');
      },
      onError: () => {
        openSubmitFailureDialog();
      },
    },
  );

  const submitIndexMutation = useMutation(
    (data: CustomizableFormData) => {
      return service?.putPatientForm(data);
    },
    {
      onSuccess: () => {
        handleOnSuccessSubmit();
        queryClient.invalidateQueries('pedigree');
      },
      onError: () => {
        openSubmitFailureDialog();
      },
    },
  );

  const onSubmit = ({ formData }: { formData: CustomizableFormData }) => {
    if (selectedNode?.isIndex) {
      submitIndexMutation.mutate(formData);
    } else {
      submitRelativeMutation.mutate(formData);
    }
  };

  const isLoading =
    submitIndexMutation.isLoading || submitRelativeMutation.isLoading;
  const {
    formRef,
    uiSchema,
    isDirty,
    dialogProps,
    changesSaved,
    setFormData,
    handleOnClickOutside,
    handleOnSuccessSubmit,
    openSubmitFailureDialog,
    onClickClose,
    onClickSave,
  } = useMedicalForm({
    isLoading,
    handleCloseFormCalback: handleCloseForm,
  });

  if (!selectedNodeId) {
    return null;
  }

  return (
    <>
      <Drawer
        title={selectedRelativeRelationLabel}
        isOpen={!!selectedNodeId}
        onClose={onClickClose}
        onClickOutside={handleOnClickOutside}
        hideBackdrop={false}
        width={drawerDefaultWidth}
      >
        <Stack pb={9.5}>
          {selectedNode?.isIndex ? (
            <PatientNodeFormController
              uiSchema={uiSchema}
              enabled={!isDirty}
              formRef={formRef}
              setNodeFormData={setFormData}
              onSubmit={onSubmit}
            />
          ) : (
            <RelativeNodeFormController
              uiSchema={uiSchema}
              enabled={!isDirty}
              formRef={formRef}
              pedigreeId={pedigree.id}
              setNodeFormData={setFormData}
              onSubmit={onSubmit}
            />
          )}
        </Stack>
        <DrawerFooterActions
          onSave={onClickSave}
          onCancel={onClickClose}
          isSaveDisabled={!isDirty}
          hasSavedChanges={changesSaved}
        />
      </Drawer>
      <Dialog {...dialogProps} />
    </>
  );
};
