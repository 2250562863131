import { Container, Stack } from '@mui/material';
import {
  PatientPedigreeCaseStatus,
  usePatientService,
} from 'api/patient/patient-service';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SectionDivider } from '../common/SectionDivider';
import { FamilyTreeSection } from './FamilyTreeSection';
import { PersonalInformationSection } from './PersonalInformationSection';
import { SubmitAndSavePedigreeAction } from './SubmitAndSavePedigreeAction';

interface PedigreeSectionProps {
  pedigreeCaseStatus: PatientPedigreeCaseStatus;
  hasUnfinishedTasks: boolean;
}

export const PedigreeSection = (props: PedigreeSectionProps) => {
  const { pedigreeCaseStatus } = props;
  const service = usePatientService();
  const { patientId } = useParams();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service?.getPedigreeCase(patientId || ''),
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading) return null;
  if (!pedigreeCase) return null;
  if (pedigreeCaseStatus === 'submitted' || pedigreeCaseStatus === 'inactive')
    return null;

  const hasPedigree = !!pedigreeCase?.pedigreeId;
  const medicalFormEditedByPatient = !!pedigreeCase?.medicalFormEditedByPatient;

  return (
    <>
      <Container maxWidth="sm">
        <Stack gap={3}>
          <FamilyTreeSection hasPedigree={hasPedigree} />
          <PersonalInformationSection
            medicalFormEditedByPatient={medicalFormEditedByPatient}
          />
          <SubmitAndSavePedigreeAction
            pedigreeCaseId={pedigreeCase.id}
            hasPedigree={hasPedigree}
            hasUnfinishedTasks={props.hasUnfinishedTasks}
          />
        </Stack>
      </Container>
      <SectionDivider />
    </>
  );
};
