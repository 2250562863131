import { Stack, useTheme } from '@mui/material';

export const SectionDivider = () => {
  const theme = useTheme();
  return (
    <Stack
      width="100%"
      height="12px"
      bgcolor={theme.palette.common.neutral[100]}
    ></Stack>
  );
};
