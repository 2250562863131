import {
  faChevronRight,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, useTheme } from '@mui/material';

export type BreadcrumbItem<K> = {
  key: K;
  label: string;
  icon: IconDefinition | null;
};

interface BreadCrumbProps<K> {
  path: BreadcrumbItem<K>[];
  handleLinkClick: (breadcrumbKey: K) => void;
  activeItem: K;
}

function Breadcrumb<K extends string>(props: BreadCrumbProps<K>) {
  const theme = useTheme();
  const blue = theme.palette.common.brand.blue;
  const darkBlue = theme.palette.common.brand.darkBlue;

  const separatorIcon = (
    <FontAwesomeIcon icon={faChevronRight} color={darkBlue} />
  );

  return (
    <Breadcrumbs aria-label="breadcrumb" separator={separatorIcon}>
      {props.path.map((item) => (
        <span
          key={item.key}
          onClick={() => props.handleLinkClick(item.key)}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            textDecoration: 'none',
            cursor: 'pointer',
            color: props.activeItem === item.key ? darkBlue : blue,
          }}
        >
          {item.icon && (
            <FontAwesomeIcon
              icon={item.icon}
              color={props.activeItem === item.key ? darkBlue : blue}
            />
          )}
          {item.label}
        </span>
      ))}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
