import { Box, Container, ContainerProps, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArkusLogo from '../../assets/images/arkus-logo.svg';
import { LanguageSelector } from '../../common/layouts/LanguageSelector';

interface PatientPortalTopNavProps {
  containerMaxWidth?: ContainerProps['maxWidth'];
}

export function PatientPortalTopNav({
  containerMaxWidth,
}: PatientPortalTopNavProps) {
  const navigate = useNavigate();

  const navigateToHome = () => {
    return navigate('.');
  };

  return (
    <Box
      sx={{
        backgroundColor: 'common.brand.lightPurple',
      }}
    >
      <Container maxWidth={containerMaxWidth}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img
            alt="logo"
            src={ArkusLogo}
            style={{
              cursor: 'pointer',
            }}
            onClick={navigateToHome}
          />
          <LanguageSelector />
        </Stack>
      </Container>
    </Box>
  );
}
