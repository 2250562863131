import { BaseService } from 'api/base-service';
import { PersonalNumberValidationResponse } from 'api/types';
import {
  AddRelativesPayload,
  NewPatientPayload,
} from 'features/consentManagement/common/types';
import { ConsentStatus } from 'types';

export type Relative = {
  name: string;
  consentStatus: ConsentStatus;
  updatedAt: string;
  createdAt: string;
  signedAt?: string;
  pdfLocationUrl?: string;
  new: boolean;
  consentId: string;
};

type ListConsentUpdatesItem = {
  consentRequestId: string;
  new: boolean;
  patientName: string;
  patientPersonalNumber: string;
  relatives: Relative[];
};

type ListConsentUpdates = {
  items: ListConsentUpdatesItem[];
  totalCount: number;
};

type ListQueryParams = {
  sortQuery: string;
  searchQuery: string;
  showCompleted?: boolean;
};

export type ConsentListItem = {
  relativeName: string;
  relativePersonalNumber: string;
  consentStatus: ConsentStatus;
  relationshipToPatient: string;
  pdfLocationUrl?: string;
  deceased: boolean;
  proxySignerName?: string;
  proxySignerToConsenteeRelationship?: string;
  new: boolean;
  consentId: string;
  updatedAt: string;
  createdAt: string;
  signedAt: string | undefined;
};

export type ListedConsentRequest = {
  consentRequestId: string;
  patientName: string;
  patientPersonalNumber: string;
  patientEmail: string;
  patientPhoneNumber: string;
  consents: ConsentListItem[];
  consentFor: string;
  consentRequestCreatedAt: string;
  completedAt: string | null;
};

export type RelativeData = {
  consentStatus: ConsentStatus;
  name: string;
  personalNumber: string;
  relationshipToPatient: string | null;
  deceased: boolean;
  proxySignerName?: string;
  proxySignerPersonalNumber?: string;
  proxySignerToConsenteeRelationship?: string;
  updatedAt: string;
  createdAt: string;
  consentId: string;
  new: boolean;
  pdfLocationUrl?: string;
};

export type PatientData = {
  personalNumber: string;
  name: string;
  phoneNumber: string;
  email?: string;
};

export type ConsentRequest = {
  id: string;
  patientData: PatientData;
  relatives: RelativeData[];
  consentFor: string;
};

export type Diagnose = {
  diseaseName: string;
  ageAtDiagnosis?: number;
};

export type ConsentRequestSuggestion = {
  relativeName?: string;
  personId: number;
  relation: string;
  deceased: boolean;
  diagnoses: Diagnose[];
  pathogenicVariants: string[];
};

type PatientContactDetails = { phoneNumber?: string; email?: string };

export type RelativeInfo = {
  name: string;
  personId?: number;
  personalNumber?: string;
  relationshipToPatient: string;
  deceased?: boolean;
  proxySignerName?: string;
  proxySignerPersonalNumber?: string;
  proxySignerToConsenteeRelationship?: string;
};

export type CreateConsentRequestForPatient = {
  patientContactDetails: PatientContactDetails;
  consentFor: string;
  relatives: RelativeInfo[];
};

export type UpdateRelativeRepresentative = {
  proxySignerName?: string | null;
  proxySignerPersonalNumber?: string | null;
  proxySignerToConsenteeRelationship?: string | null;
};

export type AddConsentsToExistingConsent = {
  patientContactDetails: PatientContactDetails;
  relatives: RelativeInfo[];
};

type ListConsentsQueryParams = {
  search: string;
  limit: number;
  offset: number;
  forUserId?: number;
};

type Consent = {
  id: string;
  name: string;
  personalNumber: string;
  status: ConsentStatus;
  updatedAt: string;
  new: boolean;
  patientId: number;
  patientName: string;
  patientPersonalNumber: string;
  pdfLocationUrl?: string;
};

type ListConsents = {
  items: Consent[];
  totalCount: number;
};

type ConsentStats = {
  new: number;
  pending: number;
};

export class ConsentManagementService extends BaseService {
  getConsentRequest = async (
    consentRequestId: string,
  ): Promise<ConsentRequest | null> =>
    this.axiosInstance
      .get(`/staff/consent-requests/${consentRequestId}`, {})
      .then((response) => response.data);

  listConsentUpdates = async (): Promise<ListConsentUpdates> =>
    this.axiosInstance
      .get('/staff/consent-updates')
      .then((response) => response.data);

  listConsentRequests = async ({
    sortQuery,
    searchQuery,
    showCompleted,
  }: ListQueryParams): Promise<ListedConsentRequest[]> => {
    const params = new URLSearchParams({
      sortBy: sortQuery,
      search: searchQuery,
      completed: showCompleted ? 'true' : 'false',
    });
    return this.axiosInstance
      .get(`/staff/consent-requests?${params}`)
      .then((response) => response.data);
  };

  listConsents = async ({
    search,
    limit,
    offset,
    forUserId,
  }: ListConsentsQueryParams): Promise<ListConsents> => {
    const params = new URLSearchParams({
      search,
      limit: limit.toString(),
      offset: offset.toString(),
    });

    if (forUserId) {
      params.append('forUserId', forUserId.toString());
    }

    return this.axiosInstance
      .get(`/staff/consents?${params}`)
      .then((response) => response.data);
  };

  getConsentInformation = async (): Promise<{ consentFor: string }> => {
    return this.axiosInstance
      .get(`/staff/consent-request-information`)
      .then((response: any) => response.data);
  };

  createNewConsentRequest = async (
    consent: NewPatientPayload,
  ): Promise<ListedConsentRequest> => {
    if (consent.patientData.email === '') {
      delete consent.patientData.email;
    }

    return this.axiosInstance
      .post('/staff/consent-requests', consent)
      .then((response) => response.data);
  };

  addRelativesToConsentRequest = async ({
    consentRequestId,
    relatives,
  }: {
    consentRequestId: string;
    relatives: AddRelativesPayload;
  }): Promise<void> =>
    this.axiosInstance
      .post(`/staff/consent-requests/${consentRequestId}/relatives`, relatives)
      .then((response) => response.data);

  completeConsentRequest = async (consentRequestId: string): Promise<void> =>
    this.axiosInstance
      .put(`/staff/consent-requests/${consentRequestId}/complete`, {
        complete: true,
      })
      .then((response) => response.data);

  validatePersonalNumber = async (
    personalNumber: string,
  ): Promise<PersonalNumberValidationResponse> =>
    this.axiosInstance
      .get(`/personal-number-validation?personalNumber=${personalNumber}`)
      .then((response) => response.data);

  setConsentViewed = async (consentId: string): Promise<void> =>
    this.axiosInstance
      .put(`/staff/consents/${consentId}/viewed`, { viewed: true })
      .then((response) => response.data);

  sendReminder = async (
    consentRequestId: string,
    consentIds?: string[],
  ): Promise<ListedConsentRequest> =>
    this.axiosInstance
      .post(`/staff/consent-requests/${consentRequestId}/reminders`, {
        consentIdsToSendReminder: consentIds,
      })
      .then((response) => response.data);

  getConsentRequestSuggestions = async (
    patientId: string,
  ): Promise<ConsentRequestSuggestion[]> =>
    this.axiosInstance
      .get(`/staff/patients/${patientId}/consent-suggestions`)
      .then((response) => response.data);

  createConsentRequest = async (
    patientId: string,
    consent: CreateConsentRequestForPatient,
  ): Promise<ListedConsentRequest> =>
    this.axiosInstance
      .put(`/staff/patients/${patientId}/consent-request`, consent)
      .then((response) => response.data);

  addConsentsToExistingConsent = async (
    patientId: string,
    consent: AddConsentsToExistingConsent,
  ): Promise<void> =>
    this.axiosInstance
      .post(`/staff/patients/${patientId}/consent-request/consents`, consent)
      .then((response) => response.data);

  getConsentRequestByPatientId = async (
    patientId: string,
  ): Promise<ConsentRequest | null> =>
    this.axiosInstance
      .get(`/staff/patients/${patientId}/consent-request`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 404) {
          return null;
        }
        throw error;
      });

  updateRelativeRepresentative = async (
    consentId: string,
    consent: UpdateRelativeRepresentative,
  ): Promise<void> =>
    this.axiosInstance
      .patch(`staff/consents/${consentId}`, consent)
      .then((response) => response.data);

  getConsentStats = async ({
    forUserId,
  }: {
    forUserId?: string;
  }): Promise<ConsentStats> => {
    const url = forUserId
      ? `/staff/consent-stats?forUserId=${forUserId}`
      : '/staff/consent-stats';
    return this.axiosInstance.get(url).then((response) => response.data);
  };
}
