import { Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { RelativeInfo } from 'api/consentManagement/consent-management-service';
import { HeaderWithBackButton } from 'components/common';
import Dialog from 'components/common/Dialog';
import {
  PatientData,
  PatientDataErrors,
  PatientForm,
} from 'iPedigree/features/patient/common/PatientForm';
import {
  validatePatientData,
  validateRelativeData,
} from 'iPedigree/features/patient/common/utils/form-validation';
import { NewConsentFormData } from 'iPedigree/pages/patient/consent/RequestNewConsentPage';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  defaultRelativeData,
  errorMessageState,
  RelativesForm,
} from './RelativesForm';

export type RelativeFormData = RelativeInfo & {
  id: number;
  disabled?: boolean;
  specificProxySigner?: boolean;
};

export type RelativeDataErrors = {
  [key in keyof RelativeFormData]?: string | undefined;
};

interface NewConsentFormProps {
  initialFormValues: NewConsentFormData;
  onContinueClick: (data: NewConsentFormData) => void;
}

export const NewConsentForm = (props: NewConsentFormProps) => {
  const { t } = useTranslation(['consent', 'common']);
  const theme = useTheme();
  const navigate = useNavigate();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const { initialFormValues } = props;

  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState<boolean>(false);

  const [initialFormValuesState, setInitialFormValuesState] =
    useState<NewConsentFormData | null>(null);

  const [consentFor, setConsentFor] = useState<string>('');

  const [patientData, setPatientData] = useState<PatientData>({
    personalNumber: '',
    name: '',
    phoneNumber: '',
    email: '',
  });

  const [patientDataErrorMessages, setPatientDataErrorMessages] =
    useState<PatientDataErrors>({
      personalNumber: '',
      name: '',
      phoneNumber: '',
    });

  const [relatives, setRelatives] = useState<RelativeFormData[]>([
    defaultRelativeData,
  ]);

  const [relativesErrorMessages, setRelativesErrorMessages] = useState<
    RelativeDataErrors[]
  >([errorMessageState]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const cancelDialogProps = useMemo(() => {
    return {
      open: isOpenCancelDialog,
      title: t('request-new-consent.cancel-confirmation.title'),
      content: t('request-new-consent.cancel-confirmation.text'),
      actions: [
        <Button
          key="confirm-button-dialog"
          variant="outlined"
          onClick={() => setIsOpenCancelDialog(false)}
        >
          {t('request-new-consent.cancel-confirmation.continue-editing')}
        </Button>,
        <Button
          key="close-button-dialog"
          variant="contained"
          onClick={navigateBack}
        >
          {t('request-new-consent.cancel-confirmation.exit-editing')}
        </Button>,
      ],
    };
  }, [isOpenCancelDialog, navigateBack, t]);

  const handleOnContinueClick = () => {
    const { valid: patientDataValid, errorMessages: patientDataErrorMessages } =
      validatePatientData(patientData);
    const { valid: relativesValid, errorMessages: relativesErrorMessages } =
      validateRelativeData(relatives);

    const hasPhoneOrEmail = patientData.phoneNumber || patientData.email;

    if (patientDataValid && relativesValid && hasPhoneOrEmail) {
      props.onContinueClick({ patientData, relatives, consentFor });
    } else {
      if (patientDataErrorMessages !== undefined || !hasPhoneOrEmail) {
        const errorMessages = {
          ...patientDataErrorMessages,
          phoneNumber: !hasPhoneOrEmail
            ? t('input-validation.phone-or-email.required')
            : patientDataErrorMessages?.phoneNumber,
        };

        setPatientDataErrorMessages(errorMessages);
      }

      if (relativesErrorMessages !== undefined)
        setRelativesErrorMessages(
          relativesErrorMessages.filter((error) => error !== undefined),
        );
    }
  };

  const handleCancelClick = () => {
    const { patientData: initialPatientData } = initialFormValuesState || {};
    const emailHasChanged =
      patientData.email && patientData.email !== initialPatientData?.email;
    const phoneHasChanged =
      patientData.phoneNumber &&
      patientData.phoneNumber !== initialPatientData?.phoneNumber;

    const contentHasChanged =
      emailHasChanged ||
      phoneHasChanged ||
      relatives.some(
        (relative) =>
          relative.personalNumber !== '' ||
          relative.name !== '' ||
          relative.relationshipToPatient !== '' ||
          relative.proxySignerName !== '' ||
          relative.proxySignerPersonalNumber !== '' ||
          relative.proxySignerToConsenteeRelationship !== '',
      );

    if (contentHasChanged) {
      return setIsOpenCancelDialog(true);
    }
    return navigateBack();
  };

  useEffect(() => {
    const { patientData, relatives, consentFor } = initialFormValues || {};
    if (patientData) setPatientData(patientData);
    if (relatives?.length) setRelatives(relatives);
    setConsentFor(consentFor);
    setInitialFormValuesState(initialFormValues);
  }, [initialFormValues]);

  return (
    <>
      <Stack gap={3}>
        <HeaderWithBackButton
          headerVariant="h1"
          onBackButtonClick={handleCancelClick}
        >
          {t('request-new-consent.title')}
        </HeaderWithBackButton>
        {patientData.personalNumber && (
          <>
            <PatientForm
              patientData={patientData}
              setPatientData={setPatientData}
              errors={patientDataErrorMessages}
              setErrors={setPatientDataErrorMessages}
              consentFor={consentFor}
            />
            <RelativesForm
              relatives={relatives}
              setRelatives={setRelatives}
              errors={relativesErrorMessages}
              setErrors={setRelativesErrorMessages}
            />
            <Stack
              direction={isNotMobile ? 'row' : 'column'}
              justifyContent="center"
              spacing={4}
            >
              <Button
                variant="outlined"
                onClick={handleCancelClick}
                sx={{ minWidth: 334 }}
              >
                {t('common:button.back')}
              </Button>
              <Button
                variant="contained"
                onClick={handleOnContinueClick}
                sx={{ minWidth: 334 }}
              >
                {t('common:button.continue')}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
      <Dialog {...cancelDialogProps} />
    </>
  );
};
